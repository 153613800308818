import React, { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ViewSwticher } from '../index.js';
import '../../css/zadosti.css';
import DokumentaceVersionsOlder from './DokumentaceVersionsOlder.jsx';
import DokumentaceVersionsNewest from './DokumentaceVersionsNewest.jsx';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config.js';
import { isRequestOK } from '../../apiCalls/componentsApiCalls.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { infoCircle } from '../../assets/index.js';

const DokumentaceDetailMainSection = ({item}) => {
    const [isActive, setIsActive] = useState('block');
    const { versions, zamerDetail } = item || {};
    const mappedVersions = [...(versions || []).map(el => ({...el, ...(zamerDetail || {})}))];
    // versionNumber or date
    // for versionNumber
    // const sortedVersions = [...(mappedVersions || [])].sort((a, b) => Number(a?.versionNumber || 0) < Number(b?.versionNumber || 0) ? 1 : -1);
    // for finishDate
    const sortedVersions = [...(mappedVersions || [])].sort((a, b) => a.finishDate < b.finishDate ? 1 : -1);
    const newest = sortedVersions?.[0] ?? {}; // obj{}
    const older = sortedVersions?.slice(1) ?? []; // arr[]
    const last = [...older]?.pop() || null;
    const { token } = useContext(AuthContext);
    const message = last ? `Použitý odkaz vede na starší verzi dokumentace (${last.finishDate ?? ''}). Níže zobrazujeme všechny verze, včetně té nejnovější.` : '';

    const downloadDocumentVersion = async (id) => {
        try {
            const source = axios.CancelToken.source();
            // TODO
            const response = await axios.get(
                // TODO
                urlJoin(apiConfig.validateAndPersistBaseUrl, `???/????/${id}`),
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                });

            if (isRequestOK(response?.status)) {
                window.open(URL.createObjectURL(response.data));
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
        }
    };

    return (
        <Container className='d-flex flex-column align-items-center align-self-center bg-white'>
            {(mappedVersions?.length > 1 && message) && (<Row className='align-self-start ps-5 pt-5 pb-3'>
                <Col className='kontrola-info w-100'>
                    <img src={infoCircle} alt="infoCircle" className='me-2' />
                    {message}
                </Col>
            </Row>)}
            <Container className='px-4'>
                {mappedVersions?.length > 0 && (<>
                    <Col>
                        <DokumentaceVersionsNewest item={newest} downloadDocumentVersion={downloadDocumentVersion}/>
                    </Col>
                    <Col>
                        <ViewSwticher
                            isActive={isActive}
                            setIsActive={setIsActive}
                        />
                    </Col>
                    <Col>
                        <DokumentaceVersionsOlder items={older} isActive={isActive} downloadDocumentVersion={downloadDocumentVersion}/>
                    </Col>
                </>)}
            </Container>
        </Container>
    );
};

DokumentaceDetailMainSection.propTypes = {
    item: PropTypes.any
};

export default DokumentaceDetailMainSection;
