import { useEffect } from 'react';
import useFirstRender from './useFirstRender';

const useScrollToTop = (stage) => {
    const firstRender = useFirstRender();

    useEffect(() => {
        if (firstRender) {
            return;
        }
        
        try {
            setTimeout(() => {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 50);

        } catch (error) {
            console.log('error', error);
            window.scrollTo(0, 0);
        }
    }, [stage, firstRender]);

    return null;
};

export default useScrollToTop;