import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { emptyList, iconZadost, iconZamerWhite } from '../assets';
import { GovButton } from '@gov-design-system-ce/react';
import { useLocation, useNavigate } from 'react-router-dom';

// TODO move these styles into separate css
const styles = {
    pMain: {
        color: '#3B3B3B',
        fontWeight: 600,
        fontSize: '1.1875em',
    },
    pSecondary: {
        color: '#3B3B3B',
    },
    image: {
        height: '80px',
        width: '80px',
    },
    btnImage: {
        height: '24px',
        width: '24px',
    },
    btnImageCont: { 
        width: '250px',
        height: '34px',
    },
};

const RecordNotFound = () => {
    const { pathname } = useLocation();
    const zadosti = '/zadosti';
    const rizeni = '/rizeni';
    const zamery = '/zamery';
    const stavby = '/stavby';
    const documentation = '/dokumentace';
    const shouldShowButton = pathname !== rizeni && pathname !== stavby;
    const buttonContent = shouldShowButton && (pathname === zadosti ? 'Nová žádost' : pathname === zamery ? 'Nový záměr' : pathname === documentation ? 'Nová dokumentace' : '');
    const path = pathname === zadosti ? '/zadosti/new' : documentation ? '/dokumentace/new' : 'zamery';
    const navigate = useNavigate();

    return (
        <Container className='px-3 py-5 d-flex row align-items-center bg-white' fluid >
            <Row className='justify-content-center py-4'>
                <Container
                    className='d-flex justify-content-center'
                >
                    <img
                        style={styles.image}
                        src={emptyList}
                        alt='žadná informace'
                    />
                </Container>
            </Row>
            <Row className='py-4'>
                <p className={`text-center ls-1 ${shouldShowButton ? 'pb-3' : 'pb-0'}`} style={styles.pMain}>
                    {pathname === rizeni
                        ? 'Nejste účastníkem žádného řízení.'
                        : `Nenalezli jsme žádné ${
                            pathname === zadosti
                                ? 'žádosti'
                                : pathname === zamery
                                    ? 'záměry'
                                    : pathname === stavby
                                        ? 'stavby'
                                        : pathname === documentation
                                            ? 'dokumentace'
                                            : ''
                        }.`}
                </p>
                <p className='text-center fw-normal ls-1p25' style={styles.pSecondary}>
                    {(shouldShowButton && pathname !== zamery) ? `Co ${
                        (pathname === zadosti || pathname === documentation)
                            ? 'jednu'
                            : pathname === zamery
                                ? 'jeden'
                                : ''
                    } založit?` : ''}
                </p>
            </Row>
            {(shouldShowButton && pathname !== zamery) && <Row className='justify-content-center'>
                <GovButton
                    className='button w-auto p-0'
                    type='solid'
                    variant={'primary'}
                    onGov-click={() => navigate(path)}
                >
                    <Container
                        className='d-flex justify-content-center align-items-center button-create-req'
                        style={styles.btnImageCont}
                    >
                        <img
                            src={pathname === zadosti ? iconZadost : pathname === zamery ? iconZamerWhite : pathname === documentation ? iconZadost : ''}
                            style={styles.btnImage}
                            alt={buttonContent}
                            className='me-2'
                        />
                        {buttonContent}
                    </Container>
                </GovButton>
            </Row>}
        </Container>
    );
};

export default RecordNotFound;
