import React, { useImperativeHandle, useState, useContext, useEffect, useRef, Fragment } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import { errorIcon } from '../../assets/index.js';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { PozemkyTable, StavbyTable, FormInput, NewConstructionModal, AffectedConstructionsAndParcels, FindAndProcessIco, ZamerSelectionControls, VodniDilo } from '../../components/index.js';
import { BUILDING_ADDRESS, DEVELOPER_INFO, DEVELOPER_INFO_PO, DEVELOPER_ADDRESS } from '../../constants/form19.js';
import FormContext from '../../formContexts/FormContext.js';
import { handleClose, handleShow } from '../../helperFunctions/modalHelpers';
import {
    shouldNotSave,
    spreadParcelsAndConstructions,
    spreadObject,
    /* checkLimitAndInsertText, */
    hasZamerError,
} from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { AUTHORITY_MODEL, COUNTRIES, PROPERTY_DEVELOPER_MODEL } from '../../constants/sharedConstants.js';
/* import { CharCount } from '../../components/index.js'; */
import { /* validDateAfterOrEquals, */ dateValidation } from '../../helperFunctions/validationHelpers.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const buildIntentionModel = {
    title: { value: null, dirty: false },
    changeOfPurpose: false,
    splitOrMergeParcels: false,
    protectZoneDetermination: false,
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
    newBuilding: false,
    buildingTypePurpose: '',
    changeOfFinishedBuilding: false,
    reasonChangeOfBuildingUse: false,
    newBuildingUse: '',
    buildingTemporary: false,
    setOfBuildings: false,
    techOrMarketFacility: false,
    siteFacility: false,
    maintenanceAndModificationPermission: false,
    waterworks: false,
    startDate: '',
    endDate: '',
    selfHelp: true,
    propertyDeveloper: false,
    useNeighborFacility: false,
    buildingTemporaryDuration: '',
    parcelChangeSuggestion: '',
    waterworksPurpose: '',
    watercourseName: '',
    watercourseId: '',
    propertyDeveloperDetails: PROPERTY_DEVELOPER_MODEL,
    useNeighborFacilityReason: '',
    hydrogeologicalAreaName: '',
    hydrogeologicalAreaID: '',
    decisionBeenIssued: false,
    administrativeActName: '',
    permissionAuthority: AUTHORITY_MODEL,
    permissionRefNumber: '',
    permissionDated: '',
};

const ZamerComponent19 = ({
    urlPath,
}) => {
    const { token } = useContext(AuthContext);
    const { intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id } = useContext(FormContext); 
    const [show, setShow] = useState(false);
    const [ focused, setFocused ] = useState({
        newBuildingUse: false,
        buildingTemporaryDuration: false,
        parcelChangeSuggestion: false,
        waterworksPurpose: false,
        watercourseName: false,
        watercourseId: false,
        start: false,
        end: false,
        propertyDeveloperDetailsNationality: false,
        icoNum: false,
    });
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        decisionDate: null,
        startDate: null,
        endDate: null,
    });
    /* const [count, setCount] = useState({
        [0]: {count: 0, limit: 1000},
    }); */
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
        },
        buildIntention: buildIntentionModel
    });
    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);
    const prevRequest = usePrevious(request);
    const buildIntention = request?.buildIntention;
    // const minDate = new Date();
    const dateErrorStart = (!buildIntention?.startDate?.trim() || isDateError.startDate) && (error || focused.start);
    const dateErrorEnd = (!buildIntention?.endDate?.trim() || isDateError.endDate) && (error || focused.end);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        affectedBuildConstructions: [],
        affectedBuildParcels: [],
        buildConstructions: [],
        buildParcels: [],
    });

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            } else if (buildIntention?.newBuilding && !buildIntention?.buildingTypePurpose.trim()) {
                err = true;
            } else if(buildIntention?.reasonChangeOfBuildingUse && !buildIntention?.newBuildingUse?.trim())
            {
                err = true;
            }
            else if ((buildIntention?.changeOfPurpose
                || buildIntention?.splitOrMergeParcels
                || buildIntention?.protectZoneDetermination) &&
                !parcelAndConstructionData.buildParcels?.length)
            {
                err = true;
            }
            else if (buildIntention?.waterworks && !buildIntention?.waterworksPurpose)
            {
                err = true;
            } else if (!buildIntention?.startDate?.trim() || isDateError.startDate) {
                err = true;
            } else if (!buildIntention?.endDate?.trim() || isDateError.endDate) {
                err = true;
            } else if (buildIntention?.decisionBeenIssued && buildIntention?.permissionDated?.trim() && isDateError.decisionDate) {
                err = true;
            }
            else if (buildIntention?.useNeighborFacility && !buildIntention?.useNeighborFacilityReason?.trim())
            {
                err = true;
            }
            if (buildIntention?.address?.zip)
            {
                const cleanedValue = buildIntention?.address?.zip?.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }
            else if (buildIntention?.propertyDeveloperDetails?.address?.zip)
            {
                const cleanedValue = buildIntention?.propertyDeveloperDetails?.address?.zip?.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    const initialRender = useRef(true);
    const [newBuilding, setNewBuilding] = useState({
        isChecked: false,
        select: '',
    });

    const [loading, setLoading] = useState(false);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const isCopied = response.data?.buildApplication?.isCopied || false;
                    setIsIntentionCheckboxDisabled(isCopied);
                    const formData = response.data?.buildApplicationForms?.[0];

                    spreadParcelsAndConstructions(
                        response.data, //obj
                        setParcelAndConstructionData, //func
                        parcelAndConstructionData // obj                
                    );

                    const intention = response.data?.buildIntention;
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest), ...((formData && formData.id) ? { form: { id: formData.id } } : {}) }));
                        setNewBuilding(state => ({ ...state, isChecked: intention.newBuilding, select: intention.buildingTypePurpose }));
                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: (intention.icsId || intention.title.value) ? (intention.icsId ?? '') + ((intention.icsId && intention.title.value) ? ' - ' : '') + (intention.title.value ?? '') : '',
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const setError = () => {
        setStagesArr(prev => [...prev].map(stg => {
            if (Number(stg.stage) === Number(stepValue)) { 
                return {...stg, error: true};
            }
            return stg;
        }));
    };

    useEffect(() => {
        if (Object.values(isDateError).every(el => el === null)) {
            return;
        }

        if (Object.values(isDateError).some(el => el)) {
            setError();
        }
    }, [isDateError]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();

        const isPropertyDeveloper = Boolean(request.buildIntention.propertyDeveloper);
        if (!isPropertyDeveloper) {
            const updatedRequest = {...request, buildIntention: {...request.buildIntention, propertyDeveloperDetails: {
                ...PROPERTY_DEVELOPER_MODEL,
                nationality: ''
            } }};
            await formSave(updatedRequest, token, urlPath, source);
            return;
        }
        
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (
            (buildIntention?.buildParcels) ||
            (buildIntention?.buildConstructions)
        ) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildParcels: [...(buildIntention?.buildParcels ?? [])].map((item) => ({...item, uid: uuidv4()})),
                buildConstructions: [...(buildIntention?.buildConstructions ?? [])].map((item) => ({...item, uid: uuidv4()})),
            }));
            setRequest(prev => ({...prev, buildConstructions: buildIntention?.buildConstructions, buildParcels: buildIntention?.buildParcels}));
        }

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest.buildIntention, buildIntention)) {
                return;
            }

            if (buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));
    
    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                        <p className='p-0 subtitle'>Základní údaje o obnově</p>
                    </Row>

                    <ZamerSelectionControls
                        setNewBuilding={setNewBuilding}
                        setParcelAndConstructionData={setParcelAndConstructionData}
                        title={'Název stavby nebo terénní úpravy'}
                        request={request}
                        setRequest={setRequest}
                        state={state}
                        setState={setState}
                        buildIntentionModel={buildIntentionModel}
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                    />

                    {/* Místo záměru */}
                    <Row className='row-wrapper'>
                        <p className='section-title p-0 mb-4'>Místo stavby nebo terénní úpravy</p>
                        <form className='p-0'>

                            {BUILDING_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}
                        </form>
                    </Row>

                    {/* Charakteristika záměru */}
                    <Row className=''>
                        <p className='p-0 mb-4 section-title'>Charakteristika záměru</p>
                        {/* <p className='p-0 mb-4 section-description'>v případě, že se žádost o vyjádření týká stavby</p> */}
                        <form className='d-flex flex-column p-0'>
                            {/* TODO change mapping when BE will be ready */}
                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={newBuilding.isChecked ?? false}
                                    onChange={(e) => { setNewBuilding(state => ({ ...state, isChecked: e.target.checked })); setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, newBuilding: e.target.checked } })); }} />
                                Obnova zničené stavby
                            </label>
                            {newBuilding.isChecked &&
                            <Row className='row-wrapper'>
                                <Col md={6} sm={12}>
                                    <p className='p-0 mt-3 mb-1 new-construction-help-text' style={{color: error && !buildIntention?.buildingTypePurpose.trim() ? '#C52A3A' : ''}}>Druh a účel stavby</p>
                                    <label className='id-checkbox-label w-100'>
                                        <input
                                            type="text"
                                            value={newBuilding?.select ?? ''}
                                            className='new-construction-input'
                                            disabled
                                        />
                                        <button
                                            type='button'
                                            className='d-flex justify-content-center align-items-center new-construction-button'
                                            style={{border: error && !buildIntention?.buildingTypePurpose.trim() ? 'solid 1px #C52A3A' : ''}}
                                            onClick={() => handleShow(setShow)}
                                        >
                                            Vybrat
                                        </button>
                                    </label>
                                </Col>
                                <Col md={6} sm={12} className='d-flex'>
                                    <p className='building-change align-content-end mb-md-2 mt-2 ps-0 ps-md-3'>
                                            V případě souboru staveb uveďte stavbu hlavní
                                    </p>
                                </Col>
                                <NewConstructionModal 
                                    show={show} 
                                    setShow={setShow}
                                    handleClose={handleClose}
                                    setNewBuilding={setNewBuilding} 
                                    setRequest={setRequest}
                                />
                            </Row>}

                            <Col md={6} sm={12}>
                                {/* TODO change mapping when BE will be ready */}
                                <label className='mb-2 id-checkbox-label'>
                                    <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.changeOfFinishedBuilding)}
                                        onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, changeOfFinishedBuilding: e.target.checked } })); }} />
                                    Obnova poškozené stavby
                                </label>
                            </Col>

                            {/* <Row className={buildIntention.reasonChangeOfBuildingUse ? 'row-wrapper' : ''}>
                                <Row className='mb-2'>
                                    <Col md={6} sm={12}>
                                        <label className='id-checkbox-label'>
                                            <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.reasonChangeOfBuildingUse)}
                                                onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, reasonChangeOfBuildingUse: e.target.checked } })); }} />
                                        Navrhuje se z důvodu změny v užívání stavby
                                        </label>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <p className='building-change my-2 my-md-0 ps-0 ps-md-3'>
                                        Uveďte, zda se nástavba, přístavba nebo stavební úprava  navrhuje z důvodu změny v užívání stavby (změna ve způsobu užívání stavby, změna v provozním zařízení stavby, změna ve způsobu výroby nebo podstatném rozšíření výroby, změna v činnosti, jejíž účinky by mohly ohrozit život nebo veřejné zdraví, život a zdraví zvířat, bezpečnost nebo životní prostředí).
                                        </p>
                                    </Col>
                                </Row>
                                {buildIntention.reasonChangeOfBuildingUse &&
                                <Row className='d-flex pb-4'>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <label className='select-input' style={{color: (!buildIntention?.newBuildingUse?.trim() && (error || focused.newBuildingUse)) ? '#C52A3A' : ''}}>
                                        Nový způsob užívání stavby
                                            <input
                                                type='text'
                                                {...(focused.newBuildingUse && { focused: 'true' })}
                                                defaultValue={buildIntention.newBuildingUse ?? ''}
                                                style={{border: error && !buildIntention?.newBuildingUse?.trim() ? 'solid 1px #C52A3A' : ''}}
                                                required
                                                onBlur={(e) => {
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, newBuildingUse: e.target.value } }));
                                                    handleFocused('newBuildingUse');
                                                }}
                                                className='mt-1 id-input2 waterworks bg-white p-2'
                                            />
                                        </label>
                                    </Col>
                                </Row>}
                            </Row> */}

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(request.buildIntention.buildingTemporary)}
                                    onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporary: e.target.checked } })); }} />
                                Stavba dočasná
                            </label>

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.setOfBuildings)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, setOfBuildings: e.target.checked } })); }} />
                                Soubor staveb
                            </label>
                            {/* <Col md={6} sm={12}>
                                <label className='mb-2 id-checkbox-label'>
                                    <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.techOrMarketFacility)}
                                        onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, techOrMarketFacility: e.target.checked } })); }} />
                                    Technické zařízení nebo reklamní zařízení
                                </label>
                            </Col> */}

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.siteFacility)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, siteFacility: e.target.checked } })); }} />
                                Zařízení staveniště
                            </label>

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.waterworks)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, waterworks: e.target.checked } })); }} />
                                Vodní dílo
                            </label>

                            {buildIntention.waterworks &&
                            <VodniDilo
                                buildIntention={buildIntention}
                                focused={focused}
                                request={request}
                                setRequest={setRequest}
                                handleFocused={handleFocused}
                            />}

                            {/* <Row className='d-flex'>
                                <Col md={6} sm={12}>
                                    <label className='mb-2 id-checkbox-label'>
                                        <input type="checkbox" className='id-checkbox-input' checked={Boolean(request.buildIntention.maintenanceAndModificationPermission)}
                                            onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, maintenanceAndModificationPermission: e.target.checked } })); }} />
                                    Udržovací práce, které vyžadují povolení
                                    </label>
                                </Col>
                                <Col md={6} sm={12}>
                                    <p className='building-change my-2 my-md-0 ps-0 ps-md-3'>
                                    Příloha č. 2 odst. 2 písm. c)  stavebního zákona
                                    </p>
                                </Col>
                            </Row> */}
                        </form>
                    </Row>

                    <PozemkyTable
                        subtitle={'na kterých se má obnova uskutečnit'}
                        
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildParcels}
                        setDataToAdd={setParcelAndConstructionData}
                    />
                    {error
                        &&
                        ((buildIntention?.changeOfPurpose
                            || buildIntention?.splitOrMergeParcels
                            || buildIntention?.protectZoneDetermination) &&
                            !parcelAndConstructionData.buildParcels?.length)
                        &&
                    <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mb-4'>
                        <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Musí být zadán alespoň jeden pozemek.</p>
                    </Col>}
                    <StavbyTable
                        title={'Stavby'}
                        subtitle={'na kterých se má obnova uskutečnit'}
                        
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildConstructions}
                        setDataToAdd={setParcelAndConstructionData}
                    />

                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 mt-1 section-title'></p>
                        <Col md={6} sm={12}>
                            <form className='d-flex flex-column p-0 form'>
                                <label className='id-checkbox-label'>
                                    <input
                                        type="checkbox"
                                        className='id-checkbox-input'
                                        checked={Boolean(buildIntention.useNeighborFacility)}
                                        onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, useNeighborFacility: e.target.checked } })); }} />
                                    K provedení obnovy má být použit sousední pozemek nebo stavba
                                </label>
                            </form>
                        </Col>
                    </Row>

                    {buildIntention.useNeighborFacility && 
                    <AffectedConstructionsAndParcels
                        setDataToAdd={setParcelAndConstructionData}
                        isAffected={true}
                        
                        urlPath={urlPath}
                        request={request}
                        setRequest={setRequest}
                        affectedBuildParcels={parcelAndConstructionData.affectedBuildParcels}
                        affectedBuildConstructions={parcelAndConstructionData.affectedBuildConstructions}    
                    />}

                    {/* Předpokládaný termín zahájení a dokončení stavby */}
                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 section-title'>Předpokládaný termín zahájení a dokončení obnovy</p>
                        <form className='p-0'>
                            <Row className='mb-4 d-flex flex-column'>
                                <Col xs={12} md={6}>
                                    <label className='d-flex flex-column mb-4 label' style={{color: dateErrorStart ? '#C52A3A' : ''}}>
                                        Zahájení
                                        <input
                                            type='date'
                                            required
                                            className='id-input2'
                                            {...(focused.start && {focused: 'true'})}
                                            value={buildIntention?.startDate ?? ''}
                                            // min={minDate.toJSON().slice(0, 10)}
                                            style={{border: dateErrorStart ? '#C52A3A solid 1px' : ''}}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                const formatError = dateValidation(value);
                                                if (!formatError) setIsDateError(state => ({...state, startDate: formatError}));
                                                setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, startDate: value } })); 
                                            }}
                                            onBlur={(e) => {
                                                const { value } = e.target;
                                                const formatError = dateValidation(value);
                                                if (formatError) setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, startDate: '' } }));
                                                setIsDateError(state => ({...state, startDate: formatError}));
                                                handleFocused('start');
                                                // if (!formatError && !validDateAfterOrEquals(value, minDate)) {
                                                //     setError();
                                                // }
                                            }}
                                        />
                                        {isDateError.startDate && 
                                        <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                                        </Container>}
                                        {!buildIntention?.startDate?.trim() && !isDateError.startDate && ((error || focused.start)) &&
                                            <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                                Bez vyplnění pole &quot;Zahájení&quot; není možné pokračovat.
                                            </Container>}
                                    </label>
                                </Col>
                                {/* <Col md={6} sm={12}>
                                    {(!error && !focused.start && !focused.end) &&
                                        <p className='building-change my-2 my-md-0 ps-0 mt-md-4 ps-md-3'>
                                            Datum zahájení i datum dokončení obnovy nesmí být dříve než datum odeslání žádosti.
                                        </p>
                                    }
                                    {(
                                        (error || focused.start || focused.end) && 
                                            (
                                                (buildIntention?.startDate?.trim() && 
                                                !dateValidation(buildIntention?.startDate) &&
                                                !validDateAfterOrEquals(buildIntention?.startDate, minDate))
                                                ||
                                                (buildIntention?.endDate?.trim() && 
                                                !dateValidation(buildIntention?.buildIntention?.endDate) &&
                                                !validDateAfterOrEquals(buildIntention?.endDate, minDate))
                                            )
                                    ) &&
                                        <Container className="mt-3 mt-md-4 ps-md-3" style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Žádost nelze odeslat. Datum zahájení/ukončení stavby nesmí nastat před podáním žádosti, prosím opravte datum zahájení/ukončení stavby.
                                        </Container>
                                    }
                                </Col> */}
                                <Col xs={12} md={6}>
                                    <label className='d-flex flex-column label' style={{color: dateErrorEnd ? '#C52A3A' : ''}}>
                                        Dokončení
                                        <input
                                            type='date'
                                            required
                                            className='id-input2'
                                            {...(focused.end && {focused: 'true'})}
                                            value={buildIntention?.endDate ?? ''}
                                            // min={minDate.toJSON().slice(0, 10)}
                                            style={{border: dateErrorEnd ? '#C52A3A solid 1px' : ''}}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                const formatError = dateValidation(value);
                                                if (!formatError) setIsDateError(state => ({...state, endDate: formatError}));
                                                setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, endDate: value } })); 
                                            }}
                                            onBlur={(e) => {                                              
                                                const { value } = e.target;
                                                const formatError = dateValidation(value);
                                                if (formatError) setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, endDate: '' } }));
                                                setIsDateError(state => ({...state, endDate: formatError}));
                                                handleFocused('end');
                                                // if (!formatError && !validDateAfterOrEquals(value, minDate)) {
                                                //     setError();
                                                // }
                                            }}
                                        />
                                        {isDateError.endDate && 
                                        <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                                        </Container>}
                                        {!buildIntention?.endDate?.trim() && !isDateError.endDate && ((error || focused.end)) &&
                                            <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                                Bez vyplnění pole &quot;Dokončení&quot; není možné pokračovat.
                                            </Container>}
                                    </label>
                                </Col>
                            </Row>
                        </form>
                    </Row>

                    {/* Způsob provádění */}
                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 section-title'>Způsob provádění</p>
                        <form className='d-flex flex-column p-0 form'>
                            <label className='d-flex align-items-center mb-2 label'>
                                <input type='radio' name='method' value='self' className='radio' checked={Boolean(buildIntention.selfHelp)}
                                    onChange={() => {
                                        setRequest(state => ({ 
                                            ...state, 
                                            buildIntention: { 
                                                ...state.buildIntention,
                                                selfHelp: true, 
                                                propertyDeveloper: false, 
                                                propertyDeveloperDetails: {
                                                    ...PROPERTY_DEVELOPER_MODEL,
                                                    nationality: ''
                                                } 
                                            }
                                        })); }}
                                />
                                Svépomocí
                            </label>
                            <label className='d-flex align-items-center label'>
                                <input type='radio' name='method' value='businessman' className='radio' checked={Boolean(buildIntention.propertyDeveloper)}
                                    onChange={() => { 
                                        setRequest(state => ({ 
                                            ...state, 
                                            buildIntention: { 
                                                ...state.buildIntention,
                                                selfHelp: false, 
                                                propertyDeveloper: true, 
                                                propertyDeveloperDetails: {
                                                    ...state.buildIntention.propertyDeveloperDetails, 
                                                    ...PROPERTY_DEVELOPER_MODEL,
                                                    id: state.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                                } 
                                            } })); }}
                                />
                                Stavebním podnikatelem
                            </label>
                        </form>
                    </Row>

                    {/* Stavební podnikatel */}
                    {buildIntention.propertyDeveloper
                        &&
                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 section-title'>Stavební podnikatel</p>
                        <form className='d-flex flex-column p-0 form'>
                            <label className='d-flex align-items-center mb-2 label'>
                                <input type='radio' name='developerType' value='physical' className='radio' checked={buildIntention?.propertyDeveloperDetails?.isFOBusiness ?? true}
                                    onChange={() => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, propertyDeveloperDetails: { 
                                        ...state.buildIntention?.propertyDeveloperDetails,
                                        ...PROPERTY_DEVELOPER_MODEL,
                                        isFOBusiness: true,
                                        isPO: false,
                                        id: state.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                    } } })); }}
                                />
                                fyzická osoba podnikající
                            </label>
                            <label className='d-flex align-items-center label'>
                                <input type='radio' name='developerType' value='legal' className='radio' checked={buildIntention?.propertyDeveloperDetails?.isPO ?? false}
                                    onChange={() => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, propertyDeveloperDetails: { 
                                        ...state.buildIntention?.propertyDeveloperDetails,
                                        ...PROPERTY_DEVELOPER_MODEL, 
                                        isFOBusiness: false, 
                                        isPO: true,
                                        id: state.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                    } } })); }}
                                />
                                právnická osoba
                            </label>
                        </form>
                    </Row>}

                    {buildIntention.propertyDeveloper && <>
                        <Row className='mb-4'>
                            <p className='p-0 section-title'>Identifikační údaje stavebního podnikatele</p>
                        </Row>
                        <Row className='row-wrapper'>
                            <form className='p-0'>
                                {buildIntention?.propertyDeveloperDetails?.isFOBusiness && <>
                                    {DEVELOPER_INFO.map(item => {
                                        if (item?.state === 'ico') {
                                            return (<Fragment key={`${item.id}-${item.refer}`}>
                                                <FindAndProcessIco
                                                    info={item.info}
                                                    parentProp={'buildIntention'}
                                                    updateProp={'propertyDeveloperDetails'}
                                                    focusKey={'icoNum'}
                                                    handleFocused={handleFocused}
                                                    focused={focused}
                                                    request={request}
                                                    setRequest={setRequest}
                                                />
                                            </Fragment>);
                                        }
                                        return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest}/>;
                                    })}
                                    <Col md={6} sm={12}>
                                        <label className='select-input mt-3'>
                                        Státní příslušnost
                                            <select
                                                type='select'
                                                value={buildIntention.propertyDeveloperDetails?.nationality ?? ''}
                                                onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, propertyDeveloperDetails: { ...state.buildIntention.propertyDeveloperDetails, nationality: e.target.value } } })); }}
                                                className='id-input2 dropdown-input' >
                                                <option value={''}>Vyberte</option>
                                                {COUNTRIES.map((country) => (
                                                    <option key={country.value} value={country.value}>{country.label}</option>
                                                ))}
                                            </select>
                                        </label>
                                    </Col>
                                </>}
                                {buildIntention?.propertyDeveloperDetails?.isPO && <>
                                    {DEVELOPER_INFO_PO.map(item => {
                                        if (item?.state === 'ico') {
                                            return (<Fragment key={`${item.id}-${item.refer}`}>
                                                <FindAndProcessIco
                                                    info={item.info}
                                                    parentProp={'buildIntention'}
                                                    updateProp={'propertyDeveloperDetails'}
                                                    focusKey={'icoNum'}
                                                    handleFocused={handleFocused}
                                                    focused={focused}
                                                    request={request}
                                                    setRequest={setRequest}
                                                />
                                            </Fragment>);
                                        }
                                        return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest}/>;
                                    })}
                                </>}
                            </form>
                        </Row>
                        {buildIntention?.propertyDeveloperDetails?.isFOBusiness && <Row className='row-wrapper'>
                            <Col md={6} sm={12}>
                                <form className='d-flex flex-column p-0 form'>
                                    <label className='label'>
                                Dodatek odlišující osobu podnikatele nebo druh podnikání vztahující se k této osobě nebo jí provozovanému druhu podnikání (nepovinné)
                                        <input 
                                            type='text'
                                            defaultValue={buildIntention?.propertyDeveloperDetails?.appendix ?? ''}
                                            onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, appendix: e.target.value}  }})); }}
                                            className='mt-1 id-input2'
                                        />
                                    </label>
                                </form>
                            </Col>
                        </Row>}
                    </>}

                    {buildIntention?.propertyDeveloper && <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>{buildIntention?.propertyDeveloperDetails?.isFOBusiness ? 'Místo podnikání' : 'Adresa sídla'}</h3>
                        <form className='p-0'>
                            {DEVELOPER_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}

                        </form>
                    </Row>}

                </Container>}
        </>
    );
};

ZamerComponent19.propTypes = {
    

    urlPath: PropTypes.string.isRequired,
    request: PropTypes.object,
    setRequest: PropTypes.func,
    // setCheckbox: PropTypes.func,
};

export default ZamerComponent19;
