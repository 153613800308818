import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ZamerComponent18, FormProgress, StavbaComponent18, ProjektantComponent18, ZpracovatelComponent18, ZhotovitelComponent18, KontrolaComponent18 } from '../index.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave } from '../../apiCalls/formApiCalls.js';
import { FORM_18_INTENTION } from '../../constants/sharedConstants.js';
import FormContext from '../../formContexts/FormContext.js';

const Form18MainSection = ({
    stage,
    documents,
    setDocuments,
    handleToken,
    isMobileView,
    buttonRef
}) => {
    const [loading, setLoading] = useState(true);
    const { token } = useContext(AuthContext);
    const { setId } = useContext(FormContext);
    const { id: urlId, formType, receivedToken } = useParams();
    const isDokumentace = formType === 'documentation'; 
    const urlPath = `quick-save-api/${isDokumentace ? formType : `build-application-${formType}`}/save`;
    const stepsRef = useRef([]);
    const [foundApplicantIndex, setFoundApplicantIndex] = useState(null);
    const [applicantArr, setApplicantArr] = useState([]);

    useEffect(() => {
        if (!urlId) {
            (async () => {
                let data = JSON.stringify({
                    'buildApplication': {
                        'title': FORM_18_INTENTION.title
                    }
                });

                const response = await formSave(data, token, urlPath);
                if ((200 <= response?.status) && (response?.status < 300)) {
                    console.log('START NOVE ZADOSTI 18', response.data);
                    setId(response.data);
                    setLoading(false);
                }
            })();
        } else {
            setId(urlId);
            setLoading(false);
        }
    }, []);
    
    useEffect(() => {
        if (!receivedToken) {
            return;
        }
        handleToken(receivedToken);
    }, [receivedToken]);

    return (
        <Container fluid className='d-flex justify-content-center px-3 pt-0 pb-3 p-lg-5'>
            <Row className='base-width'>
                <Col className='d-flex justify-content-center justify-content-md-end d-md-block d-lg-none mb-4 mb-lg-0'>
                    {isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }} className='pe-lg-3'>
                    {loading === false ? 
                        <>
                            {stage === 1 && <ZamerComponent18
                                urlPath={urlPath}
                                receivedToken={receivedToken}
                            />}
                            {stage === 2 && <StavbaComponent18
                                urlPath={urlPath}
                                stage={stage}
                                foundApplicantIndex={foundApplicantIndex}
                                applicantArr={applicantArr}
                                setApplicantArr={setApplicantArr}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                                receivedToken={receivedToken}
                            />}
                            {stage === 3 && <ZpracovatelComponent18
                                urlPath={urlPath}
                                stage={stage}
                                foundApplicantIndex={foundApplicantIndex}
                                applicantArr={applicantArr}
                                setApplicantArr={setApplicantArr}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                                receivedToken={receivedToken}
                            />}
                            {stage === 4 && <ProjektantComponent18
                                urlPath={urlPath}
                                receivedToken={receivedToken}
                            />}
                            {stage === 5 && <ZhotovitelComponent18
                                urlPath={urlPath}
                                stepsRef={stepsRef}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                                applicantArr={applicantArr}
                                receivedToken={receivedToken}
                            />}
                            {stage === 6 && <KontrolaComponent18
                                urlPath={urlPath}
                                receivedToken={receivedToken}
                                buttonRef={buttonRef}
                                documents={documents}
                                setDocuments={setDocuments}
                            />}
                        </>  :
                        <span>Načítám formulář...</span>}
                </Col>
                <Col lg={{ span: 4 }} xl={{ span: 4 }} className='d-flex justify-content-center justify-content-md-end d-none d-lg-block mb-md-5'>
                    {!isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default Form18MainSection;

Form18MainSection.propTypes = {
    stage: PropTypes.number.isRequired,

    isMobileView: PropTypes.bool,
    handleToken: PropTypes.func.isRequired,
    buttonRef: PropTypes.objectOf(Array),
};