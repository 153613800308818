import React, { useContext, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { jwtDecode } from 'jwt-decode';
import { 
    HomePage, 
    LandingPage, 
    RootLayout, 
    Zamery, 
    Rizeni, 
    Zadosti, 
    RizeniDetail, 
    ZadostiDetail,
    Stavby,
    DokumentaceScreen,
    Documentation,
    NovaZadost, 
    ZadostPovoleniStavby,
    Form01,
    Form02,
    // Form03,
    Form05,
    Form06,
    Form07,
    Form08,
    Form09,
    Form10,
    Form11,
    Form12,
    Form13,
    Form14,
    Form15,
    Form16,
    Form17,
    Form18,
    Form19,
    Unauthorized,
    AccessibilityStatement,
    PersonalData,
    Cookies,
    PortalNewsStatement,
    ProcessError,
    DokumentaceDetail,
    LoginError,
} from '../components/index.js';
import ScrollToTop from '../components/ScrollToTop.jsx';
import UrednikLogin from '../components/Urednik/UrednikLogin.jsx';
import UrednikDocumentation from '../components/Urednik/UrednikDokumentace.jsx';
import UrednikRedirect from '../components/Urednik/UrednikRedirect.jsx';
import { TOKEN_TYPE_ISSR } from '../constants/sharedConstants.js';

const Router = () => {
    const { token } = useContext(AuthContext);
    // Here we will decode token with jwt-decode and if user is "urednik", we will set isOfficer true
    const tokenType = useMemo(
        () => {
            if(!token) {
                return null;
            }
            // For testing purposes
            // return TOKEN_TYPE_ISSR;
            return jwtDecode(token)?.tokenType;
        },
        [token]
    );
    const isOfficer = tokenType === TOKEN_TYPE_ISSR;

    return (
        <>
            <BrowserRouter>
                <ScrollToTop/>
                <Routes>
                    {isOfficer ? (
                        <Route path='/' element={<RootLayout isOfficer={isOfficer} />}>
                            <Route index element={<UrednikRedirect />} />
                            <Route path='/dokumentace/:id' element={<UrednikDocumentation />} />
                            <Route path='*' element={<Unauthorized />} />
                        </Route>
                    ) : token ? (
                        <Route path='/' element={<RootLayout />}>
                            <Route index element={<HomePage />} />
                            <Route path='zamery' element={<Zamery />} />
                            <Route path='rizeni' element={<Rizeni />} />
                            <Route path='zadosti' element={<Zadosti />} />
                            <Route path='rizeni/:id' element={<RizeniDetail />} />
                            <Route path='zadosti/:id' element={<ZadostiDetail />} />
                            <Route path='stavby' element={<Stavby />} />
                            <Route path='dokumentace' element={<DokumentaceScreen />} />
                            <Route path='dokumentace/:id/:token?' element={<DokumentaceDetail />} />
                            {/* for rozpracovane and new */}
                            <Route path='dokumentace/new/:id?' element={<Documentation />} />
                            {/* for new version */}
                            <Route path='dokumentace/version/:id' element={<Documentation />} />
                            {/* TODO check new route for NovaDokumentace */}
                            {/* <Route path='dokumentace/new/:id?' element={<NovaDokumentace />} /> */}
                            <Route path='zadosti/new' element={<NovaZadost />} />
                            <Route path='zadost/form04/:id?' element={<ZadostPovoleniStavby />} />
                            <Route path='zadost/form01/:id?' element={<Form01 />} />
                            <Route path='zadost/form02/:id?' element={<Form02 />} />
                            {/* <Route path='zadost/form03/:id?' element={<Form03 />} /> */}
                            <Route path='zadost/form05/:id?' element={<Form05 />} />
                            <Route path='zadost/form06/:id?' element={<Form06 />} />
                            <Route path='zadost/form07/:id?' element={<Form07 />} />
                            <Route path='zadost/form08/:id?' element={<Form08 />} />
                            <Route path='zadost/form09/:id?' element={<Form09 />} />
                            <Route path='zadost/form10/:id?' element={<Form10 />} />
                            <Route path='zadost/form11/:id?' element={<Form11 />} />
                            <Route path='zadost/form12/:id?' element={<Form12 />} />
                            <Route path='zadost/form13/:id?' element={<Form13 />} />
                            <Route path='zadost/form14/:id?' element={<Form14 />} />
                            <Route path='zadost/form15/:id?' element={<Form15 />} />
                            <Route path='zadost/form16/:id?' element={<Form16 />} />
                            <Route path='zadost/form17/:id?' element={<Form17 />} />
                            <Route path='zadost/form18/:id?/:formType?/:receivedToken?' element={<Form18 />} />
                            <Route path='zadost/form19/:id?' element={<Form19 />} />
                            <Route path='accessibility' element={<AccessibilityStatement />} />
                            <Route path='newsOnPortal' element={<PortalNewsStatement />} />
                            <Route path='personalData' element={<PersonalData />} />
                            <Route path='cookies' element={<Cookies />} />
                            {/* add a mandatory token not to access randomly unauthorized */}
                            <Route path='/unauthorized/:token' element={<Unauthorized />} />
                            <Route path='/error' element={<ProcessError />} />
                            <Route path='*' element={<HomePage />} />
                        </Route>
                    ) : (
                        <Route path='/' element={<RootLayout />}>
                            <Route index element={<LandingPage />} />
                            <Route path='urednik' element={<UrednikLogin />} />
                            <Route path='accessibility' element={<AccessibilityStatement />} />
                            <Route path='newsOnPortal' element={<PortalNewsStatement />} />
                            <Route path='personalData' element={<PersonalData />} />
                            <Route path='cookies' element={<Cookies />} />
                            <Route path='login-error' element={<LoginError />} />
                            <Route path='*' element={<LandingPage />} />
                        </Route>
                    )}
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default Router;
