import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { MoreZadatelTable, ZadatelCard } from '../index';
import { errorIcon, iconPridat } from '../../assets';
import PropTypes from 'prop-types';
import FormContext from '../../formContexts/FormContext';

const ApplicantMoreControls = (
    {
        applicantArr,
        iconCardText,
        iconGrid,
        setCurrentApplicant,
        setZadatelAttachments,
        setShow,
        setApplicantArr,
        powerOfAttorneyError = false,
        forNavrhovatel,
        forOznamovatel,
    }
) => {
    const {stagesArr, stepValue} = useContext(FormContext);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;

    const [isActive, setIsActive] = useState('table');
    const tableSwitchStyle = {
        backgroundColor: isActive === 'table' ? '#2362A2' : '#FFFFFF',
        cursor: 'pointer',
    };

    const tableIconStyle = {
        filter: isActive === 'table' ? 'brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7468%) hue-rotate(282deg) brightness(108%) contrast(127%)'
            : 'brightness(0) saturate(100%) invert(33%) sepia(34%) saturate(973%) hue-rotate(169deg) brightness(101%) contrast(104%)',
        cursor: 'pointer',
    };
    const blockSwitchStyle = {
        backgroundColor: isActive === 'block' ? '#2362A2' : '#FFFFFF',
        cursor: 'pointer',
    };
    
    const blockIconStyle = {
        filter: isActive === 'block' ? 'brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7468%) hue-rotate(282deg) brightness(108%) contrast(127%)'
            : 'brightness(0) saturate(100%) invert(33%) sepia(34%) saturate(973%) hue-rotate(169deg) brightness(101%) contrast(104%)',
        cursor: 'pointer',
    };
    const toggleActiveSwitch = () => {
        setIsActive(prevState => (prevState === 'table' ? 'block' : 'table'));
    };
        
    return (
        <Row className='row-wrapper'>
            {applicantArr?.length > 0 && <Col className='d-flex justify-content-end mb-4' sm={12}>
                <div className='d-flex flex-column align-items-start' style={{width: '100%'}}>
                    <p className='mb-1 form__label'>Zobrazení</p>
                    <div className='d-flex form__switch' style={{width: '100%', height: '40px'}}>
                        <div style={tableSwitchStyle} onClick={toggleActiveSwitch} className='form__switch-left'>
                            <img style={tableIconStyle} width={'14px'} src={iconCardText} />
                        </div>
                        <div style={blockSwitchStyle} onClick={toggleActiveSwitch} className='form__switch-right'>
                            <img style={blockIconStyle} width={'14px'} src={iconGrid} />
                        </div>
                    </div>
                </div>
            </Col>}
                        
            {(applicantArr?.length > 0 && isActive === 'table') && <MoreZadatelTable
                items={applicantArr}
                setCurrentApplicant={setCurrentApplicant} 
                setZadatelAttachments={setZadatelAttachments} 
                setShow={setShow}
                setApplicantArr={setApplicantArr}
            />}

            {(applicantArr?.length > 0 && isActive === 'block') && applicantArr.map((item) =>
                <Col className='p-1' md={6} key={item.id}>
                    <ZadatelCard item={item} applicantArr={applicantArr} setCurrentApplicant={setCurrentApplicant} setZadatelAttachments={setZadatelAttachments} setShow={setShow} setApplicantArr={setApplicantArr}/>
                </Col>)}
            <Col className='p-1'>
                <button 
                    type='button'
                    className={(isActive === 'table' || applicantArr?.length === 0) ? 'd-flex justify-content-center align-items-center add-button--table' : 'd-flex justify-content-center zadatel-card-container align-items-center add-button'}
                    onClick={() => setShow(true)}
                >
                    <img src={iconPridat} alt='iconPridat' className='pe-2' />
                    Přidat {forNavrhovatel ? 'navrhovatele': forOznamovatel ? 'oznamovatele' : 'žadatele'}
                </button>
            </Col>
            {(error && powerOfAttorneyError) &&
                                 <Col xs={{ span: 12, order: 2 }} md={{ span: 12, order: 3 }} className='mt-2'>
                                     <p className='d-flex align-items-center p-0 warning-text'>
                                         <img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />
                                     Bez přidaného {forNavrhovatel ? 'navrhovatele': forOznamovatel ? 'oznamovatele' : 'žadatele'} není možné pokračovat.
                                     </p>
                                 </Col>}
        </Row>
    );
};

ApplicantMoreControls.propTypes = {
    applicantArr: PropTypes.arrayOf(PropTypes.object),
    iconCardText: PropTypes.string,
    iconGrid: PropTypes.string,
    setCurrentApplicant: PropTypes.func,
    setZadatelAttachments: PropTypes.func,
    setShow: PropTypes.func,
    setApplicantArr: PropTypes.func,
    powerOfAttorneyError: PropTypes.bool,
    forNavrhovatel: PropTypes.bool,
    forOznamovatel: PropTypes.bool,
};

export default ApplicantMoreControls;