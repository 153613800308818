import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PARCEL_TYPE_LAND } from '../constants/sharedConstants';

const FoundParcelInfo = ({state, helper}) => {
    const canShowType = ((!(PARCEL_TYPE_LAND.includes(state?.parcel?.parcelType?.trim()?.toLowerCase())) || (helper !== undefined && helper !== 'noNum')) && helper !== 'novaStavba');
    
    return (
        <Col className='construction-info-container my-4' lg={6}>
            <Row className='construction-info-block'>
                <Col xs={5} className='modal-input-style'>
                Druh pozemku dle katastru
                </Col>
                <Col xs={7} className='d-flex align-items-end construction-info-text'>
                    {state?.parcel?.type}
                </Col>
            </Row>
            <Row className={canShowType ? 'construction-info-block' : 'construction-info-block-padding-top'}>
                <Col xs={5} className='modal-input-style'>
                Výměra (m²)
                </Col>
                <Col xs={7} className='d-flex align-items-end construction-info-text'>
                    {state?.parcel?.area}
                </Col>
            </Row>
            {canShowType && <Row className='construction-info-block-padding-top'>
                <Col xs={5} className='modal-input-style'>
                Typ parcely
                </Col>
                <Col xs={7} className='d-flex align-items-end construction-info-text'>
                    {state?.parcel?.parcelType}
                </Col>
            </Row>}
        </Col>
    );
};

FoundParcelInfo.propTypes = {
    state: PropTypes.object,
    helper: PropTypes.string,
};

export default FoundParcelInfo;