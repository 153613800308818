import React, { useContext, useEffect, useState, useImperativeHandle } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FormContext from '../../formContexts/FormContext';
import PropTypes from 'prop-types';
// import { errorIcon } from '../../assets';
import NewConstructionModal from '../Forms/NewConstructionModal';
import { handleClose, handleShow } from '../../helperFunctions/modalHelpers';
import PozemkyTable from '../Forms/ZamerComponentTables/PozemkyTable';
import StavbyTable from '../Forms/ZamerComponentTables/StavbyTable';
import { shouldNotSave, spreadObject, spreadParcelsAndConstructions } from '../../helperFunctions/helpers';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import usePrevious from '../CustomHooks/usePrevious';
import useFirstRender from '../CustomHooks/useFirstRender';
import { useLocation, useNavigate } from 'react-router-dom';

const StavbaComponent18 = ({
    urlPath,

    receivedToken,
}) => {
    const {intention, stepValue, stagesArr, id, validationRef, setStagesArr} = useContext(FormContext);
    const [show, setShow] = useState(false);
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    
    const { token } = useContext(AuthContext);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        buildConstructions: [],
        buildParcels: [],
    });
    // const [ focused, setFocused ] = useState({
    //     obec: false,
    //     psc: false,
    //     ca: false,
    // });
    const [request, setRequest] = useState({
        'applicationId': id,
        accompanyingDocument: {
            buildAddress: {
                city: '',
                cityPart: '',
                street: '',
                descNum: '',
                orientNum: '',
                zip: '',
            },
            newBuilding: false,
            changeOfFinishedBuilding: false,
            buildingPermanent: true,
            buildingTemporary: false,
            buildingTypePurpose: ''
        }
    });
    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();
    const accompanyingDocument =  request?.accompanyingDocument;

    // const handleFocused = (elementToFocus) => {
    //     setFocused(prev => ({...prev, [elementToFocus]: true}));
    // };

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source, receivedToken);
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, receivedToken, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {
                spreadParcelsAndConstructions(
                    response.data?.accompanyingDocument, //obj
                    setParcelAndConstructionData, //func
                    parcelAndConstructionData // obj                
                );
                const accompanyingDocument = response.data?.accompanyingDocument;
                if (accompanyingDocument) {
                    setRequest(state => ({ ...state, accompanyingDocument: 
                        {...state.accompanyingDocument, 
                            ...spreadObject(accompanyingDocument),
                            newBuilding: accompanyingDocument?.newBuilding,
                            changeOfFinishedBuilding: accompanyingDocument?.changeOfFinishedBuilding,
                            buildingPermanent: !accompanyingDocument.buildingTemporary,
                        } 
                    }));
                }
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    useEffect(() => {
        if (
            (request?.buildIntention?.buildParcels) ||
            (request?.buildIntention?.buildConstructions)
        ) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildParcels: [...(request?.buildIntention?.buildParcels ?? [])],
                buildConstructions: [...(request?.buildIntention?.buildConstructions ?? [])],
            }));
            setRequest(prev => ({...prev, buildConstructions: request?.buildIntention?.buildConstructions, buildParcels: request?.buildIntention?.buildParcels}));
        }

        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }
        saveChanges();
    }, [request.accompanyingDocument, request.buildIntention]);

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (request.accompanyingDocument.newBuilding && !request.accompanyingDocument.buildingTypePurpose.trim()) {
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <Container fluid>
            <Row className='row-wrapper'>
                <h2 className='p-0 main-title'>{`${stepValue}. Stavba`}</h2>
            </Row>    
            <Row className='border p-4 row-wrapper'>
                <h3 className='p-0 id-title'>Místo stavby</h3>
                <Row className='d-flex p-0 pb-4'>
                    <Col md={6}>
                        <label className='p-0 povoleni-label' /* style={{color: error && !accompanyingDocument?.buildAddress?.city?.trim() ? '#C52A3A' : ''}} */>
                    Obec
                            <input 
                                disabled={true}
                                type='text'
                                // required
                                // {...(focused.obec && { focused: 'true' })}
                                defaultValue={accompanyingDocument?.buildAddress?.city}
                                // style={{border: error && !accompanyingDocument?.buildAddress?.city?.trim() ? '#C52A3A solid 1px' : ''}}
                                onBlur={(e) => {
                                    setRequest(prev => ({...prev, accompanyingDocument: {...prev.accompanyingDocument, buildAddress: {...prev.accompanyingDocument.buildAddress, city: e.target.value}}}));
                                    // handleFocused('obec');
                                }}
                                className='mt-1 id-input2 waterworks bg-white p-2'
                            />
                            {/* {error && !accompanyingDocument?.buildAddress?.city?.trim() && <Container className='input-message--error' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;Obec&quot; není možné pokračovat.
                            </Container>} */}
                        </label>
                    </Col>
                </Row>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='select-input'>
                    Část obce
                            <input type='text' disabled={true} defaultValue={accompanyingDocument?.buildAddress?.cityPart}
                                onBlur={(e) => {setRequest(prev => ({...prev, accompanyingDocument: {...prev.accompanyingDocument, buildAddress: {...prev.accompanyingDocument.buildAddress, cityPart: e.target.value}}}));}}
                                className='mt-1 form-zamer-input waterworks bg-white p-2'
                            />
                        </label>
                    </Col>
                </Row>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='select-input'>
                    Ulice
                            <input type='text' disabled={true} defaultValue={accompanyingDocument?.buildAddress?.street}
                                onBlur={(e) => {setRequest(prev => ({...prev, accompanyingDocument: {...prev.accompanyingDocument, buildAddress: {...prev.accompanyingDocument.buildAddress, street: e.target.value}}}));}}
                                className='mt-1 form-zamer-input waterworks bg-white p-2'
                            />
                        </label>
                    </Col>
                </Row>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='select-input'>
                    Číslo popisné
                            <input type='text' disabled={true} defaultValue={accompanyingDocument?.buildAddress?.descNum}
                                onBlur={(e) => {setRequest(prev => ({...prev, accompanyingDocument: {...prev.accompanyingDocument, buildAddress: {...prev.accompanyingDocument.buildAddress, descNum: e.target.value}}}));}}
                                className='mt-1 form-zamer-input waterworks bg-white p-2'
                            />
                        </label>
                    </Col>
                    <Col md={6}>
                        <p className='mt-3 mt-md-4 building-change ps-3'>
                    Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.
                        </p>
                    </Col>
                </Row>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='select-input'>
                    Číslo orientační
                            <input type='text' disabled={true} defaultValue={accompanyingDocument?.buildAddress?.orientNum}
                                onBlur={(e) => {setRequest(prev => ({...prev, accompanyingDocument: {...prev.accompanyingDocument, buildAddress: {...prev.accompanyingDocument.buildAddress, orientNum: e.target.value}}}));}}
                                className='mt-1 form-zamer-input waterworks bg-white p-2'
                            />
                        </label>
                    </Col>
                </Row>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='p-0 povoleni-label' /* style={{color: error && !accompanyingDocument?.buildAddress?.zip?.trim() ? '#C52A3A' : ''}} */>
                        PSČ
                            <input
                                disabled={true}
                                type='text'
                                // required
                                // {...(focused.psc && { focused: 'true' })}
                                defaultValue={accompanyingDocument?.buildAddress?.zip}
                                // style={{border: error && !accompanyingDocument?.buildAddress?.zip?.trim() ? '#C52A3A solid 1px' : ''}}
                                onBlur={(e) => {
                                    setRequest(prev => ({...prev, accompanyingDocument: {...prev.accompanyingDocument, buildAddress: {...prev.accompanyingDocument.buildAddress, zip: e.target.value}}}));
                                    // handleFocused('psc');
                                }}
                                className='mt-1 id-input2 waterworks bg-white p-2'
                            />
                            {/* {error && !accompanyingDocument?.buildAddress?.zip?.trim() && <Container className='input-message--error' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;PSČ&quot; není možné pokračovat.
                            </Container>} */}
                        </label>
                    </Col>
                </Row>
                {/* TODO see what to do with these two */}
                {/* <Row className='d-flex pb-4'>
                <label className='p-0 povoleni-label'>
                    Kraj
                    <Container fluid>
                        <Row>
                            <Col xs={12} md={6}>
                                <select type='select' defaultValue={request.accompanyingDocument.buildAddress.region}
                                    style={{appearance: 'none', background: `url(${arrowDownBlue}) no-repeat right .8rem center`, padding: '.5rem 2rem .5rem .8rem', backgroundColor: 'white', borderRadius: '3px', border: 'solid 1px #DDDDDD'}}
                                    onChange={(e) => setRequest(prev => ({...prev, accompanyingDocument: {...prev.accompanyingDocument, buildAddress: {...prev.accompanyingDocument.buildAddress, region: e.target.value}}})) }
                                    className='id-input2 w-100'
                                >
                                    <option value={'1'}>Olomoucky</option>
                                    <option value={'2'}>Olomoucky</option>
                                    <option value={'3'}>Olomoucky</option>
                                </select>
                            </Col>
                        </Row>
                    </Container>
                </label>
            </Row> */}
                {/* <Row className='d-flex'>
                <Col md={6}>
                    <label className='p-0 povoleni-label' style={{color: error && !accompanyingDocument?.buildAddress?.cadastralArea?.trim() ? '#C52A3A' : ''}}>
                    Katastrální území
                        <input
                            type='text'
                            required
                            {...(focused.ca && { focused: 'true' })}
                            defaultValue={accompanyingDocument?.buildAddress?.cadastralArea}
                            style={{border: error && !accompanyingDocument?.buildAddress?.cadastralArea?.trim() ? '#C52A3A solid 1px' : ''}}
                            onBlur={(e) => {
                                setRequest(prev => ({...prev, accompanyingDocument: {...prev.accompanyingDocument, buildAddress: {...prev.accompanyingDocument.buildAddress, cadastralArea: e.target.value}}}));
                                handleFocused('ca');
                            }}
                            className='mt-1 id-input2 waterworks bg-white p-2'
                        />
                        {!error && <Container className='input-message--error' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                    Bez vyplnění pole &quot;Katastrální území&quot; není možné pokračovat.
                        </Container>}
                    </label>
                </Col>
            </Row> */}
            </Row>
            <Row className='row-wrapper'>
                <p className='p-0 pb-4 section-title'>Typ stavby</p>
                <Row className='d-flex'>
                    <Col md={6} sm={12}>
                        <label className='mb-2 id-checkbox-label'>
                            <input type="checkbox" className='id-checkbox-input' checked={accompanyingDocument?.newBuilding}
                                onChange={(e) => { 
                                    setRequest(prev => ({ ...prev, accompanyingDocument: { ...prev.accompanyingDocument, newBuilding: e.target.checked }}));
                                }} 
                            />
                        Nová
                        </label>
                    </Col>                  
                </Row>
                <Row className='d-flex'>
                    <Col md={6} sm={12}>
                        <label className='mb-2 id-checkbox-label'>
                            <input type="checkbox" className='id-checkbox-input' checked={accompanyingDocument?.changeOfFinishedBuilding}
                                onChange={(e) => { 
                                    setRequest(prev => ({ ...prev, accompanyingDocument: { ...prev.accompanyingDocument, changeOfFinishedBuilding: e.target.checked }}));
                                }} 
                            />
                        Změna dokončené stavby
                        </label>
                    </Col>                  
                </Row>
            </Row>
            <Row className='row-wrapper'>
                <p className='p-0 pb-4 section-title'>Trvání stavby</p>
                <Row className='d-flex pb-2'>
                    <Col md={6} sm={12}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                className='radio'
                                checked={accompanyingDocument.buildingPermanent}
                                onChange={() => { 
                                    setRequest(prev => ({ ...prev, accompanyingDocument: { ...prev.accompanyingDocument, buildingPermanent: true, buildingTemporary: false }})); 
                                }}
                            />
                        Trvalá
                        </label>
                    </Col>                     
                </Row>
                <Row className='d-flex'>
                    <Col md={6} sm={12}>
                        <label className='d-flex label'>
                            <input
                                type="radio"
                                className='radio'
                                checked={accompanyingDocument.buildingTemporary}
                                onChange={() => { setRequest(prev => ({ ...prev, accompanyingDocument: { ...prev.accompanyingDocument,  buildingPermanent: false, buildingTemporary: true } })); }}
                            />
                        Dočasná
                        </label>
                    </Col>
                </Row>
            </Row>
            {accompanyingDocument.newBuilding
        &&
                            <Row className='row-wrapper'>
                                <Col md={6} sm={12}>
                                    <p className='p-0 m-0 mb-1 new-construction-help-text' style={{color: error && !request?.accompanyingDocument?.buildingTypePurpose.trim() ? '#C52A3A' : ''}}>Druh a účel stavby</p>
                                    <label className='id-checkbox-label w-100'>
                                        <input type="text" value={request?.accompanyingDocument?.buildingTypePurpose} className='new-construction-input' disabled/>
                                        <button
                                            type='button'
                                            className='d-flex justify-content-center align-items-center new-construction-button'
                                            style={{border: error && !request?.accompanyingDocument?.buildingTypePurpose.trim() ? 'solid 1px #C52A3A' : ''}}
                                            onClick={() => handleShow(setShow)}
                                        >
                                            Vybrat
                                        </button>
                                    </label>
                                </Col>
                                <Col md={6} sm={12} className='d-flex'>
                                    <p className='building-change align-content-end mb-md-2 mt-2 ps-0 ps-md-3'>
                                            V případě souboru staveb uveďte stavbu hlavní
                                    </p>
                                </Col>
                                <NewConstructionModal 
                                    show={show} 
                                    setShow={setShow}
                                    handleClose={handleClose}
                                    setRequest={setRequest}
                                    object={'accompanyingDocument'}
                                />
                            </Row>
            }
        
            <PozemkyTable
                subtitle={'na kterých se má záměr uskutečnit'}
                urlPath={urlPath}
                
                dataToAdd={parcelAndConstructionData.buildParcels}
                setDataToAdd={setParcelAndConstructionData}
                receivedToken={receivedToken}
            />
        
            <StavbyTable
                title={'Stavby a jejich parametry'}
                subtitle={<>Povinné TEA je možné vyplnit až po přidání stavby.<br /><br />Pro rámcové povolení, nejde-li o záměry v působnosti jiného stavebního úřadu, jež slouží nebo mají sloužit k zajišťování obrany a bezpečnosti státu, které se nenachází v areálech dokončených staveb údaje Stavby a jejich parametry nevyplňujte</>}
                urlPath={urlPath} 
                
                dataToAdd={parcelAndConstructionData.buildConstructions}
                receivedToken={receivedToken}
                setDataToAdd={setParcelAndConstructionData}
            />
        </Container>);
};

StavbaComponent18.propTypes = {
    urlPath: PropTypes.string.isRequired,

    receivedToken: PropTypes.string,
};

export default StavbaComponent18;