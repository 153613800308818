
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../config/api-config.js';
import {
    DOCUMENT_FIFTH,
    DOCUMENT_FIRST,
    DOCUMENT_FOURTH,
    DOCUMENT_SECOND,
    PLNA_MOC_FIRST,
    WARRANT,
    ACCEPTED_FORMATS_ARRAY,
    ACCEPTED_FORMATS_ARRAY_CSV,
    ACCEPTED_FORMATS_ARRAY_DOKLADOVA_CAST,
    ACCEPTED_FORMATS_ARRAY_DOKUMENTACE_PRILOHY,
    AFFECTED_POZEMKY_TABLE,
    AFFECTED_STAVBY_TABLE,
    APPLICANT_MODEL,
    APPLICANT_TYPE_FO,
    APPLICANT_TYPE_FOP,
    APPLICANT_TYPE_PO,
    ATTORNEY_TYPE_FO,
    ATTORNEY_TYPE_FOP,
    ATTORNEY_TYPE_PO,
    BUILDING,
    DOCUMENT_THIRD_1,
    DOCUMENT_THIRD_2,
    DOCUMENT_THIRD_3,
    DOCUMENT_THIRD_4,
    DOCUMENT_THIRD_5,
    FORM_10_INTENTION,
    FORM_11_INTENTION,
    FORM_12_INTENTION,
    FORM_13_INTENTION,
    FORM_14_INTENTION,
    PLNA_MOC_SECOND,
    PLNA_MOC_THIRD,
    PLNA_MOC_FOURTH,
    POST_BODY_FOR_FILTER,
    POZEMKY_TABLE,
    RESPECTIVE_SECTION_DOCUMENT_NAMES,
    RIZENI,
    STAVBY_TABLE,
    PLNA_MOC_FIFTH,
    ZADOSTI, POWER_OF_ATTORNEY_FILE_MODEL,
    TOTAL_UPLOAD_LIMIT,
    AUTHORITY_MODEL,
    DOKUMENTACE,
    TOTAL_PRILOHY_UPLOAD_LIMIT,
    TOTAL_PRILOHY_UPLOAD_LIMIT_FORM03,
    AFFIDAVIT,
    OVERALL_SITUATION,
    OWNER_CONSENT,
    EXPRESSION,
    CONSENT_ALL,
    PARTICIPANTS_LIST,
    COMPLIANCE_PROOF,
    PLANNING_CONTRACT,
    FULFILLING_PRODUCT,
    OTHER_DOCUMENTS,
    OWNER_CONSENT_OTHER,
    TESTS_EVALUATION,
    OPERATION_EVALUATION,
    BUILDING_CERTIFICATE,
    INSPECTOR_OPINION,
    CONTRACTOR_AGREEMENT,
    NEIGHBORING_OWNER_PROPERTY,
    PROOF_OF_QUALIFICATION,
    SIMPLE_TECHNICAL_DESCRIPTION,
} from '../constants/sharedConstants.js';
import { v4 as uuidv4 } from 'uuid';
import { getFormById, formSave } from '../apiCalls/formApiCalls.js';
import { deleteExistingZamer, isRequestOK, makeCopyZadostiById } from '../apiCalls/componentsApiCalls.js';
import { INITIAL_OBJ } from '../constants/form02.js';
import authConfig from '../config/authorization-config.js';

const convertBytes = (bytes, decimals = 4) => {
    if (isNaN(bytes) || Number(bytes) <= 0) {
        return '';
    }
      
    const kbToBytes = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB',];
      
    const index = Math.floor(
        Math.log(bytes) / Math.log(kbToBytes),
    );
      
    return `${parseFloat(
        (bytes / Math.pow(kbToBytes, index)).toFixed(dm)
    ).toString().replace('.', ',')} ${sizes[index]}`;
};

const shouldShowProgress = (uploadSuccess, uploadCancelled, uploadError, progress, flagged, message) => {
    if (uploadSuccess || uploadCancelled || uploadError || message) {
        return false;
    }
    
    if ((flagged && !progress) || progress === 100) {
        return false;
    }

    return true;
};

const shouldNotFetch = (uploadSuccess, uploadCancelled, uploadError, message) => {    
    return Boolean(uploadSuccess || uploadCancelled || uploadError || message !== undefined);
};

const getNewAbortController = () => {
    return new AbortController();
};

const cancelUpload = (controller) => {
    controller?.abort();
};

const getUrl = async (token) => {
    const source = axios.CancelToken.source();
    try {
        const config = {    
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            cancelToken: source.token,
        };
        const response = await axios.get(
            urlJoin(apiConfig.validateAndPersistBaseUrl, 'blob-storage/get-url'),
            config
        );
        if ((200 <= response?.status) && (response?.status < 300)) {
            return response.data;
        }
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error(error);
        }
    }
};

const extractFileName = (name, shouldExtractExtension = false) => {
    if (!name) {
        return '';
    }
    const baseName = name.replace(/\.[^/.]+$/, '');
    return shouldExtractExtension ? baseName : name;
};

const extractExtensionName = (name) => {
    if (name) {
        return name.split('.').pop().toLowerCase();
    }
};

const isTrackableUpload = ( // : bool
    keyName, // str
    shouldGetKey = false,
) => {
    if (!keyName) {
        return false;
    }
    if (!RESPECTIVE_SECTION_DOCUMENT_NAMES[keyName]) {
        return false;
    }

    return shouldGetKey ? RESPECTIVE_SECTION_DOCUMENT_NAMES[keyName] : Boolean(RESPECTIVE_SECTION_DOCUMENT_NAMES[keyName]);
};

const isNotForDokumentace = (category) => {
    const dokumentaceCategories = [
        DOCUMENT_FIRST,
        DOCUMENT_SECOND,
        DOCUMENT_THIRD_1,
        DOCUMENT_THIRD_2,
        DOCUMENT_THIRD_3,
        DOCUMENT_THIRD_4,
        DOCUMENT_THIRD_5,
        DOCUMENT_FOURTH,
        DOCUMENT_FIFTH
    ];
    return dokumentaceCategories.indexOf(category) === -1;
};
// file upload size check is done separately on filterFiles
const canUploadFile = (
    file, // obj
    category, // str
) => { // : obj | bool
    const maxSize = (100 * 1024 * 1024); // 100mb based on Radan's desc.

    if (file.size > maxSize && isNotForDokumentace(category)) {
        console.log('size exceeded for non-dokumentace files');
        return {message: 'size'};
    }

    const fileExtension = extractExtensionName(file.name);

    if (category.startsWith('csv_import') && ACCEPTED_FORMATS_ARRAY_CSV.indexOf(fileExtension) === -1) {
        // putting an array in case clients may add other formats in the future
        console.log('not a csv file');
        return {message: 'format'};
    }
    // for power of attorney files there's no restriction announced so keeping all file types
    if (category.startsWith('power_of_attorney') && ACCEPTED_FORMATS_ARRAY.indexOf(fileExtension) === -1) {
        console.log('incorrect format');
        return {message: 'format'};
    }

    if (category === DOCUMENT_FIFTH && ACCEPTED_FORMATS_ARRAY_DOKLADOVA_CAST.indexOf(fileExtension) === -1) {
        console.log('incorrect format');
        return {message: 'format'};
    }
    
    if (!category.startsWith('csv_import') &&
        !category.startsWith('power_of_attorney') &&
         category !== DOCUMENT_FIFTH &&
         ACCEPTED_FORMATS_ARRAY_DOKUMENTACE_PRILOHY.indexOf(fileExtension) === -1) {
        console.log('incorrect format');
        return {message: 'format'};
    }

    return true;
};

const getTotalValueFromUploads = (arr, type = 'progress') => { // :num
    return [...(arr || [])].reduce((acc, curr) => acc + (Number(curr?.[type]) || 0), 0);
};

const getTotalValue = (arr) => { // :num
    return [...(arr || [])].reduce((acc, curr) => acc + (Number(curr) || 0), 0);
};

const getOngoingUploads = (onGoingUploads) => {
    if (Array.isArray(onGoingUploads)) {
        return [...new Map([...(onGoingUploads || [])].map(el => [el.uid, el])).values()]
            .filter(el => ((el?.blobFile || el?.abortController) && !el?.message));
    } else {
        return [];
    }
};

const filterFiles = (
    fileListObj, // arr
    category, // str
    uploadedBytes, // num
    setUploadError, // func
    allUploads, // []
    isForPrilohy = false,
    isException = false,
) => { // : obj | arr
    const files = Object.values(fileListObj);
    if (files.length === 0) {
        return [];
    }

    let currentlyUploadingBytes = 0;
    if (Array.isArray(allUploads)) {
        const onGoingUploads = getOngoingUploads(allUploads);
        currentlyUploadingBytes = getTotalValueFromUploads(onGoingUploads, 'size');    
    }

    if (!isForPrilohy) {
        const isTotalLimitExceeding = (getTotalValueFromUploads(files, 'size') + Number(uploadedBytes) + currentlyUploadingBytes) > TOTAL_UPLOAD_LIMIT;
        if (isTotalLimitExceeding) {
            setUploadError(prev => [...new Map([...prev, {
                where: category,
                why: 'total_size',
                uid: uuidv4(),
            }].map(el => [el.uid, el])).values()]);  
            return;
        }
        
    }

    if (isForPrilohy) {
        const isTotalLimitExceeding = (getTotalValueFromUploads(files, 'size') + Number(uploadedBytes) + currentlyUploadingBytes) > (isException ? TOTAL_PRILOHY_UPLOAD_LIMIT_FORM03 : TOTAL_PRILOHY_UPLOAD_LIMIT);
        if (isTotalLimitExceeding) {
            setUploadError(prev => [...new Map([...prev, {
                where: category,
                why: 'total_size_prilohy',
                uid: uuidv4(),
            }].map(el => [el.uid, el])).values()]);
            return;
        }
    }
    
    return files.map((el) => {
        if (canUploadFile(el, category) instanceof Object) {
            return Object.assign(el, canUploadFile(el, category));
        } else if (canUploadFile(el, category) === true) {
            return el;
        }
    });
};

const isDokumentace = (stepValue, stagesArr) => {
    return stagesArr?.find(el => el.stage === stepValue)?.name === 'Dokumentace';
};

const isPrilohy = (stepValue, stagesArr) => {
    return stagesArr?.find(el => el.stage === stepValue)?.name === 'Přílohy';
};

const isTrackableForPrilohy= (category) => {
    const prilohyCategories = [
        AFFIDAVIT,
        OVERALL_SITUATION,
        OWNER_CONSENT,
        EXPRESSION,
        CONSENT_ALL,
        PARTICIPANTS_LIST,
        COMPLIANCE_PROOF,
        PLANNING_CONTRACT,
        FULFILLING_PRODUCT,
        OTHER_DOCUMENTS,
        OWNER_CONSENT_OTHER,
        TESTS_EVALUATION,
        OPERATION_EVALUATION,
        BUILDING_CERTIFICATE,
        INSPECTOR_OPINION,
        CONTRACTOR_AGREEMENT,
        NEIGHBORING_OWNER_PROPERTY,
        PROOF_OF_QUALIFICATION,
        SIMPLE_TECHNICAL_DESCRIPTION,
    ];

    return prilohyCategories.indexOf(category) > -1;
}; 

const isTrackableForDokumentace = (category) => {
    const dokumentaceCategories = [
        DOCUMENT_FIRST,
        DOCUMENT_SECOND,
        DOCUMENT_THIRD_1,
        DOCUMENT_THIRD_2,
        DOCUMENT_THIRD_3,
        DOCUMENT_THIRD_4,
        DOCUMENT_THIRD_5,
        DOCUMENT_FOURTH,
        DOCUMENT_FIFTH
    ];
    return dokumentaceCategories.indexOf(category) > -1;
};

const spreadUploadedDocuments = ( // void|
    docOrDocs, // obj || arr
    setDocuments,
    category = undefined, 
    setBytes = undefined, 
    isForm18 = undefined, 
    isForExistingDocumentation = undefined
) => {
    if (category) {
        if (docOrDocs instanceof Object && docOrDocs?.fileName !== null) {
            if (isForm18) {
                setDocuments((prev) => ({ ...prev, [category]: [...(prev[category] || []).filter(el => el.subSection !== 'cover_letter_pdf'), docOrDocs]}));
                return;
            }
            setDocuments((prev) => ({ ...prev, [category]: [...(prev[category] || []), docOrDocs]}));
        }
        return;
    }

    if (!(docOrDocs instanceof Array)) {
        console.log('unknown type');
        return;
    }

    const getAccumulatedBytes = () => {
        return [...new Map([...(docOrDocs) || []].map(el => [el.uid, el])).values()].filter(el => !el.message).reduce((acc, curr) => acc + (Number(curr?.size) || 0), 0);
    };

    const setFiles = (cat, doc) => {
        if (isForm18 && doc?.fileName !== null && doc?.section === DOCUMENT_FIRST) {
            // filter out for edge case
            setDocuments(prev => ({ ...prev, [DOCUMENT_FIRST]: [
                ...new Map([...(prev[DOCUMENT_FIRST] || []), doc].map(el => [el.uid, el])).values()
            ].filter(el => el.section === DOCUMENT_FIRST) }));
            return;
        } 
        
        if (cat && doc?.fileName !== null) {      
            setDocuments(prev => ({ ...prev, [cat]: [...(new Map([...(prev[cat] || []), doc]
                .map(el => [el.uid, el]))
                .values()) || []]
                .filter(doc => {
                    if (isForExistingDocumentation) {
                        return doc.existing === true;
                    } 
                    return true;
                }) 
            }));
        }   
    };

    if (!docOrDocs.length) {
        setDocuments(prev => {
            const resetObject = {};
            for (let key in prev) {
                if (Object.hasOwn(prev, String(key))) {
                    resetObject[key] = [];
                }
            }

            return resetObject;
        });
        return;
    }

    const flaggedDocuments = docOrDocs.map(el => ({ ...el, flagged: true, ...(isForExistingDocumentation ? {existing: true} : {}) })); 
    flaggedDocuments.forEach(document => setFiles(document?.section, document));

    if(setBytes && docOrDocs?.filter(el => isTrackableForDokumentace(el?.section))?.every(el => !el.abortController && !el.blobFile)) {
        setBytes(Number(getAccumulatedBytes()));
    }
};
// TODO refactor up and down later to avoid redundancy
const setUploadedDocumentsSizeFromZamer = (documentsArray, setUploadedBytes) => {
    if (!Array.isArray(documentsArray)) {
        return;
    }
    const totalSize = getTotalValueFromUploads(documentsArray, 'size');
    setUploadedBytes(totalSize);
};

const spreadUploadedModalDocuments = (doc, setDocuments, category) => {
    if (category && category.startsWith('csv_import')) {
        if (doc instanceof Object) {
            setDocuments((prev) => ({ ...prev, [category]: [doc]}));
        }
        return;
    }

    const flaggedDocuments = doc instanceof Array && doc.map(el => ({ ...el, flagged: true }));
    
    const acceptedSections = [
        POZEMKY_TABLE,
        STAVBY_TABLE,
        AFFECTED_POZEMKY_TABLE,
        AFFECTED_STAVBY_TABLE,
    ];

    flaggedDocuments.forEach(el => {
        const cat = el.section;
        if (acceptedSections.indexOf(cat) > -1) {
            setDocuments(prev => ({ ...prev, [cat]: [...(prev[cat] || []),  el] }));
        }
    });
};

const spreadUploadedAttachments = (att, setAttachments, category, setBytes = null) => {
    if (att instanceof Array) {    
        const flaggedAttachments = att.map(el => ({ ...el, flagged: true }));
        flaggedAttachments.forEach(el => {
            if (el.section && el.fileName !== null) {
                setAttachments(prev => ({ ...prev, [el.section]: [...(new Map([...(prev[el.section] || []), el]
                    .map(el => [el.uid, el]))
                    .values()) || []]
                }));
            }
        });
    } else if (att instanceof Object && att.fileName !== null) {
        if (category) {
            setAttachments((prev) => ({ ...prev, [category]: [...(prev[category] || []), att]}));
        } else {
            setAttachments(prev => ({ ...prev, [WARRANT]: [...new Map([...(prev[WARRANT] || []), { ...att, flagged: true }].map(el => [el.uid, el])).values()] }));
        }
    }

    if (att instanceof Array) {
        const getAccumulatedBytes = () => {
            return [...new Map([...(att) || []].map(el => [el.uid, el])).values()].filter(el => !el.message).reduce((acc, curr) => acc + (Number(curr?.size) || 0), 0);
        };

        if(setBytes && att?.filter(el => isTrackableForPrilohy(el?.section))?.every(el => !el.abortController && !el.blobFile)) {
            setBytes(Number(getAccumulatedBytes()));
        }
    }
};

const isNotUploading = (ongoingUploads) => {
    if ([...(ongoingUploads || [])]?.some((el) => ((el?.blobFile || el?.abortController) && !el?.message))) {
        return false;
    }

    return true;
};

const shouldNotGoToPreviousPage = (startIndex, resultPerPage) => {
    return startIndex - resultPerPage < 0;  
};

const shouldNotGoToFirstPage = (startIndex, resultPerPage) => {
    return (startIndex === 0 || startIndex - resultPerPage < 0);
};

const shouldNotGoToLastPage = (startIndex, listData, resultPerPage) => {
    return (startIndex === listData.length -1 || startIndex + resultPerPage > listData.length -1);
};

const shouldNotGoToNextPage = (startIndex, listData, resultPerPage) => {
    return startIndex + resultPerPage > listData.length -1;
};

const getFormType = (path, getString = false) => {
    if (getString) {
        const matchedPattern = path?.match(/build-application-([0-9]+)|(documentation)/);
        return matchedPattern?.[1] ?? matchedPattern?.[2] ?? '';
    }

    return Number(/[1-9]+\d*/.exec(path)?.[0]) ?? path;
};

const mapIntentionData = (
    list //ArrayOf(Object) (pozemky nebo stavby)
) => {
    if (list.length === 0) {
        return;
    }
    const mappedList = [...list].map(obj => ({...obj, uid: uuidv4()}));
    return mappedList;
};

const spreadParcelsAndConstructions = (
    data, //obj
    setParcelAndConstructionData, //func
    parcelAndConstructionData // obj
) => {
    const affectedBuildConstructions = data?.affectedBuildConstructions;
    if (affectedBuildConstructions instanceof Array && Object.hasOwn(parcelAndConstructionData, 'affectedBuildConstructions')) {
        if (affectedBuildConstructions.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, affectedBuildConstructions: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, affectedBuildConstructions: mapIntentionData(affectedBuildConstructions)}));
        }
    }

    const affectedBuildParcels = data?.affectedBuildParcels;
    if (affectedBuildParcels instanceof Array && Object.hasOwn(parcelAndConstructionData, 'affectedBuildParcels')) {
        if (affectedBuildParcels.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, affectedBuildParcels: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, affectedBuildParcels: mapIntentionData(affectedBuildParcels)}));
        }
    }

    const buildConstructions = data?.buildConstructions;
    if (buildConstructions instanceof Array && Object.hasOwn(parcelAndConstructionData, 'buildConstructions')) {
        if (buildConstructions.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, buildConstructions: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, buildConstructions: mapIntentionData(buildConstructions)}));
        }
    }

    const buildParcels = data?.buildParcels;
    if (buildParcels instanceof Array && Object.hasOwn(parcelAndConstructionData, 'buildParcels')) {
        if (buildParcels.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, buildParcels: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, buildParcels: mapIntentionData(buildParcels)}));
        }
    }

    const approvedConstructions = data?.approvedConstructions;
    if (approvedConstructions instanceof Array && Object.hasOwn(parcelAndConstructionData, 'approvedConstructions')) {
        if (approvedConstructions.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, approvedConstructions: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, approvedConstructions: mapIntentionData(approvedConstructions)}));
        }
    }

    const designers = data?.designers;
    if (designers instanceof Array && Object.hasOwn(parcelAndConstructionData, 'designers')) {
        if (designers.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, designers: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, designers: mapIntentionData(designers)}));
        }
    }
};

const findChosenIntention = ( // bool | obj
    input, //str
    zameryList //ArrayOf(Object)
) => {
    if (input?.trim() === '') {
        return false;
    }

    const str = input?.split(/\s*-\s*/)?.[0]?.trim() || null;
    const chosenIntention = str ? zameryList.find(el => {
        const projectId = el.projectId?.trim().toLowerCase();
        return projectId === str.toLowerCase();
    }) : null;

    // const insertedIntention = input.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g);
    // if (!insertedIntention) {
    //     return false;
    // }

    // const chosenIntention = [...zameryList].find((el) => el.id === insertedIntention[0]);
    // if (!chosenIntention) {
    //     return false;
    // }

    return chosenIntention ?? false;
};

const shouldNotSave = (
    prevReq, //obj
    req, //obj
    firstRender = undefined // bool | undefined
) => {
    if (firstRender) {
        return true;
    }

    return Boolean(JSON.stringify(prevReq) === JSON.stringify(req));
};

const canInitiateSaveForPrijemce = (
    firstRender, // obj,
    request, //obj
    prevRequest, // obj
    // eslint-disable-next-line no-unused-vars
    authorityType = undefined, // str
) => {
    if (firstRender) {
        return false;
    }

    // avoid multiple save for same object
    if (shouldNotSave(prevRequest, request)) {
        return false;
    }

    return true;
};

const canFulFillPrijemceList = (
    req = undefined, // obj
    firstRender, // obj,
    searchRequest, // obj
    setPrijemceList, // fn
    authorityType = undefined, // str
) => {
    if (firstRender || (req?.buildIntention?.[authorityType]?.authorityName === searchRequest?.title)) {
        return false;
    }

    if (searchRequest.title?.trim()?.length === 0) {
        setPrijemceList([]);
        return false;
    }

    return true;
};

const appendNumberToGovLabel = (
    developpedZadostiLength, // num | null
) => {
    if (developpedZadostiLength === null) {
        document.querySelector('.developed-amount')?.remove();
        return;
    }
    // as gov buttons are not compatible with "react", using this below.
    setTimeout(() => {
        const cardBtn = document.querySelectorAll('.gov-tabs__item')[1];
        if (!cardBtn) {
            return;
        }
        
        if (developpedZadostiLength > 0  && !document.querySelector('.developed-amount')) {           
            const amountSpan = document.createElement('span');
            amountSpan.className = 'developed-amount';
            amountSpan.textContent = String(developpedZadostiLength);
            cardBtn?.appendChild(amountSpan);
        }
    }, 250);
};

const updateNumberOnGovLabel = (
    developpedZadostiLength, // num
) => {
    if (developpedZadostiLength === 0) {
        document.querySelector('.developed-amount')?.remove();
        return;
    }

    if (document.querySelector('.developed-amount')) {
        document.querySelector('.developed-amount').textContent = String(developpedZadostiLength);
    }
};

const setCsvDataAndRequest = (
    listData, // arr
    setList, // func
    setReq, // func
    key, // str
    data, // array
    isFor18 = undefined // str|undefined
) => {
    if (!data) {
        return;
    }

    if (listData.length === 0 && data?.length === 0) {
        return;
    }
    setList(data);

    if (isFor18) {
        setReq(prev => ({ ...prev, accompanyingDocument: {...prev.accompanyingDocument, [key]: [...data]
            .map(el => {
                if (Object.hasOwn(el, 'uid')) {
                    // eslint-disable-next-line no-unused-vars
                    const {uid, ...others} = el;
                    return others;
                } else {
                    return el;
                }
            })}
        }));
    } else {
        setReq(prev => ({ ...prev, [key]: [...data]
            .map(el => {
                if (Object.hasOwn(el, 'uid')) {
                    // eslint-disable-next-line no-unused-vars
                    const {uid, ...others} = el;
                    return others;
                } else {
                    return el;
                }
            })
        }));
    }
};

const getPrijemceList = (
    searchReq, // obj
    token, // str
    setReq, // func
    setLoading,
    signal
) => {
    if (searchReq?.typeTitle === null) {
        return;
    }

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        
        signal
    };

    setLoading(true);
    (async () => {
        try {
            const response = await axios.post(
                urlJoin(apiConfig.getAndQueryBaseUrl, 'organizations/search'),
                searchReq,
                config
            );

            if (!signal.aborted && isRequestOK(response.status)) {
                setReq([...new Map([...response.data.data]
                    .map(el => [el.id, el])).values()]);
                setLoading(false);
            }
        }  catch (error) {
            if (!signal.aborted) {
                console.error(error);
                setLoading(false);
            }
        }
    })();
};

const spreadObject = ( // : obj
    obj, // obj
) =>  {
    return Object.entries(obj)
        // eslint-disable-next-line no-unused-vars
        .filter(([_, val]) => val !== null)
        .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});
};

const getBuildApplicationFormForPrijemce = async (
    id, // str
    token, // str
    intention, // str
    setReq, // func
    setSearchRequest, // func
    dynamicKey, // str | null
    authorityType, // str
    pathname, // str
    navigate // fn
) => {
    const source = axios.CancelToken.source();

    if (id) {
        const response = await getFormById(id, token, source, intention, null, pathname, navigate);

        if ((200 <= response?.status) && (response?.status < 300)) {
            const intention = response.data?.buildIntention;

            if (intention) {
                setReq(state => ({ ...state, buildIntention: {...state.buildIntention, ...spreadObject(intention), [authorityType]: intention?.[authorityType]} }));
            }     
            if (intention?.[authorityType] === null || !(intention?.[authorityType]?.authorityName)) {
                return;
            }  
            setSearchRequest(prev => ({...prev, title: intention?.[authorityType]?.authorityName ?? '', typeTitle: dynamicKey || BUILDING}));
        }

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    } else {
        console.log('NON EXISTENT ID');
    }
};

const normalizeText = (input) => {
    return (input ?? '').trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const checkCsvHeaders = ( // : bool | void
    inputArr,
    ref,
    setMissingFields
)  => {
    if (ref === undefined) {
        console.log('invalid');
        return;
    }

    const normalizedInputArr = inputArr.map(normalizeText);
    const normalizedRef = ref.map(normalizeText);

    if (normalizedRef.every((el, idx) => el === normalizedInputArr[idx])) {
        return true;
    }

    const errors = normalizedRef.filter((el, idx) => el !== normalizedInputArr[idx]);

    setMissingFields(prev => [...prev, ...(errors || [])]);
};

const checkCsvErrors = (resultsArray, setIsEmpty, setIsWrongFormat) => {    
    if (!Array.isArray(resultsArray) || resultsArray?.length <= 1) {
        setIsEmpty(true);
        return true;
    }

    try {
        const headers = resultsArray[0];
        const anoNeHeadersStartIndex = headers.findIndex(el => /\(ano\/ne\)/.test(el?.trim()?.toLowerCase()));
    
        if (anoNeHeadersStartIndex > -1) {
            for (let i = 1; i < resultsArray.length; i++) {
                if (Array.isArray(resultsArray[i])) {
                    for (let k = 0; k < resultsArray[i].length; k++) {
                        if (/\(ano\/ne\)/.test(headers[k]?.trim()?.toLowerCase())) {
                            const value = resultsArray[i][k]?.trim().toLowerCase();
                            if (value !== 'ano' && value !== 'ne') {
                                setIsWrongFormat(true);
                                return true;
                            }       
                        }
                    }
                }
            }
        }
    
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const shouldNotSetAuthority = (authority) => {
    if (!authority || !(authority instanceof Object)) {
        return true;
    }

    return Boolean(authority?.authorityName?.trim() === '' || Object.values(authority).every(val => !val));
};

const useOnlyRequiredKeys = (
    struc, // obj
    src, // obj
) => {
    try {
        if (struc) {
            const filteredObject = {};  
            Object.keys(struc).forEach(key => {
                if (key in src) {
                    if (src[key] && typeof struc[key] === 'object' && !Array.isArray(struc[key])) {
                    // make recursion in case it's nested
                        filteredObject[key] = useOnlyRequiredKeys(struc[key], src[key]);
                    } else if (src[key] !== null) {
                        filteredObject[key] = src[key];
                    }
                }
            });
            
            return filteredObject;
        }
    } catch (error) {
        console.log('error: ', error);
        return src;
    }
};

const enterCsvData = ( // : void
    setTableRequest, // fn
    newLines, // arr
    dynamicKey, //str
) => {
    setTableRequest(prev => ({ ...prev, [dynamicKey]: [...newLines]
        .map(el => {
            if (Object.hasOwn(el, 'uid')) {
                // eslint-disable-next-line no-unused-vars
                const {uid, ...others} = el;
                return others;
            } else {
                return el;
            }
        })
    }));
};

const removeCsvData = (
    setTableRequest, // fn
    newData, // arr
    dynamicKey, //str
) => {
    setTableRequest(prev => ({
        ...prev,
        [dynamicKey]: newData.map(el => {
            if (Object.hasOwn(el, 'uid')) {
                // eslint-disable-next-line no-unused-vars
                const {uid, ...others} = el;
                return others;
            } else {
                return el;
            }
        })
    }));
};

const shouldNotSaveTable = ( // : bool
    tableRequest // obj
) => {
    if ([...(Object.values(tableRequest || {}) || [])].filter(el => Array.isArray(el)).length === 0) {
        return true;
    }

    return false;
};

const handleAddManuallyIntoTables = ( // void
    setTableRequest, // fn
    listData, // arr
    request, // obj
    dynamicKey, //str|
    idToUpdate = '',
) => {
    if (idToUpdate) {
        setTableRequest(prev => ({ ...prev, [dynamicKey]: [...listData]
            .map(el => {
                if (el.uid === idToUpdate) {
                    return {...el, ...(request || {})};
                }
                return el;
            })
            .map(el => {
                if (el instanceof Object && Object.hasOwn(el, 'uid')) {
                // eslint-disable-next-line no-unused-vars
                    const {uid, ...others} = el;
                    return others;
                } else {
                    return el;
                }
            })}
        ));

        return;
    }

    setTableRequest(prev => ({ ...prev, [dynamicKey]: [...listData, request]
        .map(el => {
            if (Object.hasOwn(el, 'uid')) {
                // eslint-disable-next-line no-unused-vars
                const {uid, ...others} = el;
                return others;
            } else {
                return el;
            }
        })}
    ));
};

const getLinkforForm18 = ( // : ''|str
    id, urlPath, receivedToken, authConfig
) => {
    if (!authConfig) {
        return '';
    }

    const regex = /build-application-([0-9]+)|(documentation)/;
    const matchedPattern = urlPath.match(regex);
    const formType = matchedPattern?.[1] ?? matchedPattern?.[2] ?? '';

    if (!formType) {
        return '';
    }

    return`${authConfig.redirectUri}zadost/form18/${id}/${formType}/${receivedToken}`;
};

const setApplicantsFile = (applicants, setAttachments) => {
    if (!applicants) {
        return;
    }

    if (applicants?.length > 0) {
        applicants.forEach(applicant => {
            if (applicant.powerOfAttorneyFile instanceof Object && applicant.powerOfAttorneyFile?.fileName !== null) {
                spreadUploadedAttachments(applicant.powerOfAttorneyFile, setAttachments);     
            }

            if (applicant.powerOfAttorneyRepresentative instanceof Object && applicant.powerOfAttorneyRepresentative?.fileName !== null) {
                spreadUploadedAttachments(applicant.powerOfAttorneyRepresentative, setAttachments);
            }
        });
    }
};

const setAttorneyFile = (attorney, setAttachments) => {
    if (!attorney) {
        return;
    }

    if (attorney?.powerOfAttorneyFile instanceof Object && attorney.powerOfAttorneyFile?.fileName !== null) {
        spreadUploadedAttachments(attorney.powerOfAttorneyFile, setAttachments);     
    }

    if (attorney?.powerOfAttorneyRepresentative instanceof Object && attorney.powerOfAttorneyRepresentative?.fileName !== null) {
        spreadUploadedAttachments(attorney.powerOfAttorneyRepresentative, setAttachments);
    }
};

const setApplicantRepreFile = (applicant, setAttachments) => {
    if (!applicant) {
        return;
    }
    if (applicant?.powerOfAttorneyRepresentative instanceof Object && applicant.powerOfAttorneyRepresentative?.fileName !== null) {
        spreadUploadedAttachments(applicant.powerOfAttorneyRepresentative, setAttachments);
    }
};

const setAccomanyingDoc = (accompanyingDocumentStatus) => {
    return accompanyingDocumentStatus === 'NONE' ? false 
        : accompanyingDocumentStatus === 'IN_PROGRESS' ? null 
            : accompanyingDocumentStatus === 'CREATED' ? true : undefined;
};

const isExceeding = ( // : bool
    intention, // str
    val, // num
    forWarning = undefined, // str|undefined
) => {
    if (!intention || !val) {
        return false;
    }

    switch (intention) {
    case FORM_10_INTENTION.title:
    case FORM_14_INTENTION.title:
        return val > (forWarning === undefined ? 7 : 6);
    case FORM_11_INTENTION.title:
    case FORM_12_INTENTION.title:
    case FORM_13_INTENTION.title:
        return val > (forWarning === undefined ? 5 : 4);
    default:
        return false;
    }

};

const spreadTokenData = ( // :obj
    token, // obj
    state, // obj
    key, // str
    parentKey = 'form', // str
) => {
    return { 
        names: token?.firstName ?? '',
        lastName: token?.lastName ?? '',
        lastNameOrigin: token?.lastNameOrigin ?? '',
        dateOfBirth: token?.dateOfBirth ?? '',
        nationality: token?.nationality ?? 'CZE',
        address: {
            ...(state?.[parentKey]?.[key]?.address || {}),
            city: token?.addrCity ?? '',
            cityPart: token?.addrCityPart ?? '',
            street: token?.addrStreet ?? '',
            descNum: token?.addrDescNum ?? '',
            orientNum: token?.addrOrientNum ?? '',
            zip: token?.addrZipCode ?? '',
        },
    };
};

const resetForm = (val, setRequest, setCurrentApplicant, setApplicantArr, decodedToken = null, parentKey = 'form') => {
    setCurrentApplicant(APPLICANT_MODEL);
    setApplicantArr([]);
    setRequest(state => ({...state, [parentKey]: {
        ...state?.[parentKey], 
        applicantMe: val === 'me',
        applicantAttorney: val === 'someone',
        applicantMore: val === 'more',
        applicant: {...state?.[parentKey]?.applicant, ...APPLICANT_MODEL, id: state?.[parentKey]?.applicant?.id ?? null,
            ...((val === 'me' && decodedToken !== null)
                ? spreadTokenData(decodedToken, state, 'applicant', parentKey) 
                : {})
        },
        powerOfAttorney: null,
        attorney: {...state?.[parentKey]?.attorney, ...APPLICANT_MODEL, id: state?.[parentKey]?.attorney?.id ?? null,
            ...((val === 'someone' && decodedToken !== null)
                ? spreadTokenData(decodedToken, state, 'attorney', parentKey) 
                : {})
        },
        applicants: []
    }}));  
};

const resetApplicant = (applicantType, setRequest, decodedToken = null, parentKey = 'form') => {
    setRequest(state => ({ ...state, [parentKey]: {
        ...state?.[parentKey],
        applicant: {
            ...APPLICANT_MODEL,
            ...((applicantType === APPLICANT_TYPE_FO && decodedToken && state?.[parentKey]?.applicantMe)
                ? spreadTokenData(decodedToken, state, 'applicant', parentKey) 
                : {}),
            isFO: applicantType === APPLICANT_TYPE_FO,
            isFOBusiness: applicantType === APPLICANT_TYPE_FOP,
            isPO: applicantType === APPLICANT_TYPE_PO,
            id: state?.[parentKey]?.applicant?.id ?? null
        },
        attorney: { ...state?.[parentKey]?.attorney, idPowerOfAttorneyExisting: null, powerOfAttorneyExists: false, powerOfAttorneyFile: POWER_OF_ATTORNEY_FILE_MODEL }
    } }));
};

const resetAttorney = (attorneyType, setRequest, decodedToken = null, parentKey = 'form') => {
    setRequest(state => ({ ...state, [parentKey]: {
        ...state?.[parentKey],
        attorney: {
            ...APPLICANT_MODEL,
            ...((attorneyType === ATTORNEY_TYPE_FO && decodedToken !== null)
                ? spreadTokenData(decodedToken, state, 'attorney', parentKey) 
                : {}),
            ...(state?.[parentKey]?.attorney?.powerOfAttorneyFile?.fileName && { 
                powerOfAttorneyFile: state?.[parentKey]?.attorney?.powerOfAttorneyFile 
            }),
            ...(state?.[parentKey]?.attorney?.idPowerOfAttorneyExisting && { 
                powerOfAttorneyExists: state?.[parentKey]?.attorney?.powerOfAttorneyExists,
                idPowerOfAttorneyExisting: state?.[parentKey]?.attorney?.idPowerOfAttorneyExisting 
            }),
            ...(state?.[parentKey]?.attorney?.powerOfAttorneyRepresentative?.fileName && { 
                powerOfAttorneyRepresentative: state?.[parentKey]?.attorney?.powerOfAttorneyRepresentative 
            }),
            ...(state?.[parentKey]?.attorney?.idPowerOfRepresentativeExisting && { 
                powerOfRepresentativeExists: state?.[parentKey]?.attorney?.powerOfRepresentativeExists,
                idPowerOfRepresentativeExisting: state?.[parentKey]?.attorney?.idPowerOfRepresentativeExisting
            }),
            isFO: attorneyType === ATTORNEY_TYPE_FO,
            isFOBusiness: attorneyType === ATTORNEY_TYPE_FOP,
            isPO: attorneyType === ATTORNEY_TYPE_PO,
            id: state?.[parentKey]?.attorney?.id ?? null
        } } }));
};

const hasPowerOfAttorneyError = ( // :bool
    request, // obj
    zadatelAttachments, // arr
    isForRepresentative = undefined // str|undefined
) => {
    try {
        if (!isForRepresentative) {
            return (
                (request.form?.applicantAttorney && request.form?.attorney?.powerOfAttorneyExists && request.form?.attorney?.idPowerOfAttorneyExisting?.trim() === '') ||
                (request.form?.applicantAttorney && !request.form?.attorney?.powerOfAttorneyExists && zadatelAttachments[PLNA_MOC_FIRST].length === 0) ||
                (request.form?.applicantMore && request.form?.applicants?.length === 0)
            );
        } else if (isForRepresentative === 'applicant') {
            return (
                ((request.form?.applicant?.isFOBusiness && request.form?.applicant?.includeRepresentative) || (request.form?.applicant?.isPO)) &&
                (
                    // (!request.form?.applicant?.powerOfRepresentativeExists && zadatelAttachments[PLNA_MOC_THIRD].length === 0) ||
                    (request.form?.applicant?.powerOfRepresentativeExists && request.form?.applicant?.idPowerOfRepresentativeExisting?.trim() === '')
                )
            );
        } else if (isForRepresentative === 'representative') {
            return (
                ((request.form?.attorney?.isFOBusiness && request.form?.attorney?.includeRepresentative) || (request.form?.attorney?.isPO)) &&
                (
                    // (!request.form?.attorney?.powerOfRepresentativeExists && zadatelAttachments[PLNA_MOC_FOURTH].length === 0) ||
                    (request.form?.attorney?.powerOfRepresentativeExists && request.form?.attorney?.idPowerOfRepresentativeExisting?.trim() === '')
                )
            );
        } else {
            console.log('invalid request');
        }
    } catch (error) {
        console.log(error);
    }
};

const checkLimitAndInsertText = (input, countIndex, count, setCount) => {
    if (input.value.length > count[countIndex].limit) {
        input.value = input.value.substr(0, count[countIndex].limit);
    }
    setCount(prev => ({ ...prev, [countIndex]: { ...prev[countIndex], count: input.value.length } })); 
};

const shouldNotUpdateExceptions = (checkbox) => {
    return (checkbox.first === null && checkbox.second === null) || (checkbox.first === '' && checkbox.second === '');
};

const filterPruvodniListDocuments = (arr) => { // : File[]
    return arr.filter((file, index, self) => {
        const latestXmlIndex = self.findLastIndex(el => extractExtensionName(el.fileName) === 'xml');
        const latestPruvodniListIndex = self.findLastIndex(el => extractExtensionName(el.fileName) === 'pdf' && el.subSection === 'cover_letter_pdf');
        const isXml = extractExtensionName(file.fileName) === 'xml';
        const isPruvodniList = extractExtensionName(file.fileName) === 'pdf' && file.subSection === 'cover_letter_pdf';
        if (file.subSection === 'accomp_constructions_ext') {
            return true;
        } else if (isXml) {
            return index === latestXmlIndex;
        } else if (isPruvodniList) {
            return index === latestPruvodniListIndex;
        }
        return true;
    });
};

const filterPruvodniListToDownload = (arr) => { // : File[]
    return arr.filter((_file, index, self) => {
        const latestPruvodniListIndex = self.findLastIndex(el => extractExtensionName(el.fileName) === 'pdf' && el.subSection === 'cover_letter_pdf');
        return index === latestPruvodniListIndex;
    });
};

const canDisableExceptions = ( // : bool
    request, // obj
    propState, // str
    propertyToUpdate, // str
) => {
    const exceptions = ['names', 'lastName', 'dateOfBirth'];
    const isDisabled = Boolean((exceptions.indexOf(propState) > -1) && 
    ((request?.form?.applicantMe && request?.form?.applicant?.isFO && propertyToUpdate === 'applicant' && request?.form?.applicant?.[propState]) || 
        (request?.form?.applicantAttorney && request?.form?.attorney?.isFO && propertyToUpdate === 'attorney' && request?.form?.attorney?.[propState])));

    return isDisabled;
};

const resetZamerValuesManually = (
    setState,
    value,
    setRequest,
    buildIntentionModel,
    isSwitchingBackToNewFromExisting = false,
    setParcelAndConstructionData = null,
    setNewBuilding = null
) => {
    setState((prevState) => ({
        ...prevState,
        zamerType: value,
        inputValue: prevState.inputValue || '',
    }));

    setRequest((prev) => ({
        ...prev,
        buildIntention: {...buildIntentionModel,
            ...(isSwitchingBackToNewFromExisting && {
                'documentUploadHere': true,
                'documentPaperForm': null,
                'documentPreviousVersion' : null}),
            title: { ...prev.buildIntention.title, value: '', dirty: false }},
        ...(isSwitchingBackToNewFromExisting && {
            form: {
                id: prev?.form?.id ?? null,
                formType: prev?.form?.id ?? '', 
                applicantMe: true,
                applicantAttorney: false,
                applicantMore: false,
                applicant: APPLICANT_MODEL,
                powerOfAttorney: null,
                attorney: APPLICANT_MODEL,
                applicants: [],
                processPermitBuild: true,
                processPermitBuildFast: false,
                applicationExceptionRequirementBuild: null,
                applicationAlterPermitPublicBuild: null,
                applicationExceptionProvisions: '',
                applicationExceptionDescription: '',
                applicationExceptionReason: '',
                permissions: [],
                recipientAuthority: AUTHORITY_MODEL,
                permissionAuthority: AUTHORITY_MODEL,
                testOperationAuthority: AUTHORITY_MODEL,
                ...(INITIAL_OBJ || {}),
                thingName: '',
                applicationSupplement: '',
            },
            affectedBuildConstructions: [],
            affectedBuildParcels: [],
            buildConstructions: [],
            buildParcels: [],
            'buildApplication': {
                'documentations': [],
                'attachments': [],
                shareForm: {
                    firstName: '',
                    lastName: '',
                    paperType: 'ID',
                    paperNumber: ''
                },
                authorizedPersonSharedLink: '',
            },
        }),

    }));

    if (isSwitchingBackToNewFromExisting && setParcelAndConstructionData) {
        setParcelAndConstructionData(prev => {
            if (typeof prev !== 'object') {
                return prev;
            }

            const resetObject = {};
            for (let key in prev) {
                if (Object.hasOwn(prev, String(key))) {
                    resetObject[key] = [];
                }
            }
            
            return resetObject;
        });
    }

    if (isSwitchingBackToNewFromExisting && setNewBuilding) {
        setNewBuilding({
            isChecked: false,
            select: '',
        });
    }
};

const resetIntentionWithZamerType = async (
    value,
    setState,
    setRequest,
    buildIntentionModel,
    prevZamerType = '',
    id = null,
    token = null,
    setParcelAndConstructionData = null,
    setNewBuilding = null,
) => {
    if (value === 'new' && prevZamerType === 'existing' && id) {
        let removedZamer = null;
        if (token) removedZamer = await deleteExistingZamer(id, token);
        if (removedZamer) resetZamerValuesManually(setState, value, setRequest, buildIntentionModel, true, setParcelAndConstructionData, setNewBuilding);
        return;
    }

    resetZamerValuesManually(setState, value, setRequest, buildIntentionModel, false, null, null);
};

const getFilterBodyBySections = (type = '') => { // :obj
    return {
        ...POST_BODY_FOR_FILTER,
        ...((type === RIZENI || type === ZADOSTI || type === DOKUMENTACE) ?
            { sorts: [
                {
                    field: type === RIZENI ? 'date' : type === ZADOSTI ? 'requestDate' : type ===  DOKUMENTACE ? 'finishDate' : '',
                    asc: false
                }
            ] } : {}),
    };
};

// filters
const handleChangeFilterBody = (key, newObject, forRizeniOrZameryOrDokumentace = '', setPostBody) => {
    if (key === 'sorts' && (forRizeniOrZameryOrDokumentace === RIZENI || forRizeniOrZameryOrDokumentace === ZADOSTI || forRizeniOrZameryOrDokumentace === DOKUMENTACE)) {
        setPostBody(prev => ({
            ...prev,
            [key]: [newObject],
        }));
        return;
    }

    if (typeof newObject.value === 'string' && newObject.value.trim() === '') {
        setPostBody(prev => ({...prev, filters: [...prev.filters].filter(el => el.field !== newObject.field)}));
        return;
    } else if (newObject.value instanceof Array && newObject.value[0].value.match(/^%%\s*%%$/)) {
        setPostBody(prev => ({...prev, filters: [...prev.filters].filter(el => el.operation !== 'OR')}));
        return;
    }

    setPostBody(prev => ({
        ...prev,
        [key]: [...new Map([...prev[key], newObject].map(el => [el.field, el])).values()],
    }));
};

const handleFilterChange = ({ value }, type = '', field = '', isWithFolding, setPostBody) => {
    switch (type) {
    case 'sort/zadosti/date': {
        const isAscOrDesc = value === 'asc' || value === 'desc';
        const sortObject = {
            field: 'requestDate',
            asc: isAscOrDesc ? value === 'asc' : '',
        };
        handleChangeFilterBody('sorts', sortObject, ZADOSTI, setPostBody);
        break;
    }
    case 'sort/rizeni': {
        const sortObject = {
            field: value,
            asc: value !== 'date',
        };
        handleChangeFilterBody('sorts', sortObject, RIZENI, setPostBody);
        break;
    }
    case 'sort/dokumentace': {
        const sortObject = {
            field: value,
            asc: value !== 'date',
        };
        handleChangeFilterBody('sorts', sortObject, DOKUMENTACE, setPostBody);
        break;
    }
    case 'filter':
    case 'input': {
        let filterObject = {};
        if (type === 'filter') {
            filterObject = {
                ...filterObject,
                ...({field} || {}),
                operation: 'EQ',
                value,
                ...(isWithFolding && { folding: true })
            };
        } else if (type === 'input') {
            filterObject = {
                ...filterObject,
                value: [
                    {
                        field: 'projectName',
                        operation: 'LIKE',
                        value: `%%${value}%%`,
                        ignoreAccent: null,
                        ignoreCase: null
                    },
                    {
                        field: 'projectId',
                        operation: 'LIKE',
                        value: `%%${value}%%`,
                        ignoreAccent: null,
                        ignoreCase: null
                    },
                    {
                        field: 'projectNumber',
                        operation: 'LIKE',
                        value: `%%${value}%%`,
                        ignoreAccent: null,
                        ignoreCase: null
                    }
                ],
                'operation': 'OR'
            };
        } else {
            console.log('invalid operation');
        }

        handleChangeFilterBody('filters', filterObject, '', setPostBody);
        break;
    }
    default:
        break;
    }};

const appendNumber = (fileName, all) => {
    const name = extractFileName(fileName, true);
    const extensionName = extractExtensionName(fileName);
    
    const existingFiles = all.filter(doc => 
        doc.fileName.startsWith(name) && doc.fileName.endsWith(extensionName)
    );
    
    let maxNumber = 0;
    existingFiles.forEach(doc => {
        const match = doc.fileName.match(/_(\d+)\./);
        if (match) {
            const number = parseInt(match[1], 10);
            if (number > maxNumber) {
                maxNumber = number;
            }
        }
    });
    
    const nextNumber = maxNumber + 1;
    return `${name}_${nextNumber}.${extensionName}`;
};
    
const alterFileNames = (fileName, all = []) => {
    const fileExists = all.some(doc => doc.fileName === fileName);
    
    if (fileExists) {
        return appendNumber(fileName, all);
    } else {
        return fileName;
    }
};

const uploadPoaFile = async (file, section, token, allFiles = []) => {
    try {
        const extractedFileName = extractFileName(file.name);
        const size = file.size;
        const { fileName, uid, url, isDeletable } = await getUrl(token);
        const name = fileName || extractedFileName;
        const fileToUpload = {
            uid,
            fileName: alterFileNames(name, allFiles),
            url,
            section,
            size,
            blobFile: file,
            message: file.message ?? undefined,
            isDeletable,
            abortController: getNewAbortController(),
        };
        return fileToUpload;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const handlePOAFiles = async (files, category, token, setZadatelAttachments, currentApplicant) => {
    function updateAttachmentsFirst (fileToUpload) {
        setZadatelAttachments((prev) => ({
            ...prev,
            [PLNA_MOC_FIRST]: [fileToUpload]
        }));
    }

    function updateAttachmentsSecond (fileToUpload) {
        if (currentApplicant?.id) {
            fileToUpload.personId = currentApplicant.id;
        }
        setZadatelAttachments((prev) => ({
            ...prev,
            [PLNA_MOC_SECOND]: [...new Map([...prev[PLNA_MOC_SECOND], fileToUpload]
                .map(el => [el.personId, el])).values()]
        }));
    }

    function updateAttachmentsThird (fileToUpload) {
        setZadatelAttachments((prev) => ({
            ...prev,
            [PLNA_MOC_THIRD]: [fileToUpload]
        }));
    }

    function updateAttachmentsFourth (fileToUpload) {
        setZadatelAttachments((prev) => ({
            ...prev,
            [PLNA_MOC_FOURTH]: [fileToUpload]
        }));
    }

    function updateAttachmentsFifth (fileToUpload) {
        if (currentApplicant?.id) {
            fileToUpload.personId = currentApplicant.id;
        }
        setZadatelAttachments((prev) => ({
            ...prev,
            [PLNA_MOC_FIFTH]: [...new Map([...prev[PLNA_MOC_FIFTH], fileToUpload]
                .map(el => [el.personId, el])).values()]
        }));
    }

    const functionsPerCategory = {
        [PLNA_MOC_FIRST]: updateAttachmentsFirst,
        [PLNA_MOC_SECOND]: updateAttachmentsSecond,
        [PLNA_MOC_THIRD]: updateAttachmentsThird,
        [PLNA_MOC_FOURTH]: updateAttachmentsFourth,
        [PLNA_MOC_FIFTH]: updateAttachmentsFifth,
    };

    const updateFunction = functionsPerCategory[category];

    if (!updateFunction) {
        return;
    }

    for (const file of files) {
        const fileToUpload = await uploadPoaFile(file, category, token);
        if (fileToUpload) {
            updateFunction(fileToUpload);
        }
    }
};
const uploadFile = async (file, section, token, setItems, category, allFiles = []) => {
    const extractedFileName = extractFileName(file.name);
    const size = file.size;
    const { fileName, uid, url, isDeletable } = await getUrl(token);
    const name = fileName || extractedFileName;
    const fileToUpload = {
        uid,
        fileName: alterFileNames(name, allFiles),
        url,
        section,
        size,
        blobFile: file,
        message: file.message ?? undefined,
        isDeletable,
        abortController: getNewAbortController(),
    };
    if (category === 'documents') {
        spreadUploadedDocuments(fileToUpload, setItems, section, undefined);
    } else if (category === 'attachments') {
        spreadUploadedAttachments(fileToUpload, setItems, section);
    } else {
        console.log('invalid');
    }
};

const handleFiles = async (files, section, token, setItems, category, allFiles = []) => {
    for (const file of files) {
        await uploadFile(file, section, token, setItems, category ?? 'attachments', allFiles);
    }
};

const updateTableValues = (obj, ref) => {
    const objToUpdate = {};
    for (const key in ref) {
        if (obj[key] !== null && obj[key] !== undefined) {
            objToUpdate[key] = obj[key]; 
        }
    }
    return objToUpdate;
};

const hasZamerError = (buildIntention) => {
    return Boolean(!buildIntention?.title?.value?.trim() || buildIntention?.title?.value?.trim()?.length > 100);
};

const constructionOrParcelToRemoveNotAdded = (form, parcelAndConstructionData, type) => {
    if(type === 'construction') return Boolean((form?.removeBuilding || form?.removeFacility)
        && (!parcelAndConstructionData?.buildConstructions || parcelAndConstructionData.buildConstructions.length < 1));

    else return Boolean(form?.removeTerrain && (!parcelAndConstructionData?.buildParcels || parcelAndConstructionData.buildParcels.length < 1));
};

const getFormTargetNumberByTitle = (title, paths) => {
    const path = paths.find(item => item.title === title);
    if (path) {
        return path.target.replace('form', '');
    }
    return null;
};

const makeCopyRequest = async (id, token, paths) => {
    const source = axios.CancelToken.source();

    try {
        const response = await makeCopyZadostiById(id, token, source);
        const copyTitle = response.data?.data?.buildApplication?.title;
        const path = paths.find(pathItem => pathItem.title === copyTitle);
        const targetNumber = getFormTargetNumberByTitle(copyTitle, paths);

        console.log(response, copyTitle, path, targetNumber);

        if(!targetNumber) {
            // TODO error handling
            return null;
        }

        const urlPath = `quick-save-api/build-application-${targetNumber}/save`;
        const newFormData = JSON.stringify({
            'buildApplication': {
                'title': copyTitle
            }
        });
        const newFormResponse = await formSave(newFormData, token, urlPath, source);
        const applicationId = newFormResponse?.data;

        if(!path || !applicationId) {
            // TODO error handling
            return null;
        }

        const copyFormData = {
            ...response.data.data,
            buildApplication: {
                ...response.data.data.buildApplication,
                id: applicationId,
                isCopied: true,
            },
            buildIntention: {
                ...response.data.data.buildIntention,
                id: applicationId,
            },
            'applicationId': applicationId,
        };
        await formSave(copyFormData, token, urlPath, source);

        return { path, applicationId };
    } catch (e) {
        // TODO error handling
        console.log(e);
    }
};

const handleSpreadUploadedDocuments = (documents, loadedIntention, setDocuments, setUploadedBytes) => {
    if (!(documents instanceof Array) || !documents?.length) {
        return;
    }

    if (loadedIntention?.documentUploadHere ||
        (loadedIntention?.documentUploadHere === null && 
            loadedIntention?.documentPaperForm === null &&
                loadedIntention?.documentPreviousVersion === null)
    ) {
        spreadUploadedDocuments(documents, setDocuments, undefined, setUploadedBytes);
    } else if (loadedIntention?.documentPreviousVersion) {
        spreadUploadedDocuments(documents, setDocuments, undefined, setUploadedBytes, undefined, 'existing');
    } else if (loadedIntention?.documentPaperForm) {
        // enable showing pruvodni list in case paperform is selected
        spreadUploadedDocuments(documents.filter(el => el.section === DOCUMENT_FIRST && el.subSection), setDocuments, undefined, setUploadedBytes);
    }
};

const canReupload = (
    errorItem,
    size,
    uploadedBytes,
    setUploadError,
    uid,
    setUploads,
    category,
    allUploads,
) => {
    const limit = TOTAL_UPLOAD_LIMIT;
    const ongoingUploads = getOngoingUploads(allUploads);
    const ongoingUploadsSize = getTotalValueFromUploads(ongoingUploads, 'size');
    const reuploadSize = Number(size) + Number(uploadedBytes) + ongoingUploadsSize;
    if (errorItem?.why === 'total_size' && (reuploadSize > limit)) {
        return false;
    } else if (errorItem?.why && errorItem?.why !== 'total_size' && errorItem?.why !== 'single_upload_error') {
        return false;
    } else if ((errorItem?.why === 'total_size' || errorItem?.why === 'single_upload_error') && (reuploadSize <= limit)) {
        if (errorItem?.why === 'total_size') setUploadError(prev => [...prev].filter(el => el.uid !== uid));
        setUploads((prev) => ({
            ...prev,    
            [category]: [...prev[category]].map(el => {
                if (el.uid === uid && el.message !== undefined) {
                    return {...el, message: undefined};       
                } else {
                    return el;
                }
            })
        }));
        return true;
    }
};

const setFiltersForZadosti = ( // :void
    firstRender, // bool
    isReset, // bool
    setStartIndexOdeslane, // fn
    list, // arr
    postBody, // obj
    setFilteredList // fn 
) => {
    try {
        if (!firstRender && isReset) {
            setStartIndexOdeslane(0);
            return;
        }
    
        if (isReset || firstRender || !list.length) {
            return;
        }
    
        let unfiltered = [...list];
        const sortItem = postBody.sorts?.[0]?.field ?? ''; // str
        const isAscending = postBody.sorts?.[0]?.asc ?? false; // bool
        const statusFilter = postBody.filters.find(el => el.field === 'status')?.value ?? ''; // str
        const typeFilter = postBody.filters.find(el => el.field === 'requestType')?.value ?? ''; // str
        const inputFilter = postBody.filters.find(el => el.operation === 'OR')?.value?.[0]?.value.match(/^%%(.+)%%$/)?.[1]?.trim() ?? ''; // str
        
        unfiltered = unfiltered
            .sort((a, b) => a?.[sortItem] < b?.[sortItem] ? (isAscending ? -1 : 1) : (isAscending ? 1 : -1))
            .filter((el) => statusFilter === 'ACTIVE' ? el.status === 'nevyrizen' : statusFilter === 'COMPLETED' ? el.status === 'vyrizen' : true)
            .filter((el) => !typeFilter ? true : el.requestType === typeFilter)
            .filter((el) => !inputFilter ? true : (el.projectName?.toLowerCase().includes(inputFilter?.toLowerCase()) || el.requestType?.toLowerCase().includes(inputFilter?.toLowerCase())) || el.projectId?.toLowerCase().includes(inputFilter?.toLowerCase()));
        
        setStartIndexOdeslane(0);
        setFilteredList([...unfiltered]);
    } catch (error) {
        console.log(error);
    }
};

const setFiltersForDocumentations = ( // :void
    firstRender, // bool
    isReset, // bool
    setStartIndex, // fn
    list, // arr
    postBody, // obj
    setFilteredList // fn
) => {
    try {
        if (!firstRender && isReset) {
            setStartIndex(0);
            return;
        }

        if (isReset || firstRender || !list.length) {
            return;
        }

        let unfiltered = [...list];
        const sortItem = postBody.sorts?.[0]?.field ?? ''; // str
        const isAscending = postBody.sorts?.[0]?.asc ?? false; // bool
        const typeFilter = postBody.filters.find(el => el.field === 'date')?.value ?? ''; // str
        const inputFilter = postBody.filters.find(el => el.operation === 'OR')?.value?.[0]?.value.match(/^%%(.+)%%$/)?.[1]?.trim() ?? ''; // str

        unfiltered = unfiltered
            .sort((a, b) => a?.[sortItem] < b?.[sortItem] ? (isAscending ? -1 : 1) : (isAscending ? 1 : -1))
            .filter((el) => !typeFilter ? true : el.requestType === typeFilter)
            .filter((el) => !inputFilter ? true : (el.projectName?.toLowerCase().includes(inputFilter?.toLowerCase())) || el.projectId?.toLowerCase().includes(inputFilter?.toLowerCase()));

        setStartIndex(0);
        setFilteredList([...unfiltered]);
    } catch (error) {
        console.log(error);
    }
};

const checkAnoNeFields = (data, sliceIndex) => {
    function slicedArr(arr) {
        if (!arr.length) return [];

        if (sliceIndex === -1) {
            return arr.slice(-1);
        }

        return arr.slice(sliceIndex, sliceIndex + 1);
    }

    return [...data]?.slice(1).some(arr => 
        slicedArr(arr)?.some(el => 
            el?.trim().toLowerCase() !== 'ano' && el?.trim().toLowerCase() !== 'ne'
        )
    );
};

const getAnoNeValue = (line) => {
    return line?.trim()?.toLowerCase() === 'ano' ? true : line?.trim()?.toLowerCase() === 'ne' ? false : null;
};

function extractYear(projectId = '') {
    const match = projectId.match(/Z\/(\d{4})\//);
    return match ? parseInt(match[1], 10) : null;
}

function extractNumber(projectId = '') {
    const match = projectId.match(/Z\/\d{4}\/(\d+)/);
    return match ? parseInt(match[1], 10) : null;
}

function intentionsSort(a, b) {
    const yearA = extractYear(a?.projectId);
    const yearB = extractYear(b?.projectId);

    if (yearA === yearB) {
        const numA = extractNumber(a?.projectId);
        const numB = extractNumber(b?.projectId);
        return numB - numA;
    }

    return yearB - yearA;
}

const handleZadatelPeopleFiles = ( // :void
    loadedData, // obj
    setZadatelAttachments, // func
    setApplicantArr, // func
) => {
    // handle attorney files
    const attorneyRequest = loadedData?.attorney;
    if (attorneyRequest) {                   
        if (attorneyRequest.powerOfAttorneyFile instanceof Object && attorneyRequest.powerOfAttorneyFile?.fileName) { 
            setZadatelAttachments((prev) => ({
                ...prev,
                [PLNA_MOC_FIRST]: [...prev[PLNA_MOC_FIRST], {...attorneyRequest.powerOfAttorneyFile, flagged: true}]
            }));
        }
        if ((attorneyRequest?.includeRepresentative || attorneyRequest?.isPO) &&
            (attorneyRequest.powerOfAttorneyRepresentative instanceof Object && 
             attorneyRequest.powerOfAttorneyRepresentative?.fileName)) {
            setZadatelAttachments((prev) => ({
                ...prev,
                [PLNA_MOC_FOURTH]: [...prev[PLNA_MOC_FOURTH], {...attorneyRequest.powerOfAttorneyRepresentative, flagged: true}]
            }));
        }
    }
    // handle applicant files
    const applicantRequest = loadedData?.applicant;
    if (applicantRequest) {
        if ((applicantRequest?.isPO || applicantRequest?.includeRepresentative) &&
            (applicantRequest?.powerOfAttorneyRepresentative instanceof Object &&
             applicantRequest?.powerOfAttorneyRepresentative?.fileName)) {
            setZadatelAttachments((prev) => ({
                ...prev,
                [PLNA_MOC_THIRD]:[...prev[PLNA_MOC_THIRD], {...applicantRequest.powerOfAttorneyRepresentative, flagged: true}],
            }));
        }
    }
    // handle multiple applicants files
    const appArr = loadedData?.applicants;
    if (appArr instanceof Array && appArr.length > 0) {
        // API returns associated attachment with personID null always which is wrong. Writing it below to work around.
        const applicantArrWithMappedPersonId = appArr.map(applicant => {
            if (Object.hasOwn(applicant, 'powerOfAttorneyFile') && 
                (applicant.powerOfAttorneyFile instanceof Object &&
                applicant.powerOfAttorneyFile?.fileName)) {
                applicant.powerOfAttorneyFile.personId = applicant.id;
            }
            if (Object.hasOwn(applicant, 'powerOfAttorneyRepresentative') && 
                (applicant.powerOfAttorneyRepresentative instanceof Object &&
                applicant.powerOfAttorneyRepresentative?.fileName)) {
                applicant.powerOfAttorneyRepresentative.personId = applicant.id;
            }
            return applicant;
        });
                    
        setApplicantArr(applicantArrWithMappedPersonId);

        applicantArrWithMappedPersonId.forEach(applicant => {
            if (applicant.powerOfAttorneyFile instanceof Object &&
                applicant.powerOfAttorneyFile?.fileName &&
                applicant.powerOfAttorneyFile?.personId) {
                setZadatelAttachments((prev) => ({
                    ...prev,
                    [PLNA_MOC_SECOND]: [...prev[PLNA_MOC_SECOND], {...applicant.powerOfAttorneyFile, flagged: true}]
                }));
            }
            if (applicant.powerOfAttorneyRepresentative instanceof Object &&
                applicant.powerOfAttorneyRepresentative?.fileName &&
                applicant.powerOfAttorneyRepresentative?.personId) {
                setZadatelAttachments((prev) => ({
                    ...prev,
                    [PLNA_MOC_FIFTH]: [...prev[PLNA_MOC_FIFTH], {...applicant.powerOfAttorneyRepresentative, flagged: true}]
                }));
            }
        });
    }
};

const canResetAttorney = (data) => { // :bool
    const attorney = data?.attorney;
    const {names, lastName} = attorney;
    
    return (!names?.trim() && !lastName?.trim());
};

const handlePeople = ( // :void
    loadedData, // obj
    setRequest, // func
    decodedToken, // obj
    parentKey = 'form' // str
) => {
    const allNull = (loadedData.applicantMe === null && loadedData.applicantAttorney === null);
    const shouldReloadToken = (loadedData.applicantAttorney && loadedData.attorney.isFO && canResetAttorney(loadedData));
    const key = allNull ? 'applicant' : shouldReloadToken ? 'attorney' : null;
                    
    setRequest((state) => ({
        ...state,
        [parentKey]: {
            ...state?.[parentKey],
            ...spreadObject(loadedData),
            applicantMe: loadedData.applicantMe ?? true,
            applicantAttorney: loadedData.applicantAttorney ?? false,
            applicantMore: loadedData.applicantMore ?? false,
            attorney: {...loadedData.attorney, nationality: loadedData?.attorney?.nationality ?? 'CZE'},
            ...(key !== null
                ? {
                    [key]: {
                        ...(state?.[parentKey]?.[key] || {}),
                        ...APPLICANT_MODEL,
                        names: decodedToken?.firstName ?? '',
                        lastName : decodedToken?.lastName ?? '',
                        lastNameOrigin: decodedToken?.lastNameOrigin ?? '',
                        dateOfBirth: decodedToken?.dateOfBirth ?? '',
                        nationality: decodedToken?.nationality ?? 'CZE',
                        address: {
                            ...state?.[parentKey]?.[key]?.address,
                            city: decodedToken?.addrCity ?? '',
                            cityPart: decodedToken?.addrCityPart ?? '',
                            street: decodedToken?.addrStreet ?? '',
                            descNum: decodedToken?.addrDescNum ?? '',
                            orientNum: decodedToken?.addrOrientNum ?? '',
                            zip: decodedToken?.addrZipCode ?? '',
                        },
                    },
                }
                : {}),
        },
    }));
};

const canInitiateUpload = (newAttachments, fileObj) => {
    return (Boolean(newAttachments.find(element => element.uid === fileObj.uid)) && !fileObj.flagged);
};

const handleSetUpload = (setUploads, category, uid) => {
    try {
        setUploads((prev) => ({
            ...prev,    
            [category]: [...prev[category]].map(el => {
                if (el.uid === uid) {
                    if (el.message === undefined) {
                        // eslint-disable-next-line no-unused-vars
                        const {blobFile, message, abortController, ...rest} = el;
                        return {...rest};
                    } else if (el.message) { 
                        return el;
                    }
                } else {
                    return el;
                }
            })
        }));
    } catch (error) {
        console.log(error);
    }
};

const handleDeleteUpload = (setUploads, category = undefined, id, forProgress = undefined) => {
    if (forProgress) {
        setUploads((prev) => [...prev].filter(el => el.uid !== id));
        return;
    }

    if (!category) {
        return;
    }

    setUploads((prev) => ({
        ...prev,    
        [category]: [...prev[category]].filter(el => el.uid !== id)
    }));
};

const sendDocumentationRequest = (id, documents) => {
    if (!id || !documents) {
        return null;
    }
    return {
        'applicationId': id,
        'buildApplication': {
            'documentations': [...new Map([...(documents || [])]
                .map(el => [el.uid, el])).values()]
                .map(el => {
                // eslint-disable-next-line no-unused-vars
                    const {blobFile, message, abortController, existing, flagged, ...rest} = el;
                    return {...rest};
                })
        },
    };
};

const sendAttachmentRequest = (id, attachments) => {
    if (!id || !attachments) {
        return null;
    }
    return {
        'applicationId': id,
        'buildApplication': {
            'attachments': [...new Map([...(attachments || [])]
                .map(el => [el.uid, el])).values()]
                .map(el => {
                // eslint-disable-next-line no-unused-vars
                    const {blobFile, message, abortController, existing, flagged, ...rest} = el;
                    return {...rest};
                })
        },
    };
};

const canAddThirdPartyApplicant = (currentApplicant, zadatelAttachments = []) => {
    const { 
        names, 
        lastName, 
        dateOfBirth,
        isFOBusiness,
        isPO,
        address: { zip, city }, 
        powerOfAttorneyFile: { fileName: attorneyFileName, uid: attorneyFileUid }, 
        includeRepresentative, 
        powerOfAttorneyRepresentative: { fileName: repreFileName, uid: repreFileUid } 
    } = currentApplicant || {};

    if (!names?.trim() || 
        !lastName?.trim() || 
        !dateOfBirth?.trim() ||
        !zip?.trim() ||
        !city?.trim()) {
        return false;
    }
    if ([...(zadatelAttachments || [])].find(el => ((el.uid === attorneyFileUid || el.uid === repreFileUid) && (el.progress >= 0 && el.progress < 100)))) {
        return false;
    }

    if ((isFOBusiness && includeRepresentative && !repreFileName) || 
        (isPO && !repreFileName) ||
        !attorneyFileName) {
        // setting it to true for now to show error messages on files
        return true;
    }

    return true;
};

const canShowRemainingUploadCapacity = (formNumber) => {
    const acceptedForms = [
        '02', '03', '04', '06', '08', '09', '11', '12', '14', '15', '16', '17', 'documentation'
    ];

    return acceptedForms.indexOf(String(formNumber)) > -1;
};

const logError = (error, info) => {
    // TODO check if any storage would be possible as we cannot see logs from production most likely
    if (error) {
        console.log(error);
    } else if (info) {
        console.log(JSON.stringify(info));
    }
};

const getCurrentDateAndTime = () => { // obj
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`;
    // set time to current time in forma hh:mm
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const formattedTime = `${hours}:${minutes}`;

    return {formattedDate, formattedTime};
};

const formatUrlWithToken = (id, filePid, token) => {
    return`${authConfig.redirectUri}dokumentace/${id}/${filePid}/${token}` ?? '';
};

const remapAbortControllerOnError = (setUploads, category, uid) => {
    setUploads((prev) => ({
        ...prev,    
        [category]: [...prev[category]].map(el => {
            if (el.uid === uid) {
                el.abortController = getNewAbortController();
                return el;
            }
            return el;
        })
    }));
};

const isPruvodniListNotYetSubmitted = (
    obj = {}, //: obj[request]
    overrideKey = '', // str
) => { // :bool
    try {
        const loadedBuildIntention = obj?.buildIntention || {};
        // edge case for F11,F12 start
        const deviationsCheck = overrideKey === 'deviationsCheck';
        if (deviationsCheck && !Object.values(loadedBuildIntention)?.length) {
            return false;
        }
        const notCarriedWithDeviations = loadedBuildIntention?.notCarriedWithDeviations;
        const carriedWithDeviations = loadedBuildIntention?.notCarriedWithDeviations;
        if (deviationsCheck && (notCarriedWithDeviations || (notCarriedWithDeviations === null && carriedWithDeviations === null))) {
            return false;
        }
        // edge case for F11,F12 end
        const loadedBuildApplication = obj?.buildApplication || {};
        const loadedAccompanyingDocument = obj?.accompanyingDocument || {};
    
        const documentPaperForm = loadedBuildIntention?.documentPaperForm;
        const accompanyingDocumentDeveloper = loadedBuildIntention?.accompanyingDocumentDeveloper;
        const accompanyingDocumentPdf = loadedBuildIntention?.accompanyingDocumentPdf;
        
        const documentations = loadedBuildApplication?.documentations ?? [];
        const authorizedPersonSharedLink = loadedBuildApplication?.authorizedPersonSharedLink ?? '';
        
        const accompanyingDocumentStatus = loadedAccompanyingDocument?.accompanyingDocumentStatus;

        const newLinkGenerated = overrideKey === 'authorizedPersonSharedLink';
        const documentUploadHereSelected = overrideKey === 'documentUploadHere';
        const documentPaperFormSelected = overrideKey === 'documentPaperForm';
        const accompanyingDocumentPdfSelected = overrideKey === 'accompanyingDocumentPdf';
        const accompanyingDocumentDeveloperSelected = overrideKey === 'accompanyingDocumentDeveloper'; 
        
        const pruvodniListSubmitted = (
            accompanyingDocumentStatus === 'CREATED' && 
            documentations?.some(el => el.section === DOCUMENT_FIRST && el.subSection === 'cover_letter_pdf')
        );

        const pruvodniListManuallySubmitted = documentations?.some(el => el.section === DOCUMENT_FIRST && el.subSection !== 'cover_letter_pdf');

        const isAccompanyingDocumentDevelopperSubmitted = (
            accompanyingDocumentDeveloper && 
            pruvodniListSubmitted
        );

        const isAccompanyingDocumentPdfSubmitted = (
            accompanyingDocumentPdf && pruvodniListManuallySubmitted
        );

        if (newLinkGenerated) {
            return !pruvodniListSubmitted;
        }

        if (documentUploadHereSelected) {
            return !(documentations?.some(el => el.section === DOCUMENT_FIRST));
        }
        
        if (documentPaperFormSelected) {
            if (authorizedPersonSharedLink) {
                return !pruvodniListSubmitted;
            }
            return false;
        }

        if (accompanyingDocumentPdfSelected) {
            return !pruvodniListManuallySubmitted;
        }

        if (accompanyingDocumentDeveloperSelected) {
            return authorizedPersonSharedLink;
        }

        if (documentPaperForm) {
            if (authorizedPersonSharedLink) {
                return !pruvodniListSubmitted;
            }
            return false;
        }

        return !(isAccompanyingDocumentDevelopperSubmitted || isAccompanyingDocumentPdfSubmitted);
    } catch (error) {
        console.log(error);
        return false;
    }
};

const areUserDetailsMatching = (decodedToken, buildIntention) => {
    // for now tolerate DOB if they are both empty
    function trimValue(str) {
        if (typeof str !== 'string') {
            return '';
        }
        return str.trim();
    }
    
    try {
        const { applicantMe, applicant: { isFO, names, lastName, dateOfBirth } } = buildIntention || {};
        const { firstName: decodedFirstName, lastName: decodedLastName, dateOfBirth: decodedDateOfBirth } = decodedToken || {};
        if (!applicantMe || !isFO || !decodedToken) {
            return true;
        }
    
        return (
            trimValue(names) === trimValue(decodedFirstName) && 
            trimValue(lastName) === trimValue(decodedLastName) && 
            trimValue(dateOfBirth) === trimValue(decodedDateOfBirth)
        );
    } catch (error) {
        console.log('log error', error);
        return true;
    }
};

export {
    isExceeding,
    convertBytes,
    shouldShowProgress,
    shouldNotFetch,
    getNewAbortController,
    cancelUpload,
    getUrl,
    extractFileName,
    extractExtensionName,
    filterFiles,
    spreadUploadedAttachments,
    spreadUploadedDocuments,
    setUploadedDocumentsSizeFromZamer,
    isNotUploading,
    shouldNotGoToLastPage,
    shouldNotGoToFirstPage,
    shouldNotGoToNextPage,
    shouldNotGoToPreviousPage,
    spreadUploadedModalDocuments,
    getFormType,
    spreadParcelsAndConstructions,
    findChosenIntention,
    shouldNotSave,
    appendNumberToGovLabel,
    updateNumberOnGovLabel,
    setCsvDataAndRequest,
    getPrijemceList,
    getBuildApplicationFormForPrijemce,
    canInitiateSaveForPrijemce,
    canFulFillPrijemceList,
    checkCsvHeaders,
    checkCsvErrors,
    isTrackableUpload,
    shouldNotSetAuthority,
    useOnlyRequiredKeys,
    spreadObject,
    enterCsvData,
    removeCsvData,
    shouldNotSaveTable,
    handleAddManuallyIntoTables,
    getLinkforForm18,
    setAttorneyFile,
    setApplicantRepreFile,
    setApplicantsFile,
    setAccomanyingDoc,
    resetApplicant,
    resetAttorney,
    resetForm,
    hasPowerOfAttorneyError,
    checkLimitAndInsertText,
    shouldNotUpdateExceptions,
    filterPruvodniListDocuments,
    filterPruvodniListToDownload,
    canDisableExceptions,
    resetIntentionWithZamerType,
    getFilterBodyBySections,
    handleFilterChange,
    handlePOAFiles,
    handleFiles,
    updateTableValues,
    hasZamerError,
    makeCopyRequest,
    handleSpreadUploadedDocuments,
    canReupload,
    setFiltersForZadosti,
    setFiltersForDocumentations,
    checkAnoNeFields,
    getAnoNeValue,
    intentionsSort,
    handleZadatelPeopleFiles,
    canResetAttorney,
    handlePeople,
    constructionOrParcelToRemoveNotAdded,
    canInitiateUpload,
    handleSetUpload,
    handleDeleteUpload,
    getTotalValueFromUploads,
    getTotalValue,
    sendDocumentationRequest,
    sendAttachmentRequest,
    canAddThirdPartyApplicant,
    canShowRemainingUploadCapacity,
    isDokumentace,
    logError,
    getOngoingUploads,
    getCurrentDateAndTime,
    formatUrlWithToken,
    remapAbortControllerOnError,
    isNotForDokumentace,
    isTrackableForPrilohy,
    isTrackableForDokumentace,
    isPrilohy,
    isPruvodniListNotYetSubmitted,
    areUserDetailsMatching,
};
