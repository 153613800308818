import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import '../../css/progress.css';
import { Container } from 'react-bootstrap';
import FormContext from '../../formContexts/FormContext';

const Progress = ({uid}) => {
    const {uploadProgressRef} = useContext(FormContext);
    const [smoothenProgress, setSmoothenProgress] = useState(0);
    
    useEffect(() => {
        const handleProgressUpdate = () => {
            const progress = uploadProgressRef?.current?.[uid] ?? 0;
            setSmoothenProgress(progress);
        };

        handleProgressUpdate();
        const interval = setInterval(handleProgressUpdate, 100);
        return () => clearInterval(interval);
    }, [uid, uploadProgressRef]);
    
    return (
        <>
            {(Number(smoothenProgress) > 0 && Number(smoothenProgress) < 100) && (
                <Container className='d-flex align-items-start upload-progress-container'>
                    <progress min={0} max={100} value={smoothenProgress}/>
                </Container>)
            }
        </>
    );
};

Progress.propTypes = {
    uid: PropTypes.string,
};

export default Progress;