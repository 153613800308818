import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from 'react-oauth2-code-pkce';
import { GovLoading } from '@gov-design-system-ce/react';
import { redirectToErrorPage } from './utils';

const UrednikRedirect = () => {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        try {
            const decodedToken = jwtDecode(token);
            const docsId = decodedToken.issrRequestId;

            navigate(`/dokumentace/${docsId}`);
        } catch(error) {
            // Not valid token - navigate to LandingPage for not authenticated user
            console.log(error);
            return redirectToErrorPage('401', navigate);
        }
    }, [token]);

    return <GovLoading message='Loading...' />;
};

export default UrednikRedirect;