import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import {  shouldNotSave, useOnlyRequiredKeys, spreadObject, checkLimitAndInsertText } from '../../helperFunctions/helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import {AffectedConstructionsAndParcels, FindAndProcessIco, PropertyDevelopperAddress} from '../index';
import { spreadParcelsAndConstructions } from '../../helperFunctions/helpers';
import { errorIcon, infoCircle, warningUnfilled } from '../../assets';
import { COUNTRIES, PROPERTY_DEVELOPER_MODEL } from '../../constants/sharedConstants.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { CharCount } from '../../components/index.js';
import { validDateAfterOrEquals, dateValidation, affectedPropertyNotAdded } from '../../helperFunctions/validationHelpers.js';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent15 = ({ urlPath }) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext); 
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 700);
    const { token } = useContext(AuthContext);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        startDate: null,
        endDate: null,
    });
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 10000},
        [1]: {count: 0, limit: 10000},
    });
    const [ focused, setFocused ] = React.useState({
        ico: false,
        names: false,
        lastName: false,
        nationality: false,
        city: false,
        zip: false,
        poName: false,
        description: false,
        proposal: false,
        builtArea: false,
        start: false,
        end: false,
        areaOfRemovedFlats: false,
        numberOfRemovedFlats: false,
    });
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            removeBuilding: false,
            removeFacility: false,
            removeTerrain: false,
            description: '',
            proposal: '',
            builtArea: '',
            asbestos: false,
            explosives: false,
            adjacentLand: false,
            numberOfRemovedFlats: '',
            areaOfRemovedFlats: '',
            removeFlats: false,
        }, 
        buildIntention: {
            startDate: '',
            endDate: '',
            selfHelp: true,
            propertyDeveloper: false,
            propertyDeveloperDetails: PROPERTY_DEVELOPER_MODEL,
        }
    });

    const buildIntention = request?.buildIntention;
    const form = request?.form;
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        affectedBuildConstructions: [],
        affectedBuildParcels: [],
    });
    const icoPattern = /^[0-9]{8}$/;

    const minDate = new Date();
    const [ min, setMin ] = useState(minDate.toJSON().slice(0, 10));
    const dateErrorStart = (!buildIntention?.startDate.trim() || isDateError.startDate || !validDateAfterOrEquals(buildIntention?.startDate, minDate)) && (error || focused.start);
    const dateErrorEnd = (!buildIntention?.endDate.trim() || isDateError.endDate || !validDateAfterOrEquals(buildIntention?.endDate, minDate)) && (error || focused.end);

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);
            console.log(response);

            if ((200 <= response?.status) && (response?.status < 300)) {
                spreadParcelsAndConstructions(
                    response.data, //obj
                    setParcelAndConstructionData, //func
                    parcelAndConstructionData // obj                
                );
                
                const loadedBuildInt = response?.data?.buildIntention;
                const loadedData = response?.data?.buildApplicationForms[0];
                const formStructure = request.form;
                const filteredDataToSave = useOnlyRequiredKeys(formStructure, loadedData);

                if (loadedBuildInt) {
                    setRequest(state => ({
                        ...state,
                        buildIntention: {
                            ...spreadObject(loadedBuildInt),
                            selfHelp: loadedBuildInt.selfHelp ?? true,
                            propertyDeveloper: loadedBuildInt.propertyDeveloper ?? false,
                            startDate: loadedBuildInt.startDate ?? '',
                            endDate: loadedBuildInt.endDate ?? '',
                            propertyDeveloperDetails: loadedBuildInt.propertyDeveloperDetails ?? null,
                        },
                        ...(loadedData ? { form: {...state.form, ...filteredDataToSave} } : {})
                    }));
                }
                
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);
    
    const setError = () => {
        setStagesArr(prev => [...prev].map(stg => {
            if (Number(stg.stage) === Number(stepValue)) { 
                return {...stg, error: true};
            }
            return stg;
        }));
    };

    useEffect(() => {
        if (Object.values(isDateError).every(el => el === null)) {
            return;
        }

        if (Object.values(isDateError).some(el => el)) {
            setError();
        }
    }, [isDateError]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();

        const isPropertyDeveloper = Boolean(request.buildIntention.propertyDeveloper);
        if (!isPropertyDeveloper) {
            const updatedRequest = {...request, buildIntention: {...request.buildIntention, propertyDeveloperDetails: {
                ...PROPERTY_DEVELOPER_MODEL,
                nationality: ''
            }} };
            await formSave(updatedRequest, token, urlPath, source);
            return;
        }

        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form, request.buildIntention]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;
            if (!form?.description?.trim()) {
                err = true;
            } else if (!form?.proposal?.trim()) {
                err = true;
            } else if (!form?.builtArea?.trim()) {
                err = true;
            } else if (!buildIntention?.startDate?.trim() || !validDateAfterOrEquals(buildIntention?.startDate, minDate) || isDateError.startDate) {
                err = true;
            } else if (!buildIntention?.endDate?.trim() || !validDateAfterOrEquals(buildIntention?.endDate, minDate) || isDateError.endDate) {
                err = true;
            } else if (affectedPropertyNotAdded(form, parcelAndConstructionData)) {
                err = true;
            } else if (form?.removeFlats && (!form?.numberOfRemovedFlats?.trim() || !form?.areaOfRemovedFlats?.trim())) {
                err = true;
            }

            if (buildIntention?.propertyDeveloperDetails?.ico?.trim()?.length > 0)
            {   
                warning = !icoPattern.test(buildIntention?.propertyDeveloperDetails?.ico?.trim().replace(/\s+/g, ''));
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>
            
            <Row className='row-wrapper'>
                <p className='p-0 title primary'>{`${stepValue}. Rozsah`}</p>
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 mb-4 title secondary'>Charakteristika odstraňované stavby, zařízení nebo terenních úprav</p>
                <form className='p-0'>
                    <label className='p-0 mb-4 textarea-input' style={{color: (!form?.description?.trim() && (error || focused.description)) ? '#C52A3A' : ''}}>
                    Popis odstraňované stavby, zařízení nebo terénních úprav
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count mt-2 p-2 text-area--validation'
                                onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}    
                                rows="5"
                                required
                                {...(focused.description && { focused: 'true' })}
                                style={{ border: error && !form?.description?.trim() && 'solid 1px #C52A3A' }}
                                defaultValue={form.description}
                                onBlur={(e) => { 
                                    setRequest(state => ({...state, form: {...state.form, description: e.target.value }}));
                                    handleFocused('description'); 
                                }}
                            />
                            <CharCount 
                                value={form.description} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                            />
                        </Row>
                        {(count[0].count >= count[0].limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
                        {(count[0].limit === 10000 && count[0].count >= 1000) && <Row className=''><p className='d-flex align-items-center p-0 kontrola-info my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>}
                    </label>
                    <label className='p-0 mb-4 textarea-input' style={{color: (!form?.proposal?.trim() && (error || focused.proposal)) ? '#C52A3A' : ''}}>
                    Návrh úpravy pozemku po jejím odstranění
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count mt-2 p-2 text-area--validation'
                                onChange={(e) => { checkLimitAndInsertText(e.target, 1, count, setCount); }}    
                                rows="5"
                                required
                                style={{ border: error && !form?.proposal?.trim() && 'solid 1px #C52A3A' }}
                                defaultValue={form.proposal}
                                onBlur={(e) => { 
                                    setRequest(state => ({...state, form: {...state.form, proposal: e.target.value }})); 
                                    handleFocused('proposal');
                                }}
                                {...(focused.proposal && { focused: 'true' })}
                            />
                            <CharCount 
                                value={form.proposal} setCount={setCount} propToUpdate={1} count={count[1].count} limit={count[1].limit}
                            />
                        </Row>
                        {(count[1].count >= count[1].limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
                        {(count[1].limit === 10000 && count[1].count >= 1000) && <Row className=''><p className='d-flex align-items-center p-0 kontrola-info my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>}
                    </label>
                    <Col md={6}>
                        <label className='p-0 mb-4 textarea-input' style={{color: (!form?.builtArea?.trim() && (error || focused.builtArea)) ? '#C52A3A' : ''}}>
                            {/* <div className='d-flex flex-column mb-3 mb-md-0 me-md-4 textarea-input'> */}
                            Zastavěná plocha odstraňované stavby (m2)
                            <input
                                type='text'
                                required
                                className='id-input2 mb-2'
                                style={{ border: error && !form?.builtArea?.trim() && 'solid 1px #C52A3A' }}
                                defaultValue={form.builtArea}
                                onBlur={(e) => {
                                    setRequest(state => ({...state, form: {...state.form, builtArea: e.target.value }}));
                                    handleFocused('builtArea');
                                }}
                                {...(focused.builtArea && { focused: 'true' })}
                            />
                            {(!form?.builtArea?.trim() && (error || focused.builtArea)) &&
                                <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                    Bez vyplnění pole &quot;Zastavěná plocha odstraňované stavby&quot; není možné pokračovat.
                                </Container>}
                            {/* </div> */}
                            {/* <p className='mt-4 input-help-text'>Pokud vyplníte, není nutné zapisovat výměry vznikajících pozemků ani přikládat přílohu Celková situace v měřítku katastrální mapy</p> */}
                        </label>
                    </Col>
                </form>
            </Row>

            <Row className='row-wrapper'>
                <form className='p-0'>
                    <label className={`ls-1p25 p-0 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={form.asbestos}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, asbestos: e.target.checked }}));
                            }}
                        />
                            Odstraňovaná stavba nebo zařízení obsahuje azbest nebo lze přítomnost azbestu očekávat              
                    </label>
                </form>
            </Row>
            <Row>
                <p className='p-0 mb-4 title secondary'>Předpokládaný termín zahájení a ukončení prací</p>
                <Col md={6}>
                    <label className='mb-4 label p-0' style={{color: dateErrorStart  ? '#C52A3A' : ''}}>
                        Zahájení
                        <input
                            type='date'
                            required
                            className='id-input2'
                            style={{ border: dateErrorStart  && 'solid 1px #C52A3A' }}
                            value={buildIntention?.startDate ?? ''}
                            min={minDate.toJSON().slice(0, 10)}
                            {...(focused.start && {focused: 'true'})}
                            onChange={(e) => {
                                const { value } = e.target;
                                const formatError = dateValidation(value);
                                if (!formatError) setIsDateError(state => ({...state, startDate: formatError}));
                                setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, startDate: value } }));
                            }}
                            onBlur={(e) => {
                                const { value } = e.target;
                                const formatError = dateValidation(value);
                                setMin(value);
                                if (formatError) setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, startDate: '' } }));
                                setIsDateError(state => ({...state, startDate: formatError}));
                                handleFocused('start');
                                if (!formatError && !validDateAfterOrEquals(value, minDate)) {
                                    setError();
                                }
                            }}
                        />
                        {isDateError.startDate && 
                        <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Nesprávný formát vyplnění, zkuste to znovu.
                        </Container>}
                        {!buildIntention?.startDate?.trim() && !isDateError.startDate && ((error || focused.start)) &&
                            <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Zahájení&quot; není možné pokračovat.
                            </Container>}
                    </label>
                </Col>
                <Col md={6}>
                    {(!error && !focused.start && !focused.end) &&
                        <p className='building-change my-2 my-md-0 ps-0 mt-md-4 ps-md-3'>
                            Datum zahájení i datum dokončení prací nesmí být dříve než datum odeslání žádosti.
                        </p>
                    }
                    {(
                        (error || focused.start || focused.end) && 
                        (
                            (buildIntention?.startDate?.trim() &&
                            !dateValidation(buildIntention?.startDate) && 
                            !validDateAfterOrEquals(buildIntention?.startDate, minDate))
                            ||
                            (buildIntention?.endDate?.trim() &&
                            !dateValidation(buildIntention?.endDate) &&
                            !validDateAfterOrEquals(buildIntention?.endDate, minDate))
                        )
                    ) &&
                        <Container className="mt-md-4 ps-md-3" style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Datum zahájení/ukončení prací nesmí nastat před podáním žádosti, prosím opravte datum zahájení/ukončení prací.
                        </Container>
                    }
                </Col>
                <Col md={6} className='mb-4'>
                    <label className='d-flex flex-column mb-4 label p-0' style={{color: dateErrorEnd ? '#C52A3A' : ''}}>
                        Dokončení
                        <input
                            type='date'
                            required
                            className='id-input2'
                            value={buildIntention?.endDate ?? ''}
                            min={min}
                            style={{ border: dateErrorEnd  && 'solid 1px #C52A3A' }}
                            {...(focused.end && {focused: 'true'})}
                            onChange={(e) => {
                                const { value } = e.target;
                                const formatError = dateValidation(value);
                                if (!formatError) setIsDateError(state => ({...state, endDate: formatError}));
                                setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, endDate: value } }));
                            }}
                            onBlur={(e) => {
                                const { value } = e.target;
                                const formatError = dateValidation(value);
                                if (formatError) setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, endDate: '' } }));
                                setIsDateError(state => ({...state, endDate: formatError}));
                                handleFocused('end');
                                if (!formatError && !validDateAfterOrEquals(value, minDate)) {
                                    setError();
                                }
                            }}
                        />
                        {isDateError.endDate && 
                        <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Nesprávný formát vyplnění, zkuste to znovu.
                        </Container>}
                        {!buildIntention?.endDate?.trim() && !isDateError.endDate && ((error || focused.end)) &&
                            <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Dokončení&quot; není možné pokračovat.
                            </Container>}
                    </label>
                </Col>
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 mb-4 title secondary'>Způsob odstraňování stavby, zařízení a terénních úprav</p>
                <Row className='d-flex pb-2'>
                    <Col md={6} sm={12}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='execution'
                                className='radio'
                                checked={Boolean(buildIntention.selfHelp)}
                                onChange={() => { 
                                    setRequest(prev => ({ 
                                        ...prev, 
                                        buildIntention: { 
                                            ...prev.buildIntention, 
                                            selfHelp: true, 
                                            propertyDeveloper: false,
                                            propertyDeveloperDetails: {
                                                ...PROPERTY_DEVELOPER_MODEL,
                                                nationality: ''
                                            }
                                        }
                                    })); }}
                            />
                        Svépomocí 
                        </label>
                    </Col>                     
                </Row>
                <Row className='d-flex'>
                    <Col md={6} sm={12}>
                        <label className='d-flex label'>
                            <input
                                type="radio"
                                name='execution'
                                className='radio'
                                checked={Boolean(buildIntention.propertyDeveloper)}
                                onChange={() => { 
                                    setRequest(prev => ({ 
                                        ...prev, 
                                        buildIntention: { 
                                            ...prev.buildIntention, 
                                            selfHelp: false,
                                            propertyDeveloper: true, 
                                            propertyDeveloperDetails: {
                                                ...prev.buildIntention.propertyDeveloperDetails, 
                                                ...PROPERTY_DEVELOPER_MODEL,
                                                isFOBusiness: false,
                                                isPO: false,
                                                id: prev.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                            } 
                                        } 
                                    })); }}
                            />
                        Stavebním podnikatelem                    
                        </label>
                    </Col>
                </Row>
            </Row>

            {buildIntention.propertyDeveloper &&                 
            <Container className='py-2'>
                <p className='p-0 pb-4 section-title'>Stavební podnikatel</p>
                <Row className='d-flex pb-2'>
                    <Col md={6} sm={12}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='developer'
                                className='radio'
                                checked={buildIntention?.propertyDeveloperDetails?.isFOBusiness ?? true}
                                onChange={() => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {
                                    ...prev.buildIntention.propertyDeveloperDetails, 
                                    ...PROPERTY_DEVELOPER_MODEL, 
                                    isFOBusiness: true, 
                                    isPO: false,
                                    id: prev.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                }  }})); }}
                            />
                            fyzická osoba podnikající
                        </label>
                    </Col>                     
                </Row>
                <Row className='d-flex pb-2'>
                    <Col md={6} sm={12}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='developer'
                                className='radio'
                                checked={buildIntention?.propertyDeveloperDetails?.isPO ?? false}
                                onChange={() => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {
                                    ...prev.buildIntention.propertyDeveloperDetails, 
                                    ...PROPERTY_DEVELOPER_MODEL, 
                                    isFOBusiness: false, 
                                    isPO: true,
                                    id: prev.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                }  }})); }}
                            />
                            právnická osoba
                        </label>
                    </Col>                     
                </Row>
            </Container>}

            {buildIntention.propertyDeveloper && 
            <Container className='py-2' fluid>
                {
                    (buildIntention?.propertyDeveloperDetails?.isFOBusiness || buildIntention?.propertyDeveloperDetails?.isPO)
                    &&
                    <>
                        <Row className='mb-4'>
                            <p className='p-0 section-title'>Identifikační údaje stavebního podnikatele</p>
                        </Row>
                        <Row className='pb-4'>
                            <FindAndProcessIco
                                updateProp={'propertyDeveloperDetails'}
                                parentProp={'buildIntention'}
                                focusKey={'icoNum'}
                                handleFocused={handleFocused}
                                focused={false} 
                                request={request} 
                                setRequest={setRequest}
                            />
                        </Row>
                    </>
                }
                {buildIntention?.propertyDeveloperDetails?.isFOBusiness && <>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                                Jméno
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.names ?? ''}
                                    onBlur={(e) => {
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, names: e.target.value}  }}));
                                    }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                        <Col md={6}>
                            <p className='mt-md-4 mt-3 building-change ps-md-3'>
                            Uveďte všechna jména
                            </p>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                                Příjmení
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.lastName ?? ''}
                                    onBlur={(e) => {
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, lastName: e.target.value}  }}));
                                    }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                                Rodné příjmení
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.lastNameOrigin ?? ''}
                                    // ref={(refEl) => separateCleanUpFields.current['fo/lastNameOrigin'] = refEl}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, lastNameOrigin: e.target.value}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                        <Col md={6}>
                            <p className='mt-md-4 mt-3 building-change ps-md-3'>
                            Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení
                            </p>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Container fluid>
                            <Col md={6}>
                                <label className='label p-0'>
                                Státní příslušnost
                                    <select
                                        type='select'
                                        style={{ border: error && !buildIntention?.propertyDeveloperDetails?.nationality && 'solid 1px #C52A3A' }}
                                        defaultValue={buildIntention?.propertyDeveloperDetails?.nationality ??  ''}
                                        onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, nationality: e.target.value}  }}));
                                        }}
                                        className='id-input2 dropdown-input'
                                    >
                                        <option value={''}>Vyberte</option>
                                        {COUNTRIES.map((country) => (
                                            <option key={country.value} value={country.value}>{country.label}</option>
                                        ))}
                                        
                                        
                                    </select>
                                </label>
                            </Col>
                        </Container>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                            Dodatek odlišující osobu podnikatele nebo druh podnikání vztahující se k této osobě nebo jí provozovanému druhu podnikání (nepovinné)
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.appendix ?? ''}
                                    // ref={(refEl) => separateCleanUpFields.current['fo/supplement'] = refEl}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, appendix: e.target.value}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                </>
                }
                {buildIntention?.propertyDeveloperDetails?.isPO && <>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                            Název nebo obchodní firma
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.poName ?? ''}
                                    onBlur={(e) => { 
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, poName: e.target.value}  }}));
                                    }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                </>}

                {(buildIntention?.propertyDeveloperDetails?.isFOBusiness) && <PropertyDevelopperAddress buildIntention={buildIntention} setRequest={setRequest}/>}
                {(buildIntention?.propertyDeveloperDetails?.isPO) && <PropertyDevelopperAddress buildIntention={buildIntention} setRequest={setRequest}/>}
            </Container>}

            <Row className='row-wrapper'>
                <form className='p-0'>
                    <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={form?.explosives}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, explosives: e.target.checked }}));
                            }}
                        />
                K odstranění stavby, zařízení nebo terénních úprav budou použity trhaviny
                    </label>                  
                    <label className={`ls-1p25 p-0 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={form?.adjacentLand}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, adjacentLand: e.target.checked }}));
                            }}
                        />
                K odstranění stavby, zařízení a terénních úprav má být použit sousední pozemek nebo stavba
                    </label>
                </form>
            </Row>

            {form?.adjacentLand &&
                <Row className='row-wrapper'>
                    <AffectedConstructionsAndParcels
                        setDataToAdd={setParcelAndConstructionData}
                        isAffected={true}
                        
                        urlPath={urlPath}
                        request={request}
                        setRequest={setRequest}
                        affectedBuildParcels={parcelAndConstructionData.affectedBuildParcels}
                        affectedBuildConstructions={parcelAndConstructionData.affectedBuildConstructions}
                        dataToAdd={parcelAndConstructionData}
                    />
                </Row>
            }
            <Container fluid>

                <p className='p-0 mb-4 title secondary'>Statistické údaje</p>
                <Col md={6}>
                    <label className='id-checkbox-label mb-4'>
                        <input
                            type='checkbox'
                            className='id-checkbox-input'
                            checked={form?.removeFlats}
                            onChange={() => setRequest(prev => ({...prev, form: { ...prev.form, removeFlats: !form.removeFlats}}))}
                        />
                        Odstraněním se ruší byty
                    </label>
                </Col>
            </Container>
            {form?.removeFlats && <>
                <Row className='d-flex flex-column'>
                    <Col md={6}>
                        <label className='p-0 mb-4 textarea-input' style={{ color: (error || focused.numberOfRemovedFlats) && !form?.numberOfRemovedFlats?.trim() && '#C52A3A' }}>
                    Počet zrušených bytů
                            <input
                                type='text'
                                className='id-input2'
                                defaultValue={form?.numberOfRemovedFlats}
                                style={{ border: (error || focused.numberOfRemovedFlats) && !form?.numberOfRemovedFlats?.trim() && 'solid 1px #C52A3A' }}
                                required
                                {...(focused.numberOfRemovedFlats && {focused: 'true'})}
                                onBlur={(e) => {
                                    setRequest(state => ({...state, form: {...state.form, numberOfRemovedFlats: e.target.value }}));
                                    handleFocused('numberOfRemovedFlats');
                                }}
                            />
                            {((error || focused.numberOfRemovedFlats) && !form?.numberOfRemovedFlats?.trim()) &&
                        <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole není možné pokračovat.
                        </Container>}
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className='d-flex'>
                        <label className='p-0 textarea-input' style={{ color: (error || focused.areaOfRemovedFlats) && !form?.areaOfRemovedFlats?.trim() && '#C52A3A' }}>
                        Užitková plocha všech bytů v m²
                            <input
                                type='text'
                                className='id-input2'
                                defaultValue={form?.areaOfRemovedFlats}
                                style={{ border: (error || focused.areaOfRemovedFlats) && !form?.areaOfRemovedFlats?.trim() && 'solid 1px #C52A3A' }}
                                required
                                {...(focused.areaOfRemovedFlats && {focused: 'true'})}
                                onBlur={(e) => {
                                    setRequest(state => ({...state, form: {...state.form, areaOfRemovedFlats: e.target.value }}));
                                    handleFocused('areaOfRemovedFlats');
                                }}
                            />
                            {((error || focused.areaOfRemovedFlats) && !form?.areaOfRemovedFlats?.trim()) &&
                        <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole není možné pokračovat.
                        </Container>}
                        </label>
                    </Col>
                    <Col md={6}>
                        <p className='mt-2 mt-md-4 ps-md-3 input-help-text'>Bez plochy nebytových prostor</p>
                    </Col>
                </Row>
            </> 
            }
            
        </Container>
    );
};

RozsahComponent15.propTypes = {
    urlPath: PropTypes.string.isRequired,

    
    
};

export default RozsahComponent15;