import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { notAuthorized } from '../assets';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const LoginError = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const errorCode = searchParams.get('errorCode');

    useEffect(() => {
        if(!errorCode) {
            navigate('/', { replace: true });
        }
    }, [errorCode]);

    return (
        <Container className='d-flex flex-column justify-content-center m-auto unauthorized-container'>
            <Row className='py-4 emoji-container d-flex align-items-center justify-content-center m-auto'><img className='emoji m-auto' src={notAuthorized} alt="Přístup nepovolen" /></Row>
            <Row className='py-4 d-flex align-items-center justify-content-center'><h1 className='m-0 p-0 unauthorized-title'>Přístup nepovolen</h1></Row>
            <Row className='py-4 d-flex align-items-center justify-content-center'><p className='m-0 p-0 unauthorized-text w-50'>Přístup na požadovanou stránku nebyl povolen. Prosím zkontrolujte adresu a zkuste to znovu, nebo přejděte na úvodní stranu.</p></Row>
            <Row className='py-4 d-flex align-items-center justify-content-center'>
                <Link to='/' className='p-0 uvodni-strana-link'><button type='button' className='uvodni-strana-btn'>Úvodní strana</button></Link>
            </Row>
            <Row className='py-4 d-flex align-items-center justify-content-center'>
                <span className='unauthorized-span'>
                    Error {`${errorCode}`}
                </span>
            </Row>
        </Container>
       
    );
};

export default LoginError;