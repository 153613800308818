import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DokumentaceVersionCard from './DokumentaceVersionCard';
import DokumentaceVersionsTable from './DokumentaceVersionsTable';

const DokumentaceVersionsOlder = ({ items, isActive, downloadDocumentVersion }) => {
    return (
        <Container fluid className='p-3 d-flex flex-column'>
            <Row>
                <span className='header__detail-project fw-500 py-3'>Starší verze</span>
            </Row>
            {(Array.isArray(items) && items?.length > 0) && (
                <Row>
                    {isActive === 'block' && <Row className='d-flex'>
                        {items.map((el, idx) => (
                            <Col key={el.bppPid || idx} xs={12} md={6} lg={4} className='d-flex justify-content-center py-2 px-md-2 px-lg-0 gap-2'>
                                <DokumentaceVersionCard item={el} downloadDocumentVersion={downloadDocumentVersion}/>
                            </Col>
                        ))}
                    </Row>}
                    {isActive === 'table' && <>
                        <Col>
                            <DokumentaceVersionsTable items={items} downloadDocumentVersion={downloadDocumentVersion}/>
                        </Col>
                    </>}
                </Row>)}
        </Container>
    );
};

DokumentaceVersionsOlder.propTypes = {
    items: PropTypes.any,
    isActive: PropTypes.any,
    downloadDocumentVersion: PropTypes.func 
};

export default DokumentaceVersionsOlder;
