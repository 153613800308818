import {
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO,
    ATTORNEY_CONTACTS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_PERSON_AUTHORIZED,
    BASIC_INFO,
    BASIC_INFO_BUSINESS,
    BASIC_INFO_BUSINESS_APPENDIX,
    BASIC_INFO_PO,
    CONTACTS,
    PERSON_AUTHORIZED,
} from './zadatelStates.js';

let items = [
    {
        stage: 1,
        name: 'Záměr',
        error: false,
        warning: false,
        seen: false,
    },
    {
        stage: 2,
        name: 'Oznamovatel',
        error: false,
        warning: false,
        seen: false,
    },
    {
        stage: 3,
        name: 'Rozsah',
        moreInfo: 'Rozsah žádosti a případná rozšíření',
        error: false,
        warning: false,
        seen: false,
    },
    {
        stage: 4,
        name: 'Přílohy',
        moreInfo: 'Další přílohy formuláře',
        error: false,
        warning: false,
        seen: false,
    },
    {
        stage: 5,
        name: 'Příjemce',
        error: false,
        warning: false,
        seen: false,
    },
    {
        stage: 6,
        name: 'Kontrola a podání',
        error: false,
        warning: false,
        seen: false,
    },
];

const BUILDING_ADDRESS = [
    {
        id: 1,
        label: 'Obec (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-city',
        required: false,
        state: 'city',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-cityPart',
        required: false,
        state: 'cityPart',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 3,
        label: 'Ulice (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-street',
        required: false,
        state: 'street',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 4,
        label: 'Číslo popisné (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 6,
        label: 'PSČ (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-zipCode',
        required: false,
        state: 'zip',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
];

const DEVELOPER_INFO = [
    {
        id: 1,
        label: 'IČO',
        type: 'text',
        refer: 'developerInfo-id',
        required: false,
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails',
        state: 'ico',
    },
    {
        id: 2,
        label: 'Jméno',
        type: 'text',
        refer: 'developerInfo-name',
        info: 'Uveďte všechna jména',
        required: false,
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails',
        state: 'names',
    },
    {
        id: 3,
        label: 'Příjmení',
        type: 'text',
        refer: 'developerInfo-surname',
        required: false,
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails',
        state: 'lastName',
    },
    {
        id: 4,
        label: 'Rodné příjmení',
        type: 'text',
        refer: 'developerInfo-birthSurname',
        info: 'Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení',
        required: false,
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails',
        state: 'lastNameOrigin',
    },
];

const DEVELOPER_INFO_PO = [
    {
        id: 1,
        label: 'IČO',
        type: 'text',
        refer: 'developerInfoPO-ico',
        required: false,
        state: 'ico',
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails'
    },
    {
        id: 2,
        label: 'Název nebo obchodní firma',
        type: 'text',
        refer: 'developerInfoPO-firm',
        required: false,
        state: 'poName',
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails'
    },
];

const DEVELOPER_ADDRESS = [
    {
        id: 1,
        label: 'Obec',
        type: 'text',
        refer: 'developer-city',
        required: false,
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails',
        parentProperty: 'address',
        state: 'city',
    },
    {
        id: 2,
        label: 'Část obce',
        type: 'text',
        refer: 'developer-cityPart',
        required: false,
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails',
        parentProperty: 'address',
        state: 'cityPart',
    },
    {
        id: 3,
        label: 'Ulice',
        type: 'text',
        refer: 'developer-street',
        required: false,
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails',
        parentProperty: 'address',
        state: 'street',
    },
    {
        id: 4,
        label: 'Číslo popisné',
        type: 'text',
        refer: 'developer-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails',
        parentProperty: 'address',
        state: 'descNum',
    },
    {
        id: 5,
        label: 'Číslo orientační',
        type: 'text',
        refer: 'developer-orientationNumber',
        required: false,
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails',
        parentProperty: 'address',
        state: 'orientNum',
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'developer-zipCode',
        required: false,
        object: 'buildIntention',
        propertyToUpdate: 'propertyDeveloperDetails',
        parentProperty: 'address',
        state: 'zip',
    },
];

const LOCATION_FORM_04 = [
    {
        id: 1,
        label: 'Obec',
        type: 'text',
        refer: 'location-city',
        required: true,
        state: 'city',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'location-cityPart',
        required: false,
        state: 'cityPart',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'

    },
    {
        id: 3,
        label: 'Ulice (nepovinné)',
        type: 'text',
        refer: 'location-street',
        required: false,
        state: 'street',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 4,
        label: 'Číslo popisné (nepovinné)',
        type: 'text',
        refer: 'location-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'location-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'location-zipCode',
        required: true,
        state: 'zip',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
];

const LOCATION_BUSINESS_FORM_04 = [
    {
        id: 1,
        label: 'Obec',
        info: 'Uveďte adresu zapsanou v obchodním rejstříku nebo jiné zákonem upravené evidenci jako místo podnikání (sídlo).',
        type: 'text',
        refer: 'location-city',
        required: true,
        state: 'city',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'location-cityPart',
        required: false,
        state: 'cityPart',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'

    },
    {
        id: 3,
        label: 'Ulice',
        type: 'text',
        refer: 'location-street',
        required: false,
        state: 'street',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 4,
        label: 'Číslo popisné',
        type: 'text',
        refer: 'location-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'location-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'location-zipCode',
        required: true,
        state: 'zip',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
];

const DELIVERY_LOCATION_FORM_04 = [
    {
        id: 1,
        label: 'Obec',
        type: 'text',
        refer: 'deliveryLocation-city',
        required: true,
        state: 'city',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'deliveryLocation-cityPart',
        required: false,
        state: 'cityPart',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 3,
        label: 'Ulice (nepovinné)',
        type: 'text',
        refer: 'deliveryLocation-street',
        required: false,
        state: 'street',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 4,
        label: 'Číslo popisné (nepovinné)',
        type: 'text',
        refer: 'deliveryLocation-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'deliveryLocation-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'deliveryLocation-zipCode',
        required: true,
        state: 'zip',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
];

const DELIVERY_LOCATION_BUSINESS_FORM_04 = [
    {
        id: 1,
        label: 'Obec',
        type: 'text',
        refer: 'deliveryLocationBusiness-city',
        required: true,
        state: 'city',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'deliveryLocationBusiness-cityPart',
        required: false,
        state: 'cityPart',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 3,
        label: 'Ulice',
        type: 'text',
        refer: 'deliveryLocationBusiness-street',
        required: false,
        state: 'street',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 4,
        label: 'Číslo popisné',
        type: 'text',
        refer: 'deliveryLocationBusiness-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'deliveryLocationBusiness-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'deliveryLocationBusiness-zipCode',
        required: true,
        state: 'zip',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
];

const CONTACTS_PO_FORM_04 = [
    {
        id: 1,
        label: 'Telefon (nepovinné)',
        type: 'text',
        refer: 'contacts-tel',
        required: false,
        state: 'phone',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 2,
        label: 'E-mail (nepovinné)',
        type: 'text',
        refer: 'contacts-email',
        required: false,
        state: 'email',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 3,
        label: 'ID datové schránky',
        type: 'text',
        refer: 'contacts-idDataBox',
        required: true,
        state: 'idDataBox',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
];

export {
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO,
    ATTORNEY_CONTACTS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_PERSON_AUTHORIZED,
    BASIC_INFO,
    BASIC_INFO_BUSINESS,
    BASIC_INFO_BUSINESS_APPENDIX,
    BASIC_INFO_PO,
    BUILDING_ADDRESS,
    CONTACTS,
    CONTACTS_PO_FORM_04,
    DELIVERY_LOCATION_BUSINESS_FORM_04,
    DELIVERY_LOCATION_FORM_04,
    DEVELOPER_ADDRESS,
    DEVELOPER_INFO,
    DEVELOPER_INFO_PO,
    items,
    LOCATION_BUSINESS_FORM_04,
    LOCATION_FORM_04,
    PERSON_AUTHORIZED,
};

