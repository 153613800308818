import React from 'react';
import { Container } from 'react-bootstrap';
import DokumentaceApiCall from '../components/DokumentaceDetail/DokumentaceApiCall';

const DokumentaceDetail = () => {

    return (
        <Container className='d-flex flex-column align-items-center zadosti-detail-page' fluid>
            <DokumentaceApiCall/>
        </Container>
    );
};

export default DokumentaceDetail;