import React, { useContext, useEffect, useState, useImperativeHandle, useMemo } from 'react';
import { Container, Row } from 'react-bootstrap';
import { arrowDownBlue, arrowUpBlue } from '../../assets';
import '../../css/zamerComponent.css';
import '../../css/prilohyComponent.css';
import { FileUpload, UploadedAttachments } from '../index';
import { AFFIDAVIT, WARRANT, OTHER_DOCUMENTS, SIMPLE_TECHNICAL_DESCRIPTION } from '../../constants/sharedConstants';
import {
    spreadUploadedAttachments,
    shouldNotSave,
    setApplicantsFile,
    setAttorneyFile,
    handleFiles,
    setApplicantRepreFile,
    getOngoingUploads,
} from '../../helperFunctions/helpers';
import usePrevious from '../CustomHooks/usePrevious';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import useFirstRender from '../CustomHooks/useFirstRender';
import { useLocation, useNavigate } from 'react-router-dom';

const PrilohyComponent19 = ({
    setFoundApplicantIndex,
    stepsRef,
    attachments,
    setAttachments,
    urlPath,    
    applicantArr,
}) => {
    const {intention, stepValue, saveFormRef, id, setUploadedPrilohyBytes} = useContext(FormContext); 
    const [isOpen, setIsOpen] = useState(WARRANT);
    const { token } = useContext(AuthContext);
    const [request, setRequest] = useState(
        {
            'applicationId': id,
            'buildApplication': {
                'attachments': []
            }
        }
    );

    const allAttachmentsToFilter = useMemo(() => Object.values(attachments || {})?.flat(), [attachments]);
    const allAttachments = useMemo(() => {
        return [...(allAttachmentsToFilter || [])].filter(el => !el.section?.startsWith('power_of_attorney'));
    }, [attachments]);
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const onGoingUploads = useMemo(() => getOngoingUploads(Object.values(attachments || {})?.flat()), [attachments]);
    const attachmentsToUpdate = useMemo(() => {
        return [...(allAttachments || [])].filter(fileObj => 
            (!fileObj.blobFile && !fileObj.abortController && !fileObj.message));
    }, [attachments]);


    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const buildApplicationForm = response.data?.buildApplicationForms[0];
                    //if(buildApplicationForm) setApplicantNotMe(Boolean(buildApplicationForm?.applicantAttorney || buildApplicationForm?.applicantMore));

                    setApplicantRepreFile(buildApplicationForm?.applicant, setAttachments);
                    if (buildApplicationForm?.applicantMore) {
                        setApplicantsFile(buildApplicationForm?.applicants, setAttachments);
                    }
                    setAttorneyFile(buildApplicationForm?.attorney, setAttachments);

                    const buildApplication = response?.data?.buildApplication;
                    const attachments = buildApplication?.attachments;

                    if (attachments instanceof Array && attachments?.length > 0) {
                        spreadUploadedAttachments([...(attachments || []), ...onGoingUploads], setAttachments, setUploadedPrilohyBytes);
                    }
                } else {
                    console.log('ID NOT YET IN DB');
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        } else {
            console.log('NON EXISTENT ID');
        }
    }, []);

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }
        saveChanges();
    }, [request.buildApplication.attachments]);

    useEffect(() => {
        setRequest(prev => ({ ...prev, buildApplication: { ...prev.buildApplication, attachments: [...attachmentsToUpdate].map(file => {
            // eslint-disable-next-line no-unused-vars
            const {blobFile, message, abortController, existing, flagged, ...rest} = file;
            return {...rest};
        }) } }));
    }, [attachmentsToUpdate?.length]);

    const handleFileInput = (files, category) => {
        handleFiles(files, category, token, setAttachments, null, allAttachmentsToFilter);
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    return (
        <Container className='pe-lg-3 component-container' fluid>
            <Row className='row-wrapper'>
                <h2 className='p-0 main-title'>{`${stepValue}. Přílohy`}</h2>
                <p className='p-0 prilohy-second_title'>oznámení o obnově stavby nebo terénní úpravy</p>
            </Row>

            <Row className='d-flex flex-column border-bottom pt-1 pb-4 mb-3' onClick={() => setIsOpen(WARRANT)}>
                <Row>
                    <p className='d-flex justify-content-between align-items-start p-0 pb-2 prilohy-title'>Plné moci<img src={(isOpen === WARRANT || attachments[WARRANT]?.length > 0) ? arrowUpBlue : arrowDownBlue} alt='arrowDown' /></p>
                </Row>
                <Row className='prilohy-row-text'>
                    <p className='p-0 prilohy-text'>K zastupování oznamovatele</p>
                </Row>
                {(isOpen === WARRANT || attachments[WARRANT]?.length > 0) && <Row>
                    <Row>
                        <UploadedAttachments
                            category={WARRANT}
                            attachments={attachments[WARRANT]} //warrant here is a fake category that combines power of attorneys
                            setUploads={setAttachments} 

                            stepsRef={stepsRef}
                            setFoundApplicantIndex={setFoundApplicantIndex}
                            applicantArr={applicantArr}
                        />
                    </Row>
                </Row>}
            </Row>
            
            <Row className='d-flex flex-column border-bottom pt-1 pb-4 mb-3' onClick={() => setIsOpen(AFFIDAVIT)}>
                <Row>
                    <p className='d-flex justify-content-between align-items-start p-0 pb-2 prilohy-title'>Čestné prohlášení<img src={(isOpen === AFFIDAVIT || attachments[AFFIDAVIT]?.length > 0) ? arrowUpBlue : arrowDownBlue} alt='arrowDown' /></p>
                </Row>
                <Row className='prilohy-row-text'>
                    <p className='p-0 prilohy-text'>o vlastnickém právu ke stavbě, která není předmětem evidence v katastru nemovitostí</p>
                </Row>
                {(isOpen === AFFIDAVIT || attachments[AFFIDAVIT]?.length > 0) && <Row>
                    <Row>
                        <UploadedAttachments
                            category={AFFIDAVIT}
                            attachments={attachments[AFFIDAVIT]}
                            setUploads={setAttachments} 
      
                        />
                    </Row>
                    <FileUpload handleFileInput={handleFileInput} category={AFFIDAVIT} isForPrilohy allUploads={allAttachments}/>
                </Row>}
            </Row>

            <Row className='d-flex flex-column border-bottom pt-1 pb-4 mb-3' onClick={() => setIsOpen(SIMPLE_TECHNICAL_DESCRIPTION)}>
                <Row>
                    <p className='d-flex justify-content-between align-items-start p-0 pb-2 prilohy-title'>Jednoduchý technický popis prací<img src={(isOpen === SIMPLE_TECHNICAL_DESCRIPTION || attachments[SIMPLE_TECHNICAL_DESCRIPTION]?.length > 0) ? arrowUpBlue : arrowDownBlue} alt='arrowDown' /></p>
                </Row>
                {(isOpen === SIMPLE_TECHNICAL_DESCRIPTION || attachments[SIMPLE_TECHNICAL_DESCRIPTION]?.length > 0) && <Row>
                    <Row>
                        <UploadedAttachments
                            category={SIMPLE_TECHNICAL_DESCRIPTION}
                            attachments={attachments[SIMPLE_TECHNICAL_DESCRIPTION]}
                            setUploads={setAttachments} 
      
                        />
                    </Row>
                    <FileUpload handleFileInput={handleFileInput} category={SIMPLE_TECHNICAL_DESCRIPTION} isForPrilohy allUploads={allAttachments}/>
                </Row>}
            </Row>

            <Row>
                <p className='p-0 mt-4 mb-3 prilohy-subtitle'>Další přílohy oznámení</p>
            </Row>
            <Row>
                <Row>
                    <UploadedAttachments
                        category={OTHER_DOCUMENTS}
                        attachments={attachments[OTHER_DOCUMENTS]}
                        setUploads={setAttachments} 
                          
                    />
                </Row>
                <FileUpload handleFileInput={handleFileInput} category={OTHER_DOCUMENTS} isForPrilohy allUploads={allAttachments}/>
            </Row>
        </Container>
    );
};

PrilohyComponent19.propTypes = {

    urlPath: PropTypes.string.isRequired,
    stepsRef: PropTypes.objectOf(Array),
    applicantArr: PropTypes.arrayOf(Object),
    setFoundApplicantIndex: PropTypes.func,
};

export default PrilohyComponent19;
