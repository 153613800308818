import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { uploadLoader } from '../assets';
import { convertBytes } from '../helperFunctions/helpers';

const ProgressIndicator = ({
    averageProgress,
    type,
    uploadSize,
    totalSize,
    remainingSizeInBytes,
}) => {
  
    return (
        <>
            {type === 'header' ? (
                <Container>
                    <Col className='d-flex' style={{marginBottom: '1rem'}}>
                        <img src={uploadLoader} className='loader-image'/>
                        <p className='loader-header-text'>{`Nahrávám ${(uploadSize || totalSize) ? `${uploadSize} z ${totalSize}` : 'soubor'}`}</p>
                    </Col>
                    <Col>
                        <p className='loader-header-percentage'>{`${averageProgress ?? 0}% hotovo`}</p>
                    </Col>
                </Container>
            ) :  type === 'footer' ? (
                <Col className='d-flex align-items-center justify-content-center'>
                    <img src={uploadLoader} className='loader-image small'/>
                    <Container className='d-flex flex-column upload-indicator-footer'>
                        <Row>
                            <p className='upload-indicator-footer primary'>{`Nahrávám ${(uploadSize || totalSize) ? `${uploadSize} z ${totalSize}` : 'soubor'}`}
                                <span className='upload-indicator-footer span'>{` (${averageProgress ?? 0}% hotovo)`}
                                </span>
                            </p>
                        </Row>
                        <Row>
                            {remainingSizeInBytes > 0 && <p className='upload-indicator-footer secondary'>{`Možno nahrát ještě ${convertBytes(remainingSizeInBytes) ?? '0 MB'}`}</p>}
                        </Row>
                    </Container>
                </Col>
            ) : ''}        
        </>
    );
};

ProgressIndicator.propTypes = {
    averageProgress: PropTypes.any,
    remainingSizeInBytes: PropTypes.any,
    uploadSize: PropTypes.any,
    totalSize: PropTypes.any,
    type: PropTypes.any,
};

export default ProgressIndicator;