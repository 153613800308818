import { useState } from 'react';
import { BlockBlobClient } from '@azure/storage-blob';
import { handleDeleteUpload, handleSetUpload, isTrackableForDokumentace, isTrackableForPrilohy } from '../../helperFunctions/helpers';
import { isRequestOK } from '../../apiCalls/componentsApiCalls';

const useUpload = ({   
    forCsvImport = false,
    setUploads = null,
    setUploadedBytes = null,
    setUploadedPrilohyBytes = null,
    uploadProgressRef = null,
    setUploadError = null
}) => {
    const [uploadState, setUploadState] = useState({
        uploadSuccess: false,
        uploadError: false,
        uploadCancelled: false,
    });
    
    const upload = forCsvImport ?
        () => {
            setUploadState(prev => ({ ...prev, uploadSuccess: true }));
        } : 
        async (
            url,
            fileObject,
            abortSignal,
        ) => {
            const {blobFile: file, uid, section, size} = fileObject || {};

            if (!url || !file || !abortSignal) {
                return;
            }

            try {
                setUploadState(prev => ({ ...prev, uploadError: false }));

                const onProgress = (prog) => {
                    if(!abortSignal.aborted) {
                        const progress = Math.floor((Number(prog?.loadedBytes) / Number(size)) * 100);
                        // if (Number(progress) % 10 === 0)
                        if (uploadProgressRef?.current) uploadProgressRef.current[uid] = Number(progress || 0);
                    }
                };

                const blockBlobClient = new BlockBlobClient(url);
        
                const uploadOptions = {
                    abortSignal,
                    onProgress,
                };

                const data = await blockBlobClient.uploadData(file, uploadOptions);
                if (isRequestOK(data._response.status)) {
                    setUploadState(prev => ({ ...prev, uploadSuccess: true }));
                    if (uploadProgressRef?.current) {
                        delete uploadProgressRef?.current[uid];
                    }
                    if (setUploads) {
                        handleSetUpload(setUploads, section, uid);
                    }

                    if (isTrackableForDokumentace(section)) {
                        if (setUploadedBytes) setUploadedBytes(prev => Number(prev) + Number(size));
                    } else if (isTrackableForPrilohy(section)) {
                        if (setUploadedPrilohyBytes) setUploadedPrilohyBytes(prev => Number(prev) + Number(size));
                    }
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    setUploadState(prev => ({ ...prev, uploadCancelled: true }));
                    if (setUploads) {
                        handleDeleteUpload(setUploads, section, uid);
                    }
                    console.log('Upload aborted');
                } else {
                    setUploadState(prev => ({ ...prev, uploadError: true }));
                    if (setUploads) {
                        setUploads((prev) => ({
                            ...prev,    
                            [section]: [...prev[section]].map(el => {
                                if (el.uid === uid) {
                                    return {...el, message: 'single_upload_error'};
                                }
                                return el;
                            })
                        }));
                    }
                    if (setUploadError) {
                        setUploadError(prev => [...new Map([...prev, {
                            where: section,
                            why: 'single_upload_error',
                            uid: uid,
                        }].map(el => [el.uid, el])).values()]); 
                    }
                    console.log('Upload error:', error);
                }
                if (uploadProgressRef?.current) {
                    delete uploadProgressRef?.current[uid];
                }
            }
        }; 

    return { 
        upload, 
        uploadSuccess: uploadState.uploadSuccess, 
        uploadError: uploadState.uploadError, 
        setUploadState, 
        uploadCancelled: uploadState.uploadCancelled 
    };
};

export default useUpload;
