import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { GovLoading } from '@gov-design-system-ce/react';
import { redirectToErrorPage } from './utils';

const TOKEN_PARAM_NAME = 'token';
const ERROR_CODE_PARAM_NAME = 'errorCode';
const STORAGE_PREFIX_KEY = 'ROCP_';

function setLocalStorage(name, value) {
    localStorage.setItem(`${STORAGE_PREFIX_KEY}${name}`, JSON.stringify(value));
}

const UrednikLogin = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get(TOKEN_PARAM_NAME);
    const errorCode = searchParams.get(ERROR_CODE_PARAM_NAME) || '401';

    useEffect(() => {
        // Not token provided - return
        if(!token) {
            return redirectToErrorPage(errorCode, navigate);
        }

        try {
            const decodedToken = jwtDecode(token);

            // We do not have idToken for Officer
            // setLocalStorage('idToken', token);
            setLocalStorage('loginInProgress', false);
            setLocalStorage('refreshTokenExpire', decodedToken.exp);
            setLocalStorage('token', token);
            setLocalStorage('tokenExpire', decodedToken.exp);

            window.location.replace('/');
        } catch(error) {
            // Not valid token - navigate to LandingPage for not authenticated user
            console.log(error);
            return redirectToErrorPage(errorCode, navigate);
        }
    }, [token, errorCode]);

    return <GovLoading message='Loading...' />;
};

export default UrednikLogin;