import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import '../../css/reusable.css';
import { counter, saved } from '../../constants/zadostState';
import { Container, Row, Col } from 'react-bootstrap';
import { closeErrorPanel, iconAutoSave, importError } from '../../assets';
import { Link, useLocation, useOutletContext } from 'react-router-dom';
import PropTypes from  'prop-types';
import FormContext from '../../formContexts/FormContext';
import { convertBytes, isTrackableUpload, getTotalValueFromUploads, getTotalValue } from '../../helperFunctions/helpers';
import { ProgressIndicator } from '../index';
import useFirstRender from '../CustomHooks/useFirstRender';

const FormHeader = ({ header, isMobileView, currentlyUploading }) => {
    const [, forceUpdate] = useState(false);
    const saving = saved.value;
    const { uploadError, setUploadError, fetch, setFetch, uploadProgressRef } = useContext(FormContext);
    const [lastSaveTime, setLastSaveTime] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const errorsToShow = useMemo(() => {
        return [...new Map([...uploadError]
            .map(element => [element.uid, element])).values()]
            .filter((el) => isTrackableUpload(el.where));
    }, [uploadError]);
    const shouldShowErrorPanel = errorsToShow?.length > 0;
    const [panelOpen, setPanelOpen] = useState(false);
    // FOR DOKUMENTACE
    const isSizeExceeded = [...errorsToShow].find(el => el.why === 'total_size') ?? false;
    // FOR PRILOHY
    const isSizeExceededForPrilohy = [...(errorsToShow || [])].find(el => el.why === 'total_size_prilohy') ?? false;
    const isIndividualSizeExceeded = [...(errorsToShow || [])].find(el => el.why === 'size') ?? false;
    const isTotalAmountExceeded = [...(errorsToShow || [])].find(el => el.why === 'total_amount') ?? false;
    const apiCallError = [...(errorsToShow || [])].find(el => el.why === 'api_call') ?? false;
    const hasWrongFormat = [...(errorsToShow || [])].some(el => el.why === 'format');
    const location = useLocation();
    const cooldownPeriod = 3000;
    const [averageProgress, setAverageProgress] = useState(0);
    const totalSizeValue = useMemo(() => getTotalValueFromUploads(currentlyUploading, 'size') || 0, [currentlyUploading]);
    const uploadSize = convertBytes((totalSizeValue / currentlyUploading?.length) * averageProgress / 100, 2) || 0;
    const totalSize = convertBytes(totalSizeValue, 2) || 0;
    const canRenderProgressIndicator = useMemo(() => Boolean(currentlyUploading?.length > 0), [currentlyUploading]) ?? false;
    const [, setDocumentErrorCount] = useOutletContext();

    const firstRender = useFirstRender();
    const calculateAverageProgress = () => {
        const progressValues = Object.values(uploadProgressRef?.current || {});
        const totalProgress = getTotalValue(progressValues);
        return progressValues.length > 0 ? Math.round(totalProgress / progressValues.length) : 0;
    };

    useEffect(() => {
        const updateProgress = () => {
            setAverageProgress(calculateAverageProgress());
        };
    
        updateProgress();
    
        const interval = setInterval(updateProgress, 100);
        return () => clearInterval(interval);
    }, [currentlyUploading]);

    const fetchZamerAgain = () => {
        const currentTime = Date.now();
        if (currentTime - lastSaveTime > cooldownPeriod) {
            setLastSaveTime(currentTime);
            setFetch(!fetch);
            setIsButtonDisabled(true); 
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, cooldownPeriod);
        } else {
            console.log('Please, wait before fetching again.');
        }
    };

    useEffect(() => {
        const unsubscribe = saved.subscribe(() => {
            forceUpdate(update => !update);
        });
  
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (!firstRender && setDocumentErrorCount) {
            setDocumentErrorCount(errorsToShow.length);
        }

        if (!shouldShowErrorPanel) {
            return;
        }

        if (!panelOpen && shouldShowErrorPanel) {
            setPanelOpen(shouldShowErrorPanel);
        }

    }, [shouldShowErrorPanel, errorsToShow.length]);

    useEffect(() => {
        if (panelOpen) {
            const timer = setTimeout(() => {
                if (isSizeExceededForPrilohy) setUploadError(prev => [...(prev || [])].filter(el => el.why !== 'total_size_prilohy'));
                setPanelOpen(false);
                setDocumentErrorCount(0);
            }, 60000);

            return () => clearTimeout(timer);
        }
    }, [panelOpen]);

    const handleClosePanel = () => {
        setUploadError(prev => [...(prev || [])].filter(el => (el.why !== 'total_size_prilohy' && el.why !== 'total_size')));
        setPanelOpen(false);
        setDocumentErrorCount(0);
    };

    return (
        <Container fluid className={`p-3 p-md-5 d-flex align-items-center justify-content-center ${panelOpen ? 'mt-lg-5' : ''}`}>
            {(panelOpen && errorsToShow.length > 0) && (
                <Container className='import_error_container_bg' style={{backgroundColor: apiCallError ? '#F9C13C' : ''}}>
                    <Container className='import_error_container header d-flex align-items-center justify-content-between px-4 py-3 mb-1' style={{backgroundColor: apiCallError ? '#F9C13C' : ''}}>
                        <Container className='d-flex align-items-center'>
                            <img src={importError} alt='upozorneni'/>
                            <div className='import_error_text ps-3'>
                                {(errorsToShow.length > 1 && !apiCallError) ? 'Chyba nahrávání souboru v sekcich ' : errorsToShow.length === 1 && !apiCallError ? 'Chyba nahrávání souboru v sekci ' : ''}
                                {[...new Map(errorsToShow.map(el => [el.where, el])).values()].map((el, idx, arr) => {
                                    return (<Fragment key={el?.uid || idx}>
                                        {
                                            !apiCallError
                                            &&
                                            <>
                                                &quot;{isTrackableUpload(el.where, true) || ''}&quot;{(idx < arr.length - 1 || el.why === 'format') ? ', ' : ''}
                                            </>
                                        }
                                        {el.why === 'format' && 'Nepodporovaný formát souboru, nelze vložit. '}
                                        {el.why === 'api_call' && <p>Nepodařilo se předvyplnit informace o záměru. Můžete <span onClick={fetchZamerAgain} style={{color: isButtonDisabled ? '#aaaaaa' : '#3077B7', cursor: isButtonDisabled ? 'not-allowed' : 'pointer', font: 'inherit'}} className='d-inline-block text-decoration-underline cursor-pointer'>opakovat požadavek na vyplnění</span> nebo formulář vyplnit ručně.</p>}
                                    </Fragment>);
                                }
                                )}
                                {!apiCallError && (isSizeExceeded ? 
                                    ' - Soubory nemohou být nahrány, překročen limit velikosti dokumentace 5 GB.'
                                    : isSizeExceededForPrilohy ? ' - Soubory nemohou být nahrány, překročen limit velikosti příloh 1 GB.'
                                        : isTotalAmountExceeded ? ' - Soubory nemohou být nahrány, překročen limit 50 000 souborů dokumentace.'
                                            : '')}
                                {!apiCallError && ((isSizeExceeded || isSizeExceededForPrilohy) ?
                                    ' Prosím optimalizujte soubory pro nižší velikost a zkuste znovu.' 
                                    : isTotalAmountExceeded ?
                                        ' Prosím slučte soubory do větších celků a zkuste znovu. ' 
                                        : isIndividualSizeExceeded ? ' Překročena maximální velikost nahrávaných souborů'
                                            : !hasWrongFormat ? ' Prosím zkontrolujte své připojení a zkuste soubor nahrát znovu.' 
                                                : '')}
                            </div>
                        </Container>
                        <img src={closeErrorPanel} alt='zavrit' onClick={() => handleClosePanel()} style={{cursor: 'pointer'}} />
                    </Container>
                </Container>)}
            <Row className='base-width d-flex justify-content-between align-items-center' style={!isMobileView ? { borderBottom: 'solid #ddd 1px', paddingBottom: '5rem'} : { borderBottom: 'none', paddingBottom: '0'}}>
                <Col lg={7} className='my-3 my-md-0'>
                    <Row className='d-flex flex-column'>
                        <Col>
                            <p style={{fontSize: '2rem', fontWeight: '500', lineHeight: '3rem', letterSpacing: '0.01rem', marginBottom: '1rem'}}>{header.title}</p>
                        </Col>
                        {!isMobileView && <Col>
                            <p style={{fontSize: '1rem', fontWeight: '400', lineHeight: '1.5rem', letterSpacing: '0.0125rem'}}>
                                {header.perex}
                            </p>
                            {header?.perex1 && <p className='mt-2' style={{fontSize: '1rem', fontWeight: '400', lineHeight: '1.5rem', letterSpacing: '0.0125rem'}}>
                                {header.perex1}
                            </p>}
                        </Col>}
                    </Row>
                </Col>
                <Col md={{span: 4, offset: 1}} className='d-none d-lg-block' >
                    <Container style={{minHeight: '158px', maxWidth: '420px', padding: '1.5rem', borderRadius: '3px', border: 'solid 1px #DDD', backgroundColor: '#FFF'}}>
                        <Row className='d-flex flex-column'>
                            {canRenderProgressIndicator && 
                            <ProgressIndicator 
                                type={'header'}
                                averageProgress={averageProgress}
                                uploadSize={uploadSize}
                                totalSize={totalSize}
                            />}
                            {!canRenderProgressIndicator && (!saving
                                ?
                                <span style={{marginBottom: '3.80rem'}}>Ukládám formulář...</span>
                                :
                                <>
                                    <Col className='d-flex h-100' style={{marginBottom: '1rem'}}>
                                        <img src={iconAutoSave} style={{marginRight: '1rem'}}/>
                                        <p style={{fontSize: '1rem', fontWeight: '700', lineHeight: '1.5rem', letterSpacing: '0.015rem'}}>Automaticky uloženo</p>
                                    </Col>
                                    <Col>
                                        <p style={{marginLeft: '2.25rem', marginBottom: '1rem', fontSize: '0.938rem', fontWeight: '500', lineHeight: '1.464rem', letterSpacing: '0.018rem'}}>před {counter} sekundami</p>
                                    </Col>
                                </>)}
                            <Col>
                                <Link 
                                    to={!canRenderProgressIndicator ? '/zadosti' : '#'}
                                    state={{from: location}}
                                    className={`save_link ${canRenderProgressIndicator ? 'disabled' : ''}`}
                                >
                                    <button
                                        className={`w-100 d-flex align-items-center justify-content-center button-solid form-header-btn ${canRenderProgressIndicator ? 'disabled' : ''}`}
                                    >
                                        {!saving ? 'Automaticky ukládám' : 'Uložit a zavřít'}
                                    </button>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

FormHeader.propTypes = {
    header: PropTypes.objectOf(String),
    isMobileView: PropTypes.bool,
    currentlyUploading: PropTypes.arrayOf(Object),
    averageProgress: PropTypes.number,
    documentsToTrack: PropTypes.any,
};

export default FormHeader;