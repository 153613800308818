import React, {
    // useContext, useEffect, useMemo,
    useState
} from 'react';
import {
    // FormFinisher, FormFooter, FormHeader, NovaDokumentaceMainSection,
    ProcessError
} from '../index.js';
import {
    // getOngoingUploads, getTotalValueFromUploads, isNotUploading,
    logError
} from '../../helperFunctions/helpers.js';
// import { DOCUMENT_FIFTH, DOCUMENT_FIRST, DOCUMENT_FOURTH, DOCUMENT_SECOND, DOCUMENT_THIRD_1, DOCUMENT_THIRD_2, DOCUMENT_THIRD_3, DOCUMENT_THIRD_4, DOCUMENT_THIRD_5, NEW_DOCUMENTATION } from '../../constants/sharedConstants.js';
import { ErrorBoundary } from 'react-error-boundary';
import {
    // Col,
    Container,
    // Row
} from 'react-bootstrap';
// import FormContext from '../../formContexts/FormContext.js';
import { useParams } from 'react-router-dom';
// import { isRequestOK } from '../../apiCalls/componentsApiCalls.js';
// import { formSave } from '../../apiCalls/formApiCalls.js';
// import { AuthContext } from 'react-oauth2-code-pkce';

const UrednikDocumentation = () => {
    const [ isMobileView ] = useState(window.innerWidth < 992);
    // const [isFinished, setIsFinished] = useState(false);
    // const [id, setId] = useState(null);
    // const [urlToCopy, setUrlToCopy] = useState('');
    // const [uploadError, setUploadError] = useState([]);
    // const [isSuccessOrFailSubmission, setIsSuccessOrFailSubmission] = useState(null);
    // const [uploadedBytes, setUploadedBytes] = useState(0);
    // const [uploadedPrilohyBytes, setUploadedPrilohyBytes] = useState(0);
    // const [stagesArr, setStagesArr] = useState([
    //     {
    //         stage: 1,
    //         name: 'Dokumentace',
    //         error: false,
    //         warning: false,
    //         seen: false,
    //     }
    // ]);
    // const [documents, setDocuments] = useState({
    //     [DOCUMENT_FIRST]: [],
    //     [DOCUMENT_SECOND]: [],
    //     [DOCUMENT_THIRD_1]: [],
    //     [DOCUMENT_THIRD_2]: [],
    //     [DOCUMENT_THIRD_3]: [],
    //     [DOCUMENT_THIRD_4]: [],
    //     [DOCUMENT_THIRD_5]: [],
    //     [DOCUMENT_FOURTH]: [],
    //     [DOCUMENT_FIFTH]: [],
    // });
    // const currentlyUploading = useMemo(() => getOngoingUploads(Object.values(documents || {})?.flat()), [documents]);
    // const averageProgress = currentlyUploading.length > 0 ? Math.round(getTotalValueFromUploads(currentlyUploading) / currentlyUploading.length) : 0;
    // eslint-disable-next-line no-undef
    // const allUploads = useMemo(() => [...(Object.values(documents || {}) || [])], [documents]);
    const { id: urlId } = useParams();
    // const { token } = useContext(AuthContext);
    // const [loading, setLoading] = useState(true);
    // const urlPath = 'quick-save-api/documentation/save';
    // TODO reshape for new version
    // const isDocumentationWithId = Boolean(urlId);

    // useEffect(() => {
    //     // TODO
    //     if (!urlId) {
    //         (async () => {
    //             let data = JSON.stringify({
    //                 'buildApplication': {
    //                     'title': NEW_DOCUMENTATION.get((false))?.title
    //                 }
    //             });

    //             const response = await formSave(data, token, urlPath);
    //             if (isRequestOK(response?.status)) {
    //                 setId(response?.data);
    //                 setLoading(false);
    //             }
    //         })();
    //     } else {
    //         setId(urlId);
    //         setLoading(false);
    //     }
    // }, []);

    // useEffect(() => {
    //     function handleBeforeUnload (e) {
    //         if (!isNotUploading(allUploads)) {
    //             e.preventDefault();
    //         }
    //     }

    //     function handleResize() {
    //         if(window.innerWidth < 992) {
    //             setIsMobileView(true);
    //             return;
    //         } 

    //         setIsMobileView(false); 
    //     }

    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [allUploads]);

    return (
        <ErrorBoundary fallback={<ProcessError idFromBoundary={urlId} isFromErrorBoundary />} onError={logError}>
            <Container className={`d-flex flex-column align-items-center pt-5 background ${isMobileView ? 'mobile' : ''}`} fluid>
                <div className='d-flex align-items-center' style={{ height: '50vh' }}>
                    <h1>Dokumentace</h1>
                </div>
                {/* {isFinished ? 
                    <FormFinisher
                        isSuccessOrFailSubmission={isSuccessOrFailSubmission} 
                        header={''} 
                        id={id} 
                        linkRef={'documentation'}
                        setIsFinished={setIsFinished}
                        urlToCopy={urlToCopy}
                    /> 
                    : <>
                        <FormContext.Provider
                            value={{
                                intention: NEW_DOCUMENTATION.get((isDocumentationWithId))?.title,
                                uploadError,
                                setUploadError,
                                uploadedBytes,
                                setUploadedBytes,
                                uploadedPrilohyBytes,
                                setUploadedPrilohyBytes,
                                id,
                                setStagesArr,
                                stagesArr
                            }}
                        >
                            <FormHeader
                                averageProgress={averageProgress}
                                currentlyUploading={currentlyUploading}
                                isMobileView={isMobileView}
                                header={NEW_DOCUMENTATION.get((true))}
                            />
                            <Container fluid className='d-flex justify-content-center px-3 pt-0 pb-3 p-lg-5'>
                                <Row className='base-width'>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }} className='pe-lg-3'>
                                        {!loading ? 
                                            <NovaDokumentaceMainSection
                                                documents={documents}
                                                setDocuments={setDocuments}
                            
                                                
                                                isDocumentationWithId={isDocumentationWithId}
                                                urlPath={urlPath}
                                            /> 
                                            : <div className='loading'>
                                                <span>Načítám formulář...</span>
                                            </div>}
                                    </Col>
                                </Row>
                            </Container>
                            <FormFooter
                                
                                currentlyUploading={currentlyUploading}
                                averageProgress={averageProgress}
                                setIsSuccessOrFailSubmission={setIsSuccessOrFailSubmission}
                                setIsFinished={setIsFinished}
                                formNumber={'documentation'}
                                setUrlToCopy={setUrlToCopy}
                            />
                        </FormContext.Provider>
                    </>} */}
            </Container>
        </ErrorBoundary>
    );
};

export default UrednikDocumentation;