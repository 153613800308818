import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { file } from '../../assets';

const DokumentaceVersionCard = ({item, downloadDocumentVersion, darker}) => {
    return (
        <Container className={`d-flex flex-column border rounded px-3 py-4 dokumentace-card-container ${darker ? 'darker' : ''}`} fluid>
            <Row className='d-flex mb-3'>
                <span className='p-0 zadosti-title'>{item?.finishDate ?? ''}</span>
            </Row>
            <Row className='mb-4'>
                <Col>
                    <Row className='d-flex align-items-center mb-3'>
                        <span className='p-0 zadosti-id'>PID: {item?.bppPid ?? ''}</span>
                    </Row>
                </Col>
            </Row>
            <Row className='mt-auto justify-self-end'>
                <Col>
                    <Row className='mb-4'>
                        <button type='button' className='d-flex justify-content-center align-items-center kontrola-button' onClick={() => downloadDocumentVersion(item?.bppPid)}><img src={file} alt="file" className='kontrola-button-icon' />Stáhnout</button>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

DokumentaceVersionCard.propTypes = {
    item: PropTypes.any,
    darker: PropTypes.bool,
    downloadDocumentVersion: PropTypes.func 
};

export default DokumentaceVersionCard;