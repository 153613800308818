import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/zadosti.css';
import DokumentaceVersionCard from './DokumentaceVersionCard';

const DokumentaceVersionsNewest = ({item, downloadDocumentVersion}) => {
    return (
        <Container fluid className='p-3 d-flex flex-column'>
            <Row>
                <span className='header__detail-project py-4 fw-500'>Nejnovější verze</span>
            </Row>
            <Row className='d-flex'>
                <Col xs={12} md={6} lg={4} className='d-flex justify-content-center py-2 px-md-2 px-lg-0 gap-2'>
                    <DokumentaceVersionCard item={item} downloadDocumentVersion={downloadDocumentVersion} darker/>
                </Col>
            </Row>
        </Container>
    );
};

DokumentaceVersionsNewest.propTypes = {
    item: PropTypes.any,
    downloadDocumentVersion: PropTypes.func 
};

export default DokumentaceVersionsNewest;