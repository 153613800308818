import {  
    BASIC_INFO, 
    BASIC_INFO_BUSINESS, 
    BASIC_INFO_BUSINESS_APPENDIX, 
    BASIC_INFO_PO, 
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO, 
    PERSON_AUTHORIZED, 
    ATTORNEY_PERSON_AUTHORIZED,
    LOCATION, 
    LOCATION_BUSINESS,
    DELIVERY_LOCATION, 
    CONTACTS, 
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
} from './zadatelStates.js';

let items = [
    {
        stage: 1,
        name: 'Záměr',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 2,
        name: 'Žadatel',
        moreInfo: 'Osobní údaje o žadateli a případně o jeho zástupci',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 3,
        name: 'Dokumentace',
        moreInfo: 'Stavební dokumentace, ve výchozím stavu se přebírá ze Záměru',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 4,
        name: 'Rozsah',
        moreInfo: 'Rozsah žádosti a případná rozšíření',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 5,
        name: 'Přílohy',
        moreInfo: 'Další přílohy formuláře',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 6,
        name: 'Příjemce',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 7,
        name: 'Kontrola a podání',
        error: false,
        warning: false,
        seen: false,  
    },
];

const BUILDING_ADDRESS = [
    {
        id: 1,
        label: 'Obec (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-city',
        info: 'Místo odstraňované stavby, zařízení nebo terénních úprav',
        required: false,
        state: 'city',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-cityPart',
        required: false,
        state: 'cityPart',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 3,
        label: 'Ulice (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-street',
        required: false,
        state: 'street',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 4,
        label: 'Číslo popisné (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 6,
        label: 'PSČ (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-zipCode',
        required: false,
        state: 'zip',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
];

const CONTACTS_PO_FORM_15 = [
    {
        id: 1,
        label: 'Telefon (nepovinné)',
        type: 'text',
        refer: 'contacts-tel',
        required: false,
        state: 'phone',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 2,
        label: 'E-mail (nepovinné)',
        type: 'text',
        refer: 'contacts-email',
        required: false,
        state: 'email',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 3,
        label: 'ID datové schránky',
        type: 'text',
        refer: 'contacts-idDataBox',
        required: true,
        state: 'idDataBox',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
];


export {
    BASIC_INFO,
    BASIC_INFO_BUSINESS, 
    BASIC_INFO_BUSINESS_APPENDIX, 
    BASIC_INFO_PO, 
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO, 
    PERSON_AUTHORIZED, 
    ATTORNEY_PERSON_AUTHORIZED,
    LOCATION,
    LOCATION_BUSINESS,
    DELIVERY_LOCATION,
    CONTACTS,
    CONTACTS_PO_FORM_15,
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
    BUILDING_ADDRESS,
    items,
};
