import React from 'react';
import { GovTabs, GovTabsItem } from '@gov-design-system-ce/react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TabInserted from './TabInserted.jsx';
import TabInProgress from './TabInProgress.jsx';

const DokumentaceMainSection = ({ isActive, setIsActive }) => {
    return (
        <Container fluid>
            <Container className='base-width mx-auto px-2 py-4 px-md-5 px-xl-0' fluid>
                <GovTabs className='remove-border' type="text" wcag-label="Dokumentace">
                    <GovTabsItem label='Vložené'>
                        <TabInserted isActive={isActive} setIsActive={setIsActive} />
                    </GovTabsItem>

                    <GovTabsItem label='Rozpracované'>
                        <TabInProgress />
                    </GovTabsItem>
                </GovTabs>
            </Container>
        </Container>
    );
};

DokumentaceMainSection.propTypes = {
    isActive: PropTypes.string.isRequired,
    setIsActive: PropTypes.func.isRequired,
};

export default DokumentaceMainSection;