import { Col, Container, Row } from 'react-bootstrap';
import React, { useContext, useEffect, useState, useImperativeHandle } from 'react';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../../formContexts/FormContext';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import '../../css/stavbaComponent.css';
import '../../css/zadatelComponent.css';
import { canFulFillPrijemceList, getPrijemceList, shouldNotSave, spreadObject, spreadParcelsAndConstructions, checkLimitAndInsertText, isPruvodniListNotYetSubmitted } from '../../helperFunctions/helpers';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import { errorIcon, infoCircle } from '../../assets';
import { SearchWithDataList } from '../index.js';
import { AUTHORITY_MODEL, BUILDING } from '../../constants/sharedConstants';
import usePrevious from '../CustomHooks/usePrevious';
import useFirstRender from '../CustomHooks/useFirstRender';
import { StavbyTable } from '../index';
import { CharCount } from '../../components/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebounce from '../CustomHooks/useDebounce.jsx';

const StavbaComponent11 = ({ setCheckbox, urlPath }) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id, setWaitingForPruvodniList} = useContext(FormContext);
    const { token } = useContext(AuthContext);
    const [ focused, setFocused ] = React.useState({
        obec: false,
        datumDokonceni: false,
        cisloJednaci: false,
        zeDne: false,
        dobaTrvani: false,
        mapIdentifier: false,
        confirmedGeometricPlan: false,
        descriptionOfDeviations:false,
        dataList: false,
    });
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        expectedEndDate: false,
        authorityDate: false,
    });
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        approvedConstructions: [],
    });
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 10000},
    });
    const [isExceeded, setIsExceeded] = useState(false);
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            numberOfApartments: '',
        },
        buildIntention: {
            constructionId: '',
            buildingName: '',
            address: {
                city: '',
                cityPart: '',
            },
            confirmedGeometricPlan: '',
            mapIdentifier: '',
            estimatedCompletionDate: '',
            testOperationNotCarried: true,
            testOperationWasCarried: false,
            testOperationRefNumber: '',
            testOperationDated: '',
            testOperationDuration: '',
            notCarriedWithDeviations: true,
            carriedWithDeviations: null,
            descriptionOfDeviations: '',
            setOfBuildings: false,
            testOperationAuthority: AUTHORITY_MODEL
        }
    });
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const buildIntention =  request?.buildIntention;

    const [searchRequest, setSearchRequest] = useState(
        {
            title: '',
            typeTitle: null
        }
    );
    const [prijemceList, setPrijemceList] = useState([]);
    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const debouncedSearchTitle = useDebounce(searchRequest.title);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };
    
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (!canFulFillPrijemceList(request, firstRender, searchRequest, setPrijemceList, 'testOperationAuthority')) {
            return;
        }
    
        setIsSearchLoading(true);
        getPrijemceList(searchRequest, token, setPrijemceList, setIsSearchLoading, signal);

        return () => {
            controller.abort();
        };
    }, [debouncedSearchTitle]);

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {
                spreadParcelsAndConstructions(
                    response.data, //obj
                    setParcelAndConstructionData, //func
                    parcelAndConstructionData // obj                
                );

                const intention = response?.data?.buildIntention;

                if (intention) {
                    // eslint-disable-next-line no-unused-vars
                    const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;

                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, ...spreadObject(rest), notCarriedWithDeviations: intention.notCarriedWithDeviations ?? true } }));
                    
                    if (intention?.testOperationAuthority && intention?.testOperationAuthority?.authorityName) {
                        setSearchRequest(prev => ({...prev, title: `${intention?.testOperationAuthority?.authorityName} - ${intention?.testOperationAuthority?.address?.city}`, typeTitle: BUILDING }));
                    }  
                }

                const loadedDataForm = response?.data?.buildApplicationForms[0];

                if (loadedDataForm?.numberOfApartments) {
                    setRequest(state => ({ ...state, form: {...state.form, numberOfApartments: loadedDataForm?.numberOfApartments } }));
                }
                if (setWaitingForPruvodniList) setWaitingForPruvodniList(isPruvodniListNotYetSubmitted(response?.data ?? {}, 'deviationsCheck'));
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    useEffect(() => {
        if (buildIntention.carriedWithDeviations === null) {
            return;
        }

        setCheckbox({
            first: buildIntention.carriedWithDeviations ?? false,
        });
    }, [buildIntention.carriedWithDeviations]);

    useEffect(() => {
        if (request?.buildIntention?.buildConstructions && parcelAndConstructionData.buildConstructions.length === 0) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildConstructions: prev?.buildConstructions?.length === 0 ? [...(request?.buildIntention?.buildConstructions ?? [])] : prev.buildConstructions,
            }));
        }

        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form, request.buildIntention]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            if(
                // !buildIntention?.address?.city?.trim() ||
                !buildIntention?.estimatedCompletionDate?.trim()
                || (buildIntention?.testOperationWasCarried && !buildIntention?.testOperationRefNumber?.trim())
                || (buildIntention?.testOperationWasCarried && !buildIntention?.testOperationDated?.trim())
                || (buildIntention?.testOperationWasCarried && !buildIntention?.testOperationDuration?.trim())
                || (buildIntention?.testOperationWasCarried && !buildIntention?.testOperationAuthority?.authorityName?.trim())
            )
            {
                err = true;
            }
            else if(buildIntention.carriedWithDeviations && !buildIntention?.descriptionOfDeviations?.trim())
            {
                err = true;
            }
            else if (isDateError.authorityDate || isDateError.expectedEndDate) {
                err = true;
            }
            
            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            
            return !err;
        }
    }));

    return (
        <Container fluid>
            <Row className='row-wrapper'>
                <h2 className='p-0 main-title'>{`${stepValue}. Stavba`}</h2>
            </Row>
            
            <p className='p-0 mb-4 section-title'>Základní údaje o kolaudované stavbě</p>

            <Row className='border p-4 row-wrapper'>
                <h3 className='p-0 id-title'>Místo stavby</h3>
                <Row className='d-flex p-0 pb-4'>
                    <Col md={6}>
                        <label className='p-0 povoleni-label' /* style={{color: error && !buildIntention?.address?.city?.trim() ? '#C52A3A' : ''}} */>
                        Obec (nepovinné)
                            <input 
                                type='text'
                                // required
                                // {...(focused.obec && { focused: 'true' })}
                                defaultValue={buildIntention?.address?.city}
                                // style={{border: error && !buildIntention?.address?.city?.trim() ? '#C52A3A solid 1px' : ''}}
                                onBlur={(e) => {
                                    setRequest(prev => ({...prev, buildIntention: {...prev.buildIntention, address: {...prev.buildIntention.address, city: e.target.value}}}));
                                    // handleFocused('obec');
                                }}
                                className='mt-1 id-input2 waterworks bg-white p-2'
                            />
                            {/* {!error && <Container className='input-message--error' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Obec&quot; není možné pokračovat.
                            </Container>} */}
                            {/* {
                                error
                                &&
                                !buildIntention?.address?.city?.trim()
                                &&
                                <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                    Bez vyplnění pole &quot;Obec&quot; není možné pokračovat.
                                </Container>
                            } */}
                        </label>
                    </Col>
                </Row>
                <Row className='d-flex'>
                    <Col md={6}>
                        <label className='select-input'>
                        Část obce (nepovinné)
                            <input type='text' defaultValue={buildIntention?.address?.cityPart}
                                onBlur={(e) => {setRequest(prev => ({...prev, buildIntention: {...prev.buildIntention, address: {...prev.buildIntention.address, cityPart: e.target.value}}}));}}
                                className='mt-1 form-zamer-input waterworks bg-white p-2'
                            />
                        </label>
                    </Col>
                </Row>
            </Row>

            <Row className='mb-4'>
                <Col md={6}>
                    <label className='povoleni-label'>
                    Číslo geometrického plánu potvrzeného katastrálním úřadem
                        <input 
                            type='text'
                            defaultValue={buildIntention?.confirmedGeometricPlan}
                            // style={{ border: error && !buildIntention?.verifiedDocumentationId?.trim() ? 'solid 1px #C52A3A' : '' }}
                            onBlur={(e) => {
                                setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, confirmedGeometricPlan: e.target.value } }));
                            }}
                            className='povoleni-input bg-white p-2 mt-1'
                        />
                    </label>
                </Col>
                <Col md={6} className='d-flex align-items-end'>
                    <p className='povoleni-help-text mt-3 mt-md-4 ps-md-3'>Pokud je stavba předmětem evidence v katastru nemovitostí nebo její výstavbou dochází k rozdělení pozemku</p>
                </Col>
            </Row>

            <Row className='mb-4'>
                <Col md={6}>
                    <label className='povoleni-label'>
                    Identifikátor záznamu, ve kterém byly zapsány změny týkající se obsahu digitální technické mapy kraje, nebo předány podklady pro jejich zápis
                        <input 
                            type='text'
                            defaultValue={buildIntention?.mapIdentifier}
                            // style={{ border: error && !buildIntention?.verifiedDocumentationId?.trim() ? 'solid 1px #C52A3A' : '' }}
                            onBlur={(e) => {
                                setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, mapIdentifier: e.target.value } }));
                            }}
                            className='povoleni-input bg-white p-2 mt-1'
                        />
                    </label>
                </Col>
                <Col md={6} className='d-flex align-items-end'>
                    <p className='povoleni-help-text mt-3 mt-md-4 ps-md-3'>Pokud jsou údaje o stavbě obsahem digitální technické mapy kraje</p>
                </Col>
            </Row>

            <Col md={6} className='mb-4'>
                <label className='p-0 povoleni-label' style={{color: (!buildIntention?.estimatedCompletionDate?.trim() && (error || focused.datumDokonceni)) || isDateError.expectedEndDate ? '#C52A3A' : ''}}>
                    Předpokládaný termín dokončení stavby
                    <input 
                        type='date' 
                        className='id-input2'
                        required
                        style={{border: ((error && !buildIntention?.estimatedCompletionDate?.trim()) || isDateError.expectedEndDate) ? '#C52A3A solid 1px' : ''}}
                        value={buildIntention?.estimatedCompletionDate ?? ''}
                        {...(focused.datumDokonceni && { focused: 'true' })}
                        onChange={e => setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, estimatedCompletionDate: e.target.value } }))}
                        onBlur={(e) => { 
                            const validatedDate = dateValidation(e.target.value); 
                            validatedDate && setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, estimatedCompletionDate: '' } }));
                            setIsDateError(state => ({...state, expectedEndDate: validatedDate}));
                            handleFocused('datumDokonceni');
                        }}
                    />
                    {isDateError.expectedEndDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                    </Container>}
                    {(!buildIntention?.estimatedCompletionDate?.trim() && (error || focused.datumDokonceni)) && !isDateError.expectedEndDate &&
                        <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;Předpokládaný termín dokončení stavby&quot; není možné pokračovat.
                        </Container>}
                </label>
            </Col>

            <Row className='row-wrapper'>
                <form className='d-flex flex-column p-0 form'>
                    <label className='id-checkbox-label'>
                        <input
                            type="checkbox"
                            className='id-checkbox-input'
                            checked={request?.buildIntention?.setOfBuildings ?? false}
                            onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, setOfBuildings: e.target.checked } })); }} />
                            Stavba byla povolena jako součást souboru staveb
                    </label>
                </form>
            </Row>

            <Row className='row-wrapper'>
                <StavbyTable
                    title={'Kolaudované stavby'}
                    subtitle={'V případě kolaudace souboru staveb uveďte jako první stavbu hlavní.'}
                    
                    urlPath={urlPath}
                    dataToAdd={parcelAndConstructionData.approvedConstructions}
                    approvedConstructions={true}
                    setIsExceeded={setIsExceeded}
                    setDataToAdd={setParcelAndConstructionData}
                />
                {isExceeded &&  <p className='d-flex align-items-start p-0 mt-3 stavba-info__text'> <img src={infoCircle} alt="infoCircle" className='me-2' />V jedné žádosti je možné zadat nejvýše 5 staveb.</p>}
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 pb-4 section-title'>Nepodstatné odchylky</p>
                <Row className='d-flex pb-2'>
                    <Col md={6}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='deviations'
                                className='radio'
                                checked={buildIntention.notCarriedWithDeviations}
                                onChange={() => { 
                                    setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, notCarriedWithDeviations: true, carriedWithDeviations: false }})); 
                                    if (setWaitingForPruvodniList) setWaitingForPruvodniList(false);
                                }}
                            />
                        Stavba nebyla provedena s nepodstatnými odchylkami od ověřené projektové dokumentace 
                        </label>
                    </Col>                     
                </Row>
                <Row className='d-flex'>
                    <Col md={6}>
                        <label className='d-flex label'>
                            <input
                                type="radio"
                                name='deviations'
                                className='radio'
                                checked={buildIntention.carriedWithDeviations ?? false}
                                onChange={() => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention,  notCarriedWithDeviations: false, carriedWithDeviations: true } })); }}
                            />
                        Stavba byla provedena s nepodstatnými odchylkami od ověřené projektové dokumentace
                        </label>
                    </Col>
                </Row>

                {buildIntention.carriedWithDeviations && <Container className='mt-4'>
                    <Row className='justification-container'>
                        <Col>
                            <span className='px-0 m-0 justification-text' style={{color: (!buildIntention?.descriptionOfDeviations?.trim() && (error || focused.descriptionOfDeviations)) ? '#C52A3A' : ''}}>
                                Popis a zdůvodnění nepodstatných odchylek
                            </span>
                        </Col>
                    </Row>
                    <Row className='py-2 text-area--validation'>
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count p-2'
                                onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}    

                                defaultValue={buildIntention.descriptionOfDeviations}
                                required
                                {...(focused.descriptionOfDeviations && { focused: 'true' })}
                                style={{border: error && !buildIntention?.descriptionOfDeviations?.trim() ? '#C52A3A solid 1px' : ''}}
                                onBlur={(e) => { 
                                    setRequest(prev => ({ ...prev, buildIntention: {...prev.buildIntention, descriptionOfDeviations: e.target.value}}));
                                    handleFocused('descriptionOfDeviations');
                                }}
                            />
                            <CharCount 
                                value={buildIntention.descriptionOfDeviations} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                            />
                        </Row>
                    </Row>
                </Container>}
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 pb-4 section-title'>Zkušební provoz</p>
                <Row className='d-flex pb-2'>
                    <Col md={6} sm={12}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                className='radio'
                                checked={buildIntention?.testOperationNotCarried}
                                onChange={() => { 
                                    setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, testOperationNotCarried: true, testOperationWasCarried: false }})); 
                                }}
                            />
                        Zkušební provoz nebyl proveden 
                        </label>
                    </Col>                     
                </Row>
                <Row className='d-flex pb-2'>
                    <Col md={6} sm={12}>
                        <label className='d-flex label'>
                            <input
                                type="radio"
                                className='radio'
                                checked={buildIntention?.testOperationWasCarried}
                                onChange={() => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention,  testOperationNotCarried: false, testOperationWasCarried: true } })); }}
                            />
                        Zkušební provoz byl proveden
                        </label>
                    </Col>
                </Row>
            </Row>

            {buildIntention?.testOperationWasCarried && <Row className='d-flex flex-column'>
                <p className='p-0 mb-2 provoz-subtitle'>Povolení (příp. nařízení) zkušebního provozu</p>
                <p className='p-0 mb-4 provoz-subtitle--help-text'>Pokud byl nařízen, nebo povolen zkušební provoz, doložte Vyhodnocení zkušebního provozu</p>

                <Col md={6}>
                    <label className='p-0 mb-4 povoleni-label' style={{color: (!buildIntention?.testOperationAuthority?.authorityName?.trim() && (error || focused.dataList)) ? '#C52A3A' : ''}}>
                        Označení správního orgánu, který povolení zkušebního provozu vydal
                        <SearchWithDataList
                            request={request}
                            authorityType={'testOperationAuthority'}
                            authorityObj={request.buildIntention.testOperationAuthority}
                            setRequest={setRequest}
                            paramFirst={'title'}
                            paramSecond={'typeTitle'}
                            setSearchRequest={setSearchRequest}
                            searchRequest={searchRequest} // obj
                            listData={prijemceList}
                            setListData={setPrijemceList} // arr
                            dynamicVal={BUILDING} // str
                            isSearchLoading={isSearchLoading}
                            setIsSearchLoading={setIsSearchLoading}
                        />
                    </label>
                </Col>
                <Col md={6}>
                    <label className='p-0 mb-4 povoleni-label' style={{color: (!buildIntention?.testOperationRefNumber?.trim() && (error || focused.cisloJednaci)) ? '#C52A3A' : ''}}>
                        Číslo jednací
                        <input
                            type='text'
                            className='id-input2'
                            required
                            style={{border: (!buildIntention?.testOperationRefNumber?.trim() && (error || focused.cisloJednaci)) ? '#C52A3A solid 1px' : ''}}
                            defaultValue={buildIntention?.testOperationRefNumber}
                            {...(focused.cisloJednaci && { focused: 'true' })}
                            onBlur={(e) => { 
                                setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, testOperationRefNumber: e.target.value } })); 
                                handleFocused('cisloJednaci');
                            }}
                        />
                        {(!buildIntention?.testOperationRefNumber?.trim() && (error || focused.cisloJednaci)) &&
                        <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;Číslo jednací&quot; není možné pokračovat.
                        </Container>}
                    </label>
                </Col>
                <Col md={6}>
                    <label className='p-0 mb-4 povoleni-label' style={{color: (!buildIntention?.testOperationDated?.trim() && (error || focused.zeDne)) || isDateError.authorityDate ? '#C52A3A' : ''}}>
                        Ze dne
                        <input
                            type='date'
                            className='id-input2'
                            required
                            value={buildIntention?.testOperationDated ?? ''}
                            style={{border: (!buildIntention?.testOperationDated?.trim() && (error || focused.zeDne)) || isDateError.authorityDate ? '#C52A3A solid 1px' : ''}}
                            {...(focused.zeDne && { focused: 'true' })}
                            onChange={e => setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, testOperationDated: e.target.value } }))}
                            onBlur={(e) => { 
                                const validatedDate = dateValidation(e.target.value); 
                                validatedDate && setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, testOperationDated: '' } }));
                                setIsDateError(state => ({...state, authorityDate: validatedDate}));
                                handleFocused('zeDne');
                            }}
                        />
                        {isDateError.authorityDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                        </Container>}
                        {(!buildIntention?.testOperationDated?.trim() && (error || focused.zeDne)) && !isDateError.authorityDate &&
                        <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;Ze dne&quot; není možné pokračovat.
                        </Container>}
                    </label>
                </Col>
                <Col md={6}>
                    <label className='p-0 povoleni-label' style={{color: (!buildIntention?.testOperationDuration?.trim() && (error || focused.dobaTrvani)) ? '#C52A3A' : ''}}>
                        Doba trvání zkušebního provozu 
                        <input
                            type='text'
                            className='id-input2'
                            required
                            style={{border: error && !buildIntention?.testOperationDuration?.trim() ? '#C52A3A solid 1px' : ''}}
                            defaultValue={buildIntention?.testOperationDuration}
                            {...(focused.dobaTrvani && { focused: 'true' })}
                            onBlur={(e) => { 
                                setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, testOperationDuration: e.target.value } })); 
                                handleFocused('dobaTrvani');
                            }}
                        />
                        {(!buildIntention?.testOperationDuration?.trim() && (error || focused.dobaTrvani)) &&
                        <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;Doba trvání zkušebního provozu &quot; není možné pokračovat.
                        </Container>}
                    </label>
                </Col>
            </Row>}

        </Container>
    );
};

StavbaComponent11.propTypes = {
    setCheckbox: PropTypes.func,
    urlPath: PropTypes.string.isRequired,

};

export default StavbaComponent11;

