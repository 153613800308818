import React, { useContext } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../css/kontrolaComponent.css';
import { errorIcon, file, infoCircle, warningYellow } from '../../assets/index';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../../formContexts/FormContext.js';

const KontrolaComponent02 = () => {
    const { token } = useContext(AuthContext);
    const { validationRef, stepValue, setStagesArr, stagesArr, id, waitingForPruvodniList, uploadProgressRef} = useContext(FormContext); 
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [ confirmation, setConfirmation ] = React.useState(false);
    const [ focused, setFocused ] = React.useState(false);

    const createPdf = async () => {
        try {
            const source = axios.CancelToken.source();

            const response = await axios.get(
                urlJoin(apiConfig.validateAndPersistBaseUrl, `preview-pdf/build-application-02/${id}`),
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                });

            if ((200 <= response?.status) && (response?.status < 300)) {
                console.log('CALL GET PDF', response);
                window.open(URL.createObjectURL(response.data));
            }

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
        }
    };

    React.useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if(!confirmation)
            {
                err = true;
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    const handleFocused = () => {
        setFocused(true);
    };

    const hasError = error && !confirmation;

    return (
        <Container fluid>
            <Row className='row-wrapper'>
                <h2 className='p-0 mb-2 main-title'>{stepValue}. Kontrola a podání</h2>
                <p className='p-0 kontrola-subtitle'>Stáhněte a zkontrolujte svou žádost.</p>
            </Row>
            <Row className='mb-3'>
                <button type='button' className='d-flex justify-content-center align-items-center kontrola-button' onClick={createPdf}>
                    <img src={file} alt="file" className='kontrola-button-icon' />Stáhnout náhled žádosti (pdf)
                </button>
            </Row>
            <Row className='mb-3'>
                <form className='p-0'>
                    <label style={{color: hasError && '#C52A3A'}}>
                        <input
                            type="checkbox"
                            className='kontrola-checkbox-input'
                            style={{appearance: hasError && 'none', border: hasError && 'solid #C52A3A 1.5px', borderRadius: hasError && '3px'}}
                            onBlur={handleFocused}
                            required
                            checked={confirmation}
                            onChange={(e) => setConfirmation(e.target.checked)}
                            {...(focused && error && { focused: confirmation ? 'false' : 'true' })}
                        />
                        Prohlašuji, že veškeré údaje, které jsou v této žádosti uvedeny, jsou pravdivé a správné.
                    </label>
                </form>
            </Row>
            {
                error
                &&
                !confirmation
                &&
                <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginBottom: '.5rem'}}>
                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                    Bez zaškrtnutí pole prohlášní není možné pokračovat.
                </Container>
            }
            <Row>
                {Object.values(uploadProgressRef?.current || {})?.length > 0 && <p className='d-flex align-items-center p-0 kontrola-info'><img src={infoCircle} alt="infoCircle" className='me-2' />Žádost je možné podat až bude ukončeno nahrávání vložených souborů</p>}
                {waitingForPruvodniList && <p className='d-flex align-items-center p-0 kontrola-info mt-2'><img src={infoCircle} alt="infoCircle" className='me-2' />Žádost je možné podat až bude vložen průvodní list.</p>}
            </Row>
            {/* HERE WILL BE SOME CONDITION TO RENDER */}
            {
                !error && Boolean(stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.warning) && 
                <Row>
                    <p className='d-flex align-items-center p-0 kontrola-info kontrola-info--warning'><img src={warningYellow} alt="warning" className='me-1' />Žádost je možné podat až po vypořádání chybějících a chybných polí, viz osnova.</p>
                </Row>
            }
        </Container>
    );
};

export default KontrolaComponent02;