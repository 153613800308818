import React, { useState } from 'react';
import { HeaderSection, ZadostiMainSection } from '../components';
import { Container } from 'react-bootstrap';

const Zadosti = () => {
    const [isActive, setIsActive] = useState('block');

    return (
        <Container className='d-flex flex-column align-items-center' fluid>
            <HeaderSection headerText={'Všechny žádosti, které jsou podány vámi, či zástupcem vaším jménem.'} page={'zadosti'} mainHeader={'Moje žádosti'}/>
            <ZadostiMainSection isActive={isActive} setIsActive={setIsActive} />
        </Container>
    );
};

export default Zadosti;