import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { iconStageDone, alertIcon, errorIcon, chevronDownMobileBlue, chevronUpMobileBlue } from '../../assets';
import { useGlobalState } from '../../constants/states';
import FormContext from '../../formContexts/FormContext.js';
import { CircularProgress } from '../index.js';
import { FORM_18_INTENTION } from '../../constants/sharedConstants.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';

const FormProgress = ({ stepsRef, isMobile }) => {
    const [opened, setOpened] = useState(false);
    const { validationRef, stepValue, stagesArr, setStagesArr, intention } = useContext(FormContext);
    const [state, setState] = useGlobalState();
    const { stage } = state;
    const fixedStyle = !isMobile ? {
        maxWidth: '377px',
        backgroundColor: 'white',
        padding: '1.2rem',
    } : {
        maxWidth: '100%',
        backgroundColor: 'white',
        padding: '1.2rem',
    };
    const isForm18 = intention === FORM_18_INTENTION.title;
    const [isMobileElementsDeployed, setIsMobileElementsDeployed] = useState(false);
    const progressRef = useRef();
    const hasWarning = (item) => stagesArr.find(stg => Number(stg?.stage) === Number(item?.stage))?.warning ?? false;
    const hasInputError = (item) => stagesArr.find(stg => Number(stg?.stage) === Number(item?.stage))?.error ?? false;
    const isCurrentItem = (item) => Number(item?.stage) === Number(stage);
    const isVisited = (item) => stagesArr.find(stg => Number(stg?.stage) === Number(item?.stage))?.seen ?? false;
    const previousStage = usePrevious(stage);
    
    useEffect(() => {
        if (!previousStage) {
            return;
        }

        setStagesArr(prev => [...prev].map(stg => {
            if (Number(previousStage) === Number(stagesArr.length)) {
                return {...stg, warning: false};
            } else if (Number(stg.stage) === Number(previousStage)) {
                return {...stg, seen: true};
            }
            return stg;
        }));

    }, [stage]);

    const nextStage = (stg) => {
        if ((stage > stagesArr.length) || (stg === stage)) {
            return;
        }

        if (isForm18 && stage !== 4 && stage !== 3 && stage !== 2) {
            setState(prevState => {
                return ({ ...prevState, stage: stg });
            });
            return;
        }

        if ((stage === 1 && !isForm18) || (isForm18 && (stage === 4 || stage === 3 || stage === 2))) {
            
            if (validationRef?.current?.validate()) {
                setState(prevState => {
                    return ({ ...prevState, stage: stg });
                });
            } else {
                window.scrollTo({
                    top: 480,
                    behavior: 'smooth',
                });
            }
            return;
        }

        if (stage !== stagesArr.length) {
            validationRef?.current?.validate();
        }

        setState(prevState => {
            return ({ ...prevState, stage: stg });
        });
    };

    return (
        isMobile ? 
            <Container fluid style={fixedStyle} className='mb-3 pb-0'>
                <Row className='d-flex flex-column' style={{ color: '#15355E' }}>
                    <Col style={{ marginBottom: '1rem', fontSize: '0.90625rem', fontWeight: '400', color: '#686868' }} ref={progressRef}>Osnova</Col>
                    <Container className={`pb-3 ${isMobileElementsDeployed ? 'border-bottom' : ''}`}>
                        <Container className='d-flex align-items-center justify-content-between'>
                            <Row>
                                <p style={{ fontSize: '1.1875rem', fontWeight: '600', color: '#3B3B3B' }} className='p-0 mb-2'>{stagesArr.find(el => el?.stage === stage)?.stage}. {stagesArr.find(el => el?.stage === stage)?.name}</p>
                            </Row>
                            <Row className='position-relative'>
                                <CircularProgress progress={stagesArr.filter(el => el.error === false && el.seen === true).length} totalSteps={stagesArr.length} />
                                <span className='position-absolute progress-info-span'>{stagesArr.find(el => el?.stage === stage)?.stage} z {stagesArr.length}</span>
                            </Row>
                        </Container>
                        <Container className='d-flex align-items-center' onClick={() => setIsMobileElementsDeployed(prev => !prev)}>
                            <p style={{ fontSize: '1rem', fontWeight: '400', color: '#2362A2', cursor: 'pointer' }} className='p-0'>{!isMobileElementsDeployed ? 'Zobrazit' : 'Skrýt'} osnovu</p>
                            <Row style={{cursor: 'pointer'}}>
                                <img style={{height: 'auto', width: '38px'}} src={!isMobileElementsDeployed ? chevronDownMobileBlue : chevronUpMobileBlue} alt="progress" />
                            </Row>
                        </Container>
                    </Container>
                    <Col className={`${isMobileElementsDeployed ? 'py-3' : ''}`}>
                        {isMobileElementsDeployed && (stagesArr.length > 0 && stagesArr.map((item, index) => {
                            return (
                                <Container key={index + item?.name + item?.stage} >
                                    <Row>
                                        <Col className='d-flex align-items-center' ref={el => {if (stepsRef) stepsRef.current[item?.stage] = el;}} onClick={() => nextStage(item?.stage)} style={{ cursor: 'pointer' }} >
                                            <Container
                                                className='d-flex align-items-center justify-content-center'
                                                style={{
                                                    fontSize: '1rem',
                                                    fontWeight: '400',
                                                    lineHeight: '1.5rem',
                                                    letterSpacing: '0.0125rem',
                                                    marginRight: '1rem',
                                                    borderRadius: '100%',
                                                    width: '2rem',
                                                    height: '2rem',
                                                    backgroundColor: hasInputError(item) ? '#C52A3A' : hasWarning(item) ? '#F9C13C' : isCurrentItem(item) ? '#2362A2' : isVisited(item) ? '#6FBD2C' : '#EAEAEA',
                                                    color: isCurrentItem(item) && '#FFF'
                                                }}
                                            >
                                                {hasInputError(item) ? (
                                                    <img src={errorIcon} className='icon-filter--white' alt="state.error" />
                                                ) : hasWarning(item) ? (
                                                    <img src={alertIcon} className='icon-filter--white' alt="alert, wrong format" />
                                                ) : isVisited(item) ?  (
                                                    <img src={iconStageDone} alt="Stage Done" />
                                                ) :  item?.stage}
                                            </Container>
                                            <p style={{ fontSize: '1rem', fontWeight: isCurrentItem(item) ? '700' : '400', lineHeight: '1.5rem', letterSpacing: '0.015rem', color: hasInputError(item) ? '#9E0615' : hasWarning(item) ? '#C78300' : '' }}>
                                                {`${item?.name}`}
                                            </p>
                                        </Col>
                                    </Row>
                                    {
                                        index + 1 !== stagesArr.length
                                    &&
                                <Col className='d-flex align-items-center'>
                                    <Container className='d-flex align-items-center justify-content-center' style={{ minWidth: '2rem', maxWidth: '2rem', height: true    && (item?.name === 'Žadatel' || item?.name === 'Dokumentace') ? '4.25rem' : '2.75rem' }}>
                                        <Container style={{ height: true     && (item?.name === 'Žadatel' || item?.name === 'Dokumentace') ? '2.438rem' : '0.75rem', width: '0px', border: hasInputError(item) ? '#C52A3A solid 1px' : hasWarning(item) ? '#F9C13C solid 1px' : isVisited(item) ? '#6FBD2C solid 1px' : '#B5D9F3 solid 1px', borderColor: hasInputError(item) ? '#C52A3A solid 1px' : hasWarning(item) ? '#F9C13C solid 1px' : isVisited(item) ? '#6FBD2C' : stagesArr.find(stg => Number(stg?.stage) === Number(stepValue))?.error && isCurrentItem(item) ? '#C52A3A' : '#B5D9F3' }}></Container>
                                    </Container>
                                    {
                                        true    
                                        &&
                                        item.moreInfo
                                        &&
                                        <div style={{ fontSize: '.906rem', fontWeight: '400', lineHeight: '1.45rem', letterSpacing: '0.014rem', color: '#686868', marginLeft: '1rem', maxWidth: '248px' }}>
                                            {item.moreInfo && item.moreInfo}
                                        </div>
                                    }
                                </Col>
                                    }
                                </Container>
                            );
                        }))}
                    </Col>
                </Row>
            </Container>
            :
            <Container fluid style={fixedStyle}>
                <Row className='d-flex flex-column' style={{ color: '#15355E' }}>
                    <Col style={{ marginBottom: '1rem', fontSize: '1.21rem', fontWeight: '600', lineHeight: '1.772rem', letterSpacing: '0.01em', color: '#3B3B3B' }} ref={progressRef}>Osnova</Col>
                    {
                        (stagesArr.length > 0 && stagesArr.map((item, index) => {
                            return (
                                <Container key={index + item?.name + item?.stage} >
                                    <Row>
                                        <Col className='d-flex align-items-center' ref={el => {if (stepsRef) stepsRef.current[item?.stage] = el;}} onClick={() => nextStage(item?.stage)} style={{ cursor: 'pointer' }} >
                                            <Container
                                                className='d-flex align-items-center justify-content-center'
                                                style={{
                                                    fontSize: '1rem',
                                                    fontWeight: '400',
                                                    lineHeight: '1.5rem',
                                                    letterSpacing: '0.0125rem',
                                                    marginRight: '1rem',
                                                    borderRadius: '100%',
                                                    width: '2rem',
                                                    height: '2rem',
                                                    backgroundColor: hasInputError(item) ? '#C52A3A' : hasWarning(item) ? '#F9C13C' : isCurrentItem(item) ? '#2362A2' : isVisited(item) ? '#6FBD2C' : '#EAEAEA',
                                                    color: isCurrentItem(item) && '#FFF'
                                                }}
                                            >
                                                {hasInputError(item) ? (
                                                    <img src={errorIcon} className='icon-filter--white' alt="state.error" />
                                                ) : hasWarning(item) ? (
                                                    <img src={alertIcon} className='icon-filter--white' alt="alert, wrong format" />
                                                ) : isVisited(item) ?  (
                                                    <img src={iconStageDone} alt="Stage Done" />
                                                ) :  item?.stage}
                                            </Container>
                                            <p style={{ fontSize: '1rem', fontWeight: isCurrentItem(item) ? '700' : '400', lineHeight: '1.5rem', letterSpacing: '0.015rem', color: hasInputError(item) ? '#9E0615' : hasWarning(item) ? '#C78300' : '' }}>
                                                {`${item?.name}`}
                                            </p>
                                        </Col>
                                    </Row>
                                    {
                                        index + 1 !== stagesArr.length
                                        &&
                                    <Col className='d-flex align-items-center'>
                                        <Container className='d-flex align-items-center justify-content-center' style={{ minWidth: '2rem', maxWidth: '2rem', height: opened && (item?.name === 'Žadatel' || item?.name === 'Dokumentace') ? '4.25rem' : '2.75rem' }}>
                                            <Container 
                                                style={{ 
                                                    height: 
                                                        opened && 
                                                        (item?.name === 'Žadatel' || item?.name === 'Dokumentace') ? 
                                                            '2.438rem' : 
                                                            '0.75rem', 
                                                    width: '0px', border: hasInputError(item) ? 
                                                        '#C52A3A solid 1px' : hasWarning(item) ? 
                                                            '#F9C13C solid 1px' : isVisited(item) ? 
                                                                '#6FBD2C solid 1px' : '#B5D9F3 solid 1px', 
                                                    borderColor: hasInputError(item) ? 
                                                        '#C52A3A solid 1px' : hasWarning(item) ? 
                                                            '#F9C13C solid 1px' : isVisited(item) ?
                                                                '#6FBD2C' : 
                                                                stagesArr.find(stg => Number(stg?.stage) === Number(stepValue))?.error && isCurrentItem(item) ? '#C52A3A' :
                                                                    '#B5D9F3' 
                                                }}
                                            >
                                            </Container>
                                        </Container>
                                        {
                                            opened
                                            &&
                                            item.moreInfo
                                            &&
                                            <div style={{ fontSize: '.906rem', fontWeight: '400', lineHeight: '1.45rem', letterSpacing: '0.014rem', color: '#686868', marginLeft: '1rem', maxWidth: '248px' }}>
                                                {item.moreInfo && item.moreInfo}
                                            </div>
                                        }
                                    </Col>
                                    }
                                </Container>
                            );
                        }))
                    }
                    <Col>
                        <p onClick={() => setOpened(!opened)} style={{ width: '90px', marginTop: '1.5rem', textDecoration: 'underline', color: '#2362A2', fontSize: '1rem', fontWeight: '400', lineHeight: '1.5rem', letterSpacing: '0.0125rem', cursor: 'pointer' }}>{!opened ? 'Rozbalit' : 'Zabalit'}</p>
                    </Col>
                </Row>

            </Container>
    );


};

FormProgress.propTypes = {
    stepsRef: PropTypes.objectOf(Array),
    isMobile: PropTypes.bool,
};

export default FormProgress;

