import {  
    BASIC_INFO, 
    BASIC_INFO_BUSINESS, 
    BASIC_INFO_BUSINESS_APPENDIX, 
    BASIC_INFO_PO, 
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO, 
    PERSON_AUTHORIZED, 
    ATTORNEY_PERSON_AUTHORIZED,
    LOCATION, 
    LOCATION_BUSINESS,
    DELIVERY_LOCATION, 
    CONTACTS, 
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
} from './zadatelStates.js';

let items = [
    {
        stage: 1,
        name: 'Záměr',
        error: false,
        warning: false,
        seen: false,      
    },
    {
        stage: 2,
        name: 'Navrhovatel',
        moreInfo: 'Osobní údaje o navrhovateli a případně o jeho zástupci',
        error: false,
        warning: false,
        seen: false,      
    },
    {
        stage: 3,
        name: 'Přílohy',
        moreInfo: 'Další přílohy formuláře',
        error: false,
        warning: false,
        seen: false,      
    },
    {
        stage: 4,
        name: 'Příjemce',
        error: false,
        warning: false,
        seen: false,      
    },
    {
        stage: 5,
        name: 'Kontrola a podání',
        error: false,
        warning: false,
        seen: false,      
    },
];

const BUILDING_ADDRESS = [
    {
        id: 1,
        label: 'Obec (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-city',
        required: false,
        state: 'city',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'buildingLocation-cityPart',
        required: false,
        state: 'cityPart',
        object: 'buildIntention',
        propertyToUpdate: 'address'
    },
];


export {
    BASIC_INFO,
    BASIC_INFO_BUSINESS, 
    BASIC_INFO_BUSINESS_APPENDIX, 
    BASIC_INFO_PO, 
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO, 
    PERSON_AUTHORIZED, 
    ATTORNEY_PERSON_AUTHORIZED,
    LOCATION,
    LOCATION_BUSINESS,
    DELIVERY_LOCATION,
    CONTACTS,
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
    BUILDING_ADDRESS,
    items,
};
