import { ErrorBoundary } from 'react-error-boundary';
import React, { useEffect, useMemo, useState } from 'react';
import '../css/reusable.css';
import { Container } from 'react-bootstrap';
import { FormFooter, FormHeader, Form14MainSection, FormFinisher, ProcessError } from '../components';
import { useGlobalState } from '../constants/states';
import { isNotUploading, logError, getOngoingUploads } from '../helperFunctions/helpers';
import { items } from '../constants/form14';
import { FORM_14_INTENTION, DOCUMENT_FOURTH, WARRANT, AFFIDAVIT, OWNER_CONSENT, PARTICIPANTS_LIST, OTHER_DOCUMENTS } from '../constants/sharedConstants';
import FormContext from '../formContexts/FormContext';
import useScrollToTop from '../components/CustomHooks/useScrollToTop';

const Form14 = () => {
    const [state, setState] = useGlobalState();
    
    const [ isMobileView, setIsMobileView ] = useState(window.innerWidth < 992);
    const [isFinished, setIsFinished] = useState(false);
    const [id, setId] = useState(null);
    const [uploadError, setUploadError] = useState([]);
    const [isSuccessOrFailSubmission, setIsSuccessOrFailSubmission] = useState(null);
    const [uploadedBytes, setUploadedBytes] = useState(0);
    const [uploadedPrilohyBytes, setUploadedPrilohyBytes] = useState(0);
    const [stagesArr, setStagesArr] = useState(items);
    const [ fetch, setFetch ] = useState(false);
    const [documents, setDocuments] = useState({
        [DOCUMENT_FOURTH]: [],
    });
    const [attachments, setAttachments] = useState({
        [WARRANT]: [],
        [AFFIDAVIT]: [],
        [OWNER_CONSENT]: [],
        [PARTICIPANTS_LIST]: [],
        [OTHER_DOCUMENTS]: [],
    });
    const currentlyUploading = useMemo(() => getOngoingUploads(Object.values(documents || {})?.flat()), [documents]);
    const allUploads = React.useMemo(() => [...[...(Object.values(documents || {}) || [])].flat(), ...[...(Object.values(attachments || {}) || [])].flat()], [documents, attachments]);
    const { stage } = state;
    useScrollToTop(stage);
    const uploadProgressRef = React.useRef({});

    useEffect(() => {
        function handleBeforeUnload (e) {
            if (!isNotUploading(allUploads)) {
                e.preventDefault();
            }
        }

        function handleResize() {
            if(window.innerWidth < 992) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('resize', handleResize);
        };
    }, [allUploads]);

    const setStage = (newStage) => {
        setState({ ...state, stage: newStage });
    };

    useEffect(() => {
        return () => {
            setStage(1);
        };
    }, []);

    const validationFunctionRef = React.useRef();
    const saveFormRef = React.useRef();

    return (
        <ErrorBoundary fallback={<ProcessError idFromBoundary={id} isFromErrorBoundary />} onError={logError}>
            <Container className={`d-flex flex-column align-items-center pt-5 background ${isMobileView ? 'mobile' : ''}`} fluid>
                {isFinished ? <FormFinisher isSuccessOrFailSubmission={isSuccessOrFailSubmission} header={FORM_14_INTENTION.title} id={id} linkRef={'form14'}
                    setStage={setStage} setIsFinished={setIsFinished} /> :
                    <>
                        <FormContext.Provider
                            value={{
                                intention: FORM_14_INTENTION.title,
                                stepValue: stage,
                                uploadError,
                                setUploadError,
                                uploadedBytes,
                                setUploadedBytes,
                                uploadedPrilohyBytes,
                                setUploadedPrilohyBytes,
                                uploadProgressRef,
                                validationRef: validationFunctionRef,
                                setStagesArr,
                                stagesArr,
                                saveFormRef,
                                id,
                                setId,
                                fetch,
                                setFetch,
                            }}
                        >
                            <FormHeader currentlyUploading={currentlyUploading} isMobileView={isMobileView} header={FORM_14_INTENTION} />
                            <Form14MainSection attachments={attachments} setAttachments={setAttachments} documents={documents} setDocuments={setDocuments} isMobileView={isMobileView} stage={stage}   />
                            <FormFooter currentlyUploading={currentlyUploading} setIsSuccessOrFailSubmission={setIsSuccessOrFailSubmission} formNumber={'14'} setIsFinished={setIsFinished} />
                        </FormContext.Provider>
                    </>}
            </Container>
        </ErrorBoundary>
    );
};

export default Form14;