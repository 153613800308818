import React, { useEffect, useState } from 'react';
import '../../css/zadostiDetail.css';
import '../../css/reusable.css';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { GovButton, GovFormInput } from '@gov-design-system-ce/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import authConfig from '../../config/authorization-config';

const DokumentaceDetailHeaderSection = ({item}) => {
    const [showCopySuccess, setShowCopySuccess] = useState(false);
    const { id, token: documentationToken } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location || {};
    const newVersionUrl = id ? `/dokumentace/version/${id}${documentationToken ? `/${documentationToken}` : ''}` : '#';
    const rootUrl = authConfig?.redirectUri ?? '';
    const shareUrl = `${rootUrl?.substring(0, rootUrl.length - 1) ?? ''}${pathname ?? ''}` ?? '';

    useEffect(() => {
        if (showCopySuccess) {
            const timer = setTimeout(() => {
                setShowCopySuccess(false);
            }, 60000);

            return () => clearTimeout(timer);
        }
    }, [showCopySuccess]);

    return (
        <Container fluid className='p-3 my-4 p-md-0 d-flex flex-column align-items-center'>
            <Row className='base-width py-4' style={{borderBottom: 'solid 1px #DDDDDD'}}>
                <Col xs={12} md={6}>
                    <Row className='d-flex flex-column'>
                        <Col className='header__detail-id-big pb-4'>{`Název Záměru${item?.projectName ?? ''}`}</Col>
                        <Col className='header__detail-project pb-4'>{`Číslo Záměru ${item?.projectId ?? ''}`}</Col>
                    </Row>                    
                </Col>            
                <Col xs={12} md={6}>
                    <Row className='d-flex flex-column align-items-end'>
                        <Col xs={12} md={6} className='pb-4 gov-btn-container'>
                            <GovButton
                                variant="primary"
                                type="solid" 
                                size="m"
                                onGov-click={() => navigate(newVersionUrl)}
                            >
                                Vložit novou verzi
                            </GovButton>
                        </Col>
                        <Col xs={12} md={6} className='d-flex flex-column gov-btn-container'>
                            <GovFormInput
                                disabled
                                value={shareUrl?.trim()}
                            />
                            <GovButton
                                type='outlined'
                                variant='primary'
                                size='m'
                                onGov-click={async () => {
                                    try {
                                        if (!shareUrl?.trim()) {
                                            return;
                                        }
                                        await navigator.clipboard.writeText(shareUrl?.trim());
                                        setShowCopySuccess(true);
                                    } catch (err) {
                                        setShowCopySuccess(false);
                                    }
                                }}
                            >
                                Kopírovat odkaz pro sdílení
                            </GovButton>
                            {showCopySuccess && (
                                <Col className='align-self-end'>
                                    <p className='gov-color--success-400 gov-text--s pb-0 pb-lg-2 pe-2 ps-2 ps-lg-4 pt-2 pt-lg-0' style={{textWrap: 'nowrap'}}>Odkaz úspěšně zkopírován do schránky!</p>
                                </Col>
                            )}
                        </Col>
                    </Row>                    
                </Col>   
            </Row>
        </Container>
    );

};

DokumentaceDetailHeaderSection.propTypes = {
    item: PropTypes.any,
};

export default DokumentaceDetailHeaderSection;