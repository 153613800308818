import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { GovButton } from '@gov-design-system-ce/react';
import moment from 'moment';
import cs from 'moment/locale/cs';
import { CardContainer, CardRow, CardBody, CardFooter, CardHeader } from '../Card';

import '../../css/zadosti.css';

moment.locale('cs', cs);

export const BASE_DOCUMENTATION_CARD_TYPE = 'BASE';
export const IN_PROGRESS_DOCUMENTATION_CARD_TYPE = 'IN_PROGRESS';

const formatedDate = (date) => {
    return date ? moment(date).format('LL') : date;
};

const DocumentationCard = ({ item, type = BASE_DOCUMENTATION_CARD_TYPE }) => {
    const location = useLocation();
    const isInProgressType = type === IN_PROGRESS_DOCUMENTATION_CARD_TYPE;

    const title = isInProgressType ? item?.title : item?.projectName;
    const subTitle = isInProgressType ? item?.intentionName : item?.projectId;
    const pID = isInProgressType ? item?.id : item?.filePid;
    const date = isInProgressType ? formatedDate(item?.created) : formatedDate(item?.finishDate);
    const link = isInProgressType ? `/dokumentace/new/${encodeURIComponent(item?.id)}` : `/dokumentace/${encodeURIComponent(item?.projectEszId)}`;

    return (
        <CardContainer>
            <CardHeader>
                <h4 className='p-0 zadosti-title'>{title}</h4>
                <p className='p-0 zadosti-id'>{subTitle}</p>
            </CardHeader>

            <CardBody>
                <CardRow label='PID nejnovější dokumentace' value={pID} />
                <CardRow
                    label='Datum vložení'
                    value={date}
                />
            </CardBody>

            <CardFooter>
                <dir>
                    <Link
                        to={link}
                        state={{ from: location }}
                        className='button-fill-in-container'
                    >
                        <GovButton
                            nativeType='button'
                            variant='primary'
                            expanded={true}
                        >
                            Zobrazit detail
                        </GovButton>
                    </Link>
                </dir>
            </CardFooter>
        </CardContainer>
    );
};

DocumentationCard.propTypes = {
    item: PropTypes.oneOfType([
        PropTypes.shape({
            filePid: PropTypes.string,
            projectId: PropTypes.string,
            projectEszId: PropTypes.string,
            projectName: PropTypes.string,
            versionId: PropTypes.string,
            versionNumber: PropTypes.number,
            finishDate: PropTypes.string,
        }),
        PropTypes.shape({
            id: PropTypes.string,
            sendStatus: PropTypes.string,
            sendStartDate: PropTypes.string,
            title: PropTypes.string,
            created: PropTypes.string,
            updated: PropTypes.string,
            intentionName: PropTypes.string,
            intentionNumber: PropTypes.string,
            attachments: PropTypes.array,
            documentations: PropTypes.array,
            bppFile: PropTypes.object,
            esslDocumentId: PropTypes.string,
            authorizedPersonSharedLink: PropTypes.string,
            shareForm: PropTypes.string,
            isCopied: PropTypes.bool,
            isDocumentation: PropTypes.bool,
        }),
    ]),
    type: PropTypes.oneOf([BASE_DOCUMENTATION_CARD_TYPE, IN_PROGRESS_DOCUMENTATION_CARD_TYPE]),
};

export default DocumentationCard;
