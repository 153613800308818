
import React, { useContext, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AuthContext } from 'react-oauth2-code-pkce';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { GovButton, GovIcon } from '@gov-design-system-ce/react';
import { uploadedFile } from '../../assets';
import { PATHS } from '../../constants/sharedConstants';
import { ZadostiDetailPodani } from '../index.js';
import { makeCopyRequest } from '../../helperFunctions/helpers';
import '../../css/zadosti.css';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config.js';
import { isRequestOK } from '../../apiCalls/componentsApiCalls.js';

const PDF_TYPE = 'pdf';
const DOCUMENTATION_TYPE = 'documentation';
const ATTACHEMENTS_TYPE = 'attachements';

const ZadostiDetailMainSection = ({zadostiDetail}) => {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [ dropDownlVisible, setDropDownVisible ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ focused, setFocused ] = useState(false);
    const attachments = zadostiDetail?.detail?.attachments || [];
    const APPLICATION_PID = zadostiDetail?.detail?.applicationPid;
    const BPP_UID = zadostiDetail?.detail?.bppFile?.uid;
    const BPP_FILENAME = zadostiDetail?.detail?.bppFile?.fileName;
    const PDF_UID = zadostiDetail?.detail?.pdfFile?.uid;
    const PDF_FILENAME = zadostiDetail?.detail?.pdfFile?.fileName;
    const attachmentsToMap = attachments.filter(att => (!(att.fileName.startsWith('build-application')) && !(att.fileName.startsWith('build-intention'))));
    const attachmentToPreviewLength = attachmentsToMap.length;
    let timeOut = null;
    const [isDownloadPending, setIsDownloadPending] = useState({});
    const isDownloadPDFPending = !!isDownloadPending?.[PDF_TYPE];
    const isDownloadDocumentationsPending = !!isDownloadPending?.[DOCUMENTATION_TYPE];
    const isDownloadAttachementsPending = !!isDownloadPending?.[ATTACHEMENTS_TYPE];
    const {id} = useParams();
    
    const showModal = () => {
        if (attachmentToPreviewLength)
        {
            setDropDownVisible(!dropDownlVisible);
            setFocused(!focused);
        }
        else return;
    };

    const handleBlur = () => {
        if(dropDownlVisible)
        {
            timeOut = setTimeout(() => {
                setDropDownVisible(false);
                setFocused(false);
            }, 100);
        }
    };

    const handleFocus = () => {
        clearTimeout(timeOut);
    };


    const downloadPdf = async (uid, isDocumentation = false, fileName = null, type) => {
        if (!uid) {
            return;
        }
        try {
            const source = axios.CancelToken.source();

            setIsDownloadPending((prevState) => ({
                ...prevState,
                [type]: true,
            }));
            const response = await axios.get(
                urlJoin(apiConfig.getAndQueryBaseUrl, `requests/${APPLICATION_PID}/attachment/${uid}`),
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                    params: {
                        'fileName': fileName ?? (isDocumentation ? BPP_FILENAME : PDF_FILENAME),
                    },
                });

            if (isRequestOK(response?.status)) {
                if(isDocumentation) {
                    const blob = new Blob([response.data], { type: 'application/zip' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'dokumentace.zip';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                } else {
                    window.open(URL.createObjectURL(response.data));
                }
                setIsDownloadPending((prevState) => ({
                    ...prevState,
                    [type]: false,
                }));
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
            setIsDownloadPending((prevState) => ({
                ...prevState,
                [type]: false,
            }));
        }
    };
    
    const handleClick = async (id) => {
        setIsLoading(true);
        try {
            const { path, applicationId } = await makeCopyRequest(id, token, PATHS);

            if(!path || !applicationId) {
                return null;
            }

            return navigate(`/zadost/${path.target}/${applicationId}`, { state: { isCopied: true } });
        } catch (e) {
            // TODO error handling
            setIsLoading(false);
            console.log(e);
        }
    };

    return (
        <Container className='p-3 pb-5 mb-4 p-md-0 d-flex flex-column align-items-center' fluid>
            <Row className='base-width'>
                <Col lg={4} className='d-flex flex-column align-items-xl-start mb-3 mb-xl-0 zadosti-detail-info'>
                    <p className='d-flex p-0 mb-3 rizeni-detail-title'>Podání</p>
                    <ZadostiDetailPodani item={zadostiDetail.detail}/>

                    <GovButton
                        nativeType='button'
                        className='w-100 mb-3'
                        iconLeft='basic/file-earmark-arrow-down'
                        type='outlined'
                        variant='primary'
                        expanded={true}
                        disabled={isDownloadPDFPending}
                        aria-disabled={isDownloadPDFPending}
                        loading={isDownloadPDFPending}
                        onGov-click={() => downloadPdf(PDF_UID, false, null, PDF_TYPE)}
                    >
                        Stáhnout žádost v PDF
                    </GovButton>

                    {!!BPP_UID && (
                        <GovButton
                            nativeType='button'
                            className='w-100 mb-3'
                            iconLeft='basic/file-earmark-arrow-down'
                            type='outlined'
                            variant='primary'
                            expanded={true}
                            disabled={isDownloadDocumentationsPending}
                            aria-disabled={isDownloadDocumentationsPending}
                            loading={isDownloadDocumentationsPending}
                            onGov-click={() => downloadPdf(BPP_UID, true, null, DOCUMENTATION_TYPE)}
                        >
                            Stáhnout dokumentaci
                        </GovButton>
                    )}

                    <button
                        className={`attachment--dropdown-container mb-3 zadosti-detail-button--dropdown id-input2 dropdown-input-zadosti ${isDownloadAttachementsPending ? 'native-button--disabled' : ''}`}
                        {...(focused ? {focused: 'true'} : {focused: 'false'})}
                        style={{height: dropDownlVisible && 'auto'}}
                        onClick={showModal}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        disabled={isDownloadAttachementsPending}
                        aria-disabled={isDownloadAttachementsPending}
                        loading={isDownloadAttachementsPending}
                    >
                        <p className='dropdown-placeholder'>
                            {isDownloadAttachementsPending && <GovIcon className='icon--medium gov-spin-animation mx-1' name='loader' size='xs' />}
                            {attachmentToPreviewLength ? 'Stáhnout přílohy' : 'Přílohy nejsou k dispozici'}
                        </p>
                        {
                            dropDownlVisible
                            &&
                            <>
                                {
                                    attachmentToPreviewLength &&
                                        <>
                                            {
                                                attachmentsToMap.map((item, index) => {
                                                    return (
                                                        <div key={index} className='d-flex align-items-center dropdown--items-container' onClick={() => downloadPdf(item.uid, false, item.fileName, ATTACHEMENTS_TYPE)}>
                                                            <img src={uploadedFile} alt={item.fileName} />
                                                            <p>{item.fileName}</p>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </>
                                }
                            </>
                        }
                    </button>
                    {/* <button type='button' className='p-1 zadosti-detail-button'><img src={file} alt='file' className='pe-2' />Stáhnout potvrzení</button> */}

                    <GovButton
                        nativeType='button'
                        className='w-100 mb-3'
                        iconLeft='basic/copy'
                        type='outlined'
                        variant='primary'
                        expanded={true}
                        disabled={isLoading}
                        aria-disabled={isLoading}
                        loading={isLoading}
                        onGov-click={() => handleClick(id)}
                    >
                        Vytvořit kopii
                    </GovButton>
                    {/* </Row> */}
                </Col>
                {/* <Col lg={8} className='d-flex flex-column align-items-xl-start w-99 ps-lg-4'>
                    <p className='d-flex p-0 mb-3 rizeni-detail-title'><img src={alarm} alt='alarm' className='pe-2' />Historie</p>
                    <ZadostiDetailHistory history={zadostiDetail?.detail?.history} />
                </Col> */}
            </Row>
        </Container>
    );
};

ZadostiDetailMainSection.propTypes = {
    zadostiDetail: PropTypes.shape({
        detail: PropTypes.shape({
            applicationPid: PropTypes.string,
            pid: PropTypes.string,
            projectName: PropTypes.string,
            projectId: PropTypes.string,
            processId: PropTypes.string,
            state: PropTypes.string,
            date: PropTypes.string,
            mode: PropTypes.string,
            history: PropTypes.array,
            attachments: PropTypes.array,
            bppFile: PropTypes.object,
            pdfFile: PropTypes.object,
            icsId:PropTypes.string
        })
    }),
};

export default ZadostiDetailMainSection;
