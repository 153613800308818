import React from 'react';
import { Col } from 'react-bootstrap';
import { iconCardText, iconGrid } from '../assets';
import PropTypes from 'prop-types';

const ViewSwticher = ({isActive, setIsActive}) => {
    const toggleActiveSwitch = () => {
        setIsActive(prevState => (prevState === 'table' ? 'block' : 'table'));
    };

    const tableSwitchStyle = {
        backgroundColor: isActive === 'table' ? '#2362A2' : '#FFFFFF',
        cursor: 'pointer',
    };

    const tableIconStyle = {
        filter: isActive === 'table' ? 'brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7468%) hue-rotate(282deg) brightness(108%) contrast(127%)'
            : 'brightness(0) saturate(100%) invert(33%) sepia(34%) saturate(973%) hue-rotate(169deg) brightness(101%) contrast(104%)',
        cursor: 'pointer',
    };

    const blockSwitchStyle = {
        backgroundColor: isActive === 'block' ? '#2362A2' : '#FFFFFF',
        cursor: 'pointer',
    };

    const blockIconStyle = {
        filter: isActive === 'block' ? 'brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7468%) hue-rotate(282deg) brightness(108%) contrast(127%)'
            : 'brightness(0) saturate(100%) invert(33%) sepia(34%) saturate(973%) hue-rotate(169deg) brightness(101%) contrast(104%)',
        cursor: 'pointer',
    };

    return (
        <Col className='d-none d-md-flex flex-column align-items-end justify-content-start ms-auto my-2' md={{span: 2, order: 3}} lg={{span: 2, order: 4}}>
            <div className='d-flex flex-column align-items-center'>
                <p className='mb-1 form__label'>Zobrazení</p>
                <div className='d-flex form__switch'>
                    <div style={blockSwitchStyle} onClick={toggleActiveSwitch} className='form__switch-right'>
                        <img style={blockIconStyle} width={'14px'} src={iconGrid} />
                    </div>
                    <div style={tableSwitchStyle} onClick={toggleActiveSwitch} className='form__switch-left'>
                        <img style={tableIconStyle} width={'14px'} src={iconCardText} />
                    </div>
                </div>
            </div>
        </Col>
    );
};

ViewSwticher.propTypes = {
    isActive: PropTypes.string.isRequired,
    setIsActive: PropTypes.func.isRequired,
};

export default ViewSwticher;