/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import '../css/homePage.css';
import '../css/headerSection.css';
import '../css/reusable.css';
import { AuthContext } from 'react-oauth2-code-pkce';
import { GovButton } from '@gov-design-system-ce/react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { iconZadost, iconZamer, iconZamerWhite, bckgHomePage, novaZadostBackground, mojeZadostiBackground, mojeRizeniBackground, mojeZameryBackground, mojeStavbyBackground, dokumentaceBackground } from '../assets';
import LandingPage from '../screens/LandingPage';

const HeaderSection = ({ headerText, page, mainHeader }) => {
    const { tokenData } = React.useContext(AuthContext);
    const img = page === undefined 
        ? bckgHomePage 
        : page === 'zadosti/new' 
            ? novaZadostBackground 
            : page === 'zadosti' 
                ? mojeZadostiBackground 
                : page === 'rizeni' 
                    ? mojeRizeniBackground 
                    : page === 'zamery'
                        ? mojeZameryBackground
                        : page === 'stavby'
                            ? mojeStavbyBackground
                            : page === 'dokumentace'
                                ? dokumentaceBackground
                                : LandingPage;
    const zamerIcon = page === 'zamery' ? iconZamerWhite : iconZamer;

    return (
        <Container fluid style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center 20%' }} className='welcome-section d-flex justify-content-center align-items-center d-md-flex flex-md-column p-3 p-md-5'>
            <Row className='d-flex flex-column justify-content-center flex-md-row base-width welcome-section-content'>
                <Col xs={12} md={6} className='pb-5 d-flex flex-column justify-content-center'>
                    {mainHeader ? <p className='salutation'>{mainHeader}</p> :
                        <p className='salutation'>Vítejte, {tokenData?.salutation || tokenData?.firstName}.</p>
                    }
                    <p className='welcome-to-portal' style={{ maxWidth: '478px' }}>
                        {headerText ? headerText : 'Vítejte na portálu stavební správy. Zde můžete digitálně podat novou žádost a sledovat její stav.'}
                    </p>
                </Col>
                <Col xs={12} md={6} className='d-flex flex-column align-items-center align-items-md-end'>
                    {(!page || page === 'zadosti' || page === 'dokumentace')
                        &&
                        <GovButton className='button' type='solid' variant={'primary'} size='xl' >
                            {(page === 'zadosti' || !page) && (
                                <Link to='/zadosti/new'>
                                    <Container fluid className='d-flex justify-content-center align-items-center button-create'>
                                        <img src={iconZadost} alt='Vytvořit žádost' className='header-link-img' />
                                        <span className='header-link-text'>Nová žádost</span>
                                    </Container>
                                </Link>
                            )}

                            {page === 'dokumentace' && (
                                <Link to='/dokumentace/new'>
                                    <Container fluid className='d-flex justify-content-center align-items-center button-create'>
                                        <img src={iconZadost} alt='Vytvořit novou dokumentaci' className='header-link-img' />
                                        <span className='header-link-text'>Nová dokumentace</span>
                                    </Container>
                                </Link>
                            )}
                        </GovButton>
                    }
                    {(page === 'zamery')
                        &&
                        // <GovButton className='button' variant={'primary'} size='xl' >
                        //     <Link>
                        //         <Container fluid className='d-flex justify-content-center align-items-center button-create'>
                        //             <img className='header-link-img' src={zamerIcon} alt='ikona vytvořit záměr' />
                        //             <span className='header-link-text'>Nový záměr</span>
                        //         </Container>
                        //     </Link>
                        // </GovButton>
                        ''
                    }
                </Col>
            </Row>
        </Container>
    );
};

HeaderSection.propTypes = {
    headerText: PropTypes.string,
    page: PropTypes.string,
    mainHeader: PropTypes.string,
};

export default HeaderSection;

