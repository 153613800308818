import React, { useState, useContext, useEffect, useRef, useImperativeHandle, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadatelComponent.css';
import PropTypes from 'prop-types';
import {
    ApplicantMoreModal,
    FormInput,
    PowerOfAttorneyInput,
    ApplicantMoreControls,
    FindAndProcessIco,
    PowerOfApplicantInputRepre,
    PersonTypeSelector
} from '../index';
import { iconCardText, iconGrid } from '../../assets';
import {
    BASIC_INFO,
    BASIC_INFO_BUSINESS, 
    BASIC_INFO_BUSINESS_APPENDIX, 
    BASIC_INFO_PO,
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO,
    PERSON_AUTHORIZED, 
    ATTORNEY_PERSON_AUTHORIZED, 
    CONTACTS,
    CONTACTS_PO_FORM_15,
    DELIVERY_LOCATION, 
    LOCATION,
    LOCATION_BUSINESS,
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
} from '../../constants/form15.js';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { shouldNotSave, hasPowerOfAttorneyError, handlePOAFiles, handleZadatelPeopleFiles, handlePeople, canResetAttorney } from '../../helperFunctions/helpers';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import {
    PLNA_MOC_FIRST,
    PLNA_MOC_SECOND,
    APPLICANT_MODEL,
    COUNTRIES,
    PLNA_MOC_FIFTH,
    PLNA_MOC_FOURTH,
    PLNA_MOC_THIRD
} from '../../constants/sharedConstants.js';
import { jwtDecode } from 'jwt-decode';
import { handleUpdate } from '../../helperFunctions/modalHelpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import {REPRE_ATTORNEY_PERSON_AUTHORIZED, REPRE_PERSON_AUTHORIZED} from '../../constants/zadatelStates.js';
import PowerOfAttorneyInputRepre from '../Forms/PowerOfAttorneyInputRepre.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { isRequestOK } from '../../apiCalls/componentsApiCalls.js';

const ZadatelComponent15 = ({ 
    urlPath,
    foundApplicantIndex,
    setFoundApplicantIndex,
    stage,
    applicantArr,
    setApplicantArr
}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [ focused, setFocused ] = useState({
        idPowerOfAttorneyExisting: false,
        nationality: false,
        nationality2: false,
        icoNumApp: false,
        icoNumAtt: false,
    });

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: '',
            formType: '', 
            applicantMe: true,
            applicantAttorney: false,
            applicantMore: false,
            applicant: APPLICANT_MODEL,
            powerOfAttorney: null,
            attorney: APPLICANT_MODEL,
            applicants: [],  
        }
    });
    
    const form = request?.form;
    const decodedToken = jwtDecode(token);
    const [currentApplicant, setCurrentApplicant] = useState(APPLICANT_MODEL);   
    
    const prevRequest = usePrevious(request);
    const attorneyAttachmentRef = useRef(null);
    const [zadatelAttachments, setZadatelAttachments] = useState({
        [PLNA_MOC_FIRST]: [],
        [PLNA_MOC_SECOND]: [],
        [PLNA_MOC_THIRD]: [],
        [PLNA_MOC_FOURTH]: [],
        [PLNA_MOC_FIFTH]: [],
    });
    const allZadatelAttachments = React.useMemo(() => Object.values(zadatelAttachments || {})?.flat(), [zadatelAttachments]);
    const firstRender = useFirstRender();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    // powerOfAttorneyError is only for plnaMocFirst, for other types another check is required
    const powerOfAttorneyError = React.useMemo(() => hasPowerOfAttorneyError(request, zadatelAttachments), [request, zadatelAttachments]);
    const powerOfApplicantInputRepreError = React.useMemo(() => hasPowerOfAttorneyError(request, zadatelAttachments, 'applicant'), [request, zadatelAttachments]);
    const powerOfAttorneyInputRepreError = React.useMemo(() => hasPowerOfAttorneyError(request, zadatelAttachments, 'representative'), [request, zadatelAttachments]);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if (isRequestOK(response?.status)) {
                    const data = response?.data;
                    const loadedData = data?.buildApplicationForms?.[0];

                    if (loadedData) {
                        handlePeople(loadedData, setRequest, decodedToken);
                        handleZadatelPeopleFiles(loadedData, setZadatelAttachments, setApplicantArr);   
                    }
                    setLoading(false);
                }
                else {
                    console.log('ID NOT YET IN DB');
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();

        if (canResetAttorney(form)) {
            const updatedRequest = {...request, form: {...form, attorney: {...APPLICANT_MODEL, nationality: ''}} };
            await formSave(updatedRequest, token, urlPath, source);
            return;
        }

        formSave(request, token, urlPath, source);
    };

    const handleFileInput = (files, category) => {
        handlePOAFiles(files, category, token, setZadatelAttachments, currentApplicant);
    };

    useEffect(() => {
        setRequest(state => ({ ...state, form: { ...state.form, applicants: [...applicantArr] } }));
    }, [applicantArr]);

    useEffect(() => {
        if (foundApplicantIndex === null || stage !== 2) {
            return;
        }

        if (foundApplicantIndex === -1) {
            setTimeout(() => {
                window.scrollTo({
                    top: Number(window.scrollY || document.documentElement.scrollTop || 0) + Number(attorneyAttachmentRef?.current?.getBoundingClientRect()?.top || 0) - 150,  
                    behavior: 'instant'
                });
            }, 150);
            setFoundApplicantIndex(null);
            return;
        }

        const foundApplicant = applicantArr[foundApplicantIndex];
        if (!foundApplicant?.id) {
            return;
        }

        setTimeout(() => {
            handleUpdate(foundApplicant.id, applicantArr, setCurrentApplicant, setShow);
            setFoundApplicantIndex(null);
        }, 250);

    }, [foundApplicantIndex, stage]);

    useEffect(() => {
        if (!form.id) {
            return;
        }
        // avoid multiple save for same object
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (!request.form.applicantMore)
            {
                if(request.form.applicant.isFO)
                {
                    if (!form?.applicant || ['names', 'lastName', 'dateOfBirth'].some(key => 
                        !form.applicant[key] || form.applicant[key].trim() === '')) {
                        err = true;
                    } else if (!form?.applicant?.address || ['city', 'zip'].some(key => 
                        !form.applicant.address[key] || form.applicant.address[key].trim() === '')) {
                        err = true;
                    } else if (!form?.applicant?.shippingSameAsPr &&
                        (!form?.applicant?.shippingAddress || ['city', 'zip'].some(key => 
                            !form.applicant.shippingAddress[key] || form.applicant.shippingAddress[key].trim() === ''))) {
                        err = true;
                    }
                }
                else if (request.form.applicant.isFOBusiness)
                {
                    if (!form?.applicant || ['ico', 'names', 'lastName', 'dateOfBirth'].some(key => 
                        !form.applicant[key] || form.applicant[key].trim() === ''))
                    {
                        err = true;
                    }
                    else if (!form?.applicant?.address || ['city', 'zip'].some(key => 
                        !form.applicant.address[key] || form.applicant.address[key].trim() === ''))
                    {
                        err = true;
                    }
                    else if (!form?.applicant?.shippingSameAsPr &&
                        (!form?.applicant?.shippingAddress || ['city', 'zip'].some(key => 
                            !form.applicant.shippingAddress[key] || form.applicant.shippingAddress[key].trim() === '')))
                    {
                        err = true;
                    }
                }
                else if (request.form.applicant.isPO)
                {
                    if (!form?.applicant || ['ico', 'poName', 'names', 'lastName', 'dateOfBirth', 'mandate', 'mandateFrom'].some(key => 
                        !form.applicant[key] || form.applicant[key].trim() === '')) {
                        err = true;
                    } else if (!form?.applicant?.address || ['city', 'zip'].some(key => 
                        !form.applicant.address[key] || form.applicant.address[key].trim() === '')) {
                        err = true;
                    }
                }
            }
            
            if (!request.form.applicantMe)
            {
                if (request.form.attorney.isFO) {
                    if (!form?.attorney || ['names', 'lastName', 'dateOfBirth'].some(key => 
                        !form.attorney[key] || form.attorney[key].trim() === '')) {
                        err = true;
                    } else if (!form?.attorney?.address || ['city', 'zip'].some(key => 
                        !form.attorney.address[key] || form.attorney.address[key].trim() === '')) {
                        err = true;
                    } else if (!form?.attorney?.shippingSameAsPr &&
                        (!form?.attorney?.shippingAddress || ['city', 'zip'].some(key => 
                            !form.attorney.shippingAddress[key] || form.attorney.shippingAddress[key].trim() === ''))) {
                        err = true;
                    }
                } else if (request.form.attorney.isFOBusiness) {
                    if (!form?.attorney || ['ico', 'names', 'lastName', 'dateOfBirth'].some(key => 
                        !form.attorney[key] || form.attorney[key].trim() === '')) {
                        err = true;
                    } else if (!form?.attorney?.address || ['city', 'zip'].some(key => 
                        !form.attorney.address[key] || form.attorney.address[key].trim() === '')) {
                        err = true;
                    } else if (!form?.attorney?.shippingSameAsPr &&
                        (!form?.attorney?.shippingAddress || ['city', 'zip'].some(key => 
                            !form.attorney.shippingAddress[key] || form.attorney.shippingAddress[key].trim() === ''))) {
                        err = true;
                    }
                } else if (request.form.attorney.isPO) {
                    if (!form?.attorney || ['ico', 'poName', 'names', 'lastName', 'dateOfBirth', 'mandate', 'mandateFrom'].some(key => 
                        !form.attorney[key] || form.attorney[key].trim() === ''))
                    {
                        err = true;
                    }
                    else if (!form?.attorney?.address || ['city', 'zip'].some(key => 
                        !form.attorney.address[key] || form.attorney.address[key].trim() === ''))
                    {
                        err = true;
                    }
                }
            } 

            if (dateValidation(form.applicant.dateOfBirth) || dateValidation(form.attorney.dateOfBirth)) {
                err = true;
            }

            if (powerOfAttorneyError || powerOfApplicantInputRepreError || powerOfAttorneyInputRepreError) {
                err = true; 
            }

            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
            const telPattern = /^\+?(?:\(\d+\))?[\d ()-]{9,}$/;
            const icoPattern = /^[0-9]{8}$/;

            if (form?.applicant?.address?.zip)
            {
                const cleanedValue = form?.applicant?.address?.zip?.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }
            if (form?.attorney?.address?.zip)
            {
                const cleanedValue = form?.attorney?.address?.zip?.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }
            if (form?.applicant?.email)
            {
                warning = !emailPattern.test(form?.applicant?.email);
            }
            if (form?.attorney?.email)
            {
                warning = !emailPattern.test(form?.attorney?.email);
            }
            if (form?.applicant?.phone )
            {
                const cleanedValue = form?.applicant?.phone?.replace(/\s+/g, '');
                warning = (!telPattern.test(cleanedValue) || cleanedValue.length < 9);
            }
            if (form?.attorney?.phone)
            {
                const cleanedValue = form?.attorney?.phone?.replace(/\s+/g, '');
                warning = (!telPattern.test(cleanedValue) || cleanedValue.length < 9);
            }
            if (form?.applicant?.ico)
            {   
                warning = !icoPattern.test(form?.applicant?.ico?.trim().replace(/\s+/g, ''));
            }
            if (form?.attorney?.ico)
            {
                warning = !icoPattern.test(form?.attorney?.ico?.trim().replace(/\s+/g, ''));
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));
    
            return !err;
        }
    }));

    return (
        <>
            {loading ? <div>Loading...</div> :
                <Container fluid>
                    {/* TITLE */}
                    <Row className='row-wrapper'>
                        <h2 className='p-0 mb-2 main-title'>{`${stepValue}. Žadatel`}</h2>
                        <p className='p-0 zadatel-subtitle'>Vlastník stavby nebo pozemku, na kterém se nachází odstraňované terénní úpravy</p>
                    </Row>

                    {/* ŽADATEL */}
                    <PersonTypeSelector
                        request={request} 
                        setRequest={setRequest} 
                        decodedToken={decodedToken}
                        keyToUpdate={'main'}
                        setZadatelAttachments={setZadatelAttachments}
                        setCurrentApplicant={setCurrentApplicant}
                        setApplicantArr={setApplicantArr}
                    />

                    <h3 className='p-0 id-title'>{!request.form.applicantMore ? 'Identifikační údaje žadatele' : 'Identifikační údaje žadatelů'}</h3>

                    {/* IDENTIFIKAČNÍ ÚDAJE ŽADATELE */}
                    {!request.form.applicantMore &&
                    <PersonTypeSelector 
                        request={request} 
                        setRequest={setRequest} 
                        decodedToken={decodedToken}
                        keyToUpdate={'applicant'}
                        setZadatelAttachments={setZadatelAttachments}
                    />}


                    {/* ZÁKLADNÍ ÚDAJE */}
                    {!request.form.applicantMore && <Row className='row-wrapper'>
                        <form className='p-0'>
                            {
                                request.form.applicant.isFO &&
                                BASIC_INFO.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                            {
                                request.form.applicant.isFOBusiness &&
                                BASIC_INFO_BUSINESS.map(item => {
                                    if (item?.state === 'ico') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco info={item.info} updateProp={'applicant'} focusKey={'icoNumApp'} handleFocused={handleFocused} focused={focused} request={request} setRequest={setRequest} />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                            }
                            {
                                request.form.applicant.isPO &&
                                BASIC_INFO_PO.map(item =>  {
                                    if (item?.state === 'ico') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco info={item.info} updateProp={'applicant'} focusKey={'icoNumApp'} handleFocused={handleFocused} focused={focused} request={request} setRequest={setRequest} />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                            }
                            {!request.form.applicant.isPO && <label className='d-flex flex-column label' style={{ marginTop: '1.5rem', color: (!form?.applicant?.nationality?.trim() && (error || focused.nationality))  ? '#C52A3A' : '' }}>
                                Státní příslušnost
                                <Container fluid>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <select type='select'
                                                value={form?.applicant?.nationality ?? 'CZE'}
                                                required
                                                style={{ border: error && !form?.applicant?.nationality?.trim() && 'solid 1px #C52A3A' }}
                                                {...(focused.nationality && { focused: 'true' })}
                                                onChange={(e) => {
                                                    setRequest(state => ({ ...state, form: { ...state.form, applicant: { ...state.form.applicant, nationality: e.target.value } } }));
                                                }}
                                                onBlur={() => {
                                                    handleFocused('nationality');
                                                }}
                                                className='id-input2 dropdown-input'
                                            >
                                                <option value={''}>Vyberte</option>
                                                {COUNTRIES.map((country) => (
                                                    <option key={country.value} value={country.value}>{country.label}</option>
                                                ))}     
                                            </select>
                                        </Col>
                                    </Row>
                                </Container>
                            </label>}
                            {
                                request.form.applicant.isFOBusiness &&
                                BASIC_INFO_BUSINESS_APPENDIX.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* TRVALÝ POBYT / MÍSTO PODNIKÁNÍ */}
                    {!request.form.applicantMore && 
                    <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>{request.form.applicant.isFO ? 'Trvalý pobyt' : request.form.applicant.isFOBusiness ? 'Místo podnikání' : 'Sídlo'}</h3>
                        <form className='p-0'>
                            { 
                                (request.form.applicant.isPO || request.form.applicant.isFO) &&
                                LOCATION.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                            { 
                                request.form.applicant.isFOBusiness &&
                                LOCATION_BUSINESS.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* DORUČOVACÍ ADRESA STEJNÁ */}
                    {(!request.form.applicantMore && !request.form.applicant.isPO) && (<Row className='row-wrapper'>
                        <form className='p-0'>
                            <label className='id-checkbox-label'>
                                <input type='checkbox' className='id-checkbox-input' checked={request.form.applicant.shippingSameAsPr}
                                    onChange={(e) => {  setRequest(state => ({ ...state, form: { ...state.form, applicant: { ...state.form.applicant, shippingSameAsPr: e.target.checked } } })); }} 
                                />
                                Doručovací adresa je stejná jako adresa {request.form.applicant.isFO ? 'trvalého pobytu' : 'místa podnikání'}
                            </label>
                        </form>
                    </Row>)}

                    {/* DORUČOVACÍ ADRESA */}
                    {(!request.form.applicantMore && !request.form.applicant.shippingSameAsPr && !request.form.applicant.isPO) && (<Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>Doručovací adresa</h3>
                        <form className='p-0'>
                            {
                                DELIVERY_LOCATION.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>)}

                    {/* KONTAKTNÍ ÚDAJE */}
                    {!request.form.applicantMore && <Row className='row-wrapper'>
                        <form className='p-0'>
                            {(request.form.applicant.isFO || request.form.applicant.isFOBusiness) &&
                                CONTACTS.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                            {request.form.applicant.isPO &&
                                CONTACTS_PO_FORM_15.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {(request.form.applicant.isFOBusiness) && (<Row className='row-wrapper'>
                        <form className='p-0'>
                            <label className='id-checkbox-label'>
                                <input type='checkbox' className='id-checkbox-input' checked={request.form.applicant.includeRepresentative}
                                    onChange={(e) => {  setRequest(state => ({ ...state, form: { ...state.form, applicant: { ...state.form.applicant, includeRepresentative: e.target.checked } } })); }}
                                />
                                Zastoupena jinou osobou
                            </label>
                        </form>
                    </Row>)}

                    {/* POVĚŘENÁ OSOBA */}

                    {request.form.applicant.isPO && !request.form.applicantMore && <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>Osoba oprávněná jednat jménem právnické osoby</h3>
                        <form className='p-0'>
                            {
                                PERSON_AUTHORIZED.filter(item => !(item.marker && item.marker === 'FOP')).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}
                    {((request.form.applicant.isFOBusiness && request.form.applicant.includeRepresentative)) && !request.form.applicantMore && <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>Osoba zastupující fyzickou osobu podnikající</h3>
                        <form className='p-0'>
                            {
                                REPRE_PERSON_AUTHORIZED.filter(item => !(item.marker && item.marker === 'PO')).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* PLNÁ MOC PRO FOP*/}
                    {((request.form.applicant.isFOBusiness && request.form.applicant.includeRepresentative)||(request.form.applicant.isPO)) && <Row className='row-wrapper' ref={attorneyAttachmentRef}>
                        <PowerOfApplicantInputRepre
                            pravnickaOsoba={request.form.applicant.isPO}
                            form={form}
                            setRequest={setRequest}
                            setZadatelAttachments={setZadatelAttachments}
                            handleFocused={handleFocused}
                            powerOfAttorneyError={powerOfApplicantInputRepreError}
                            handleFileInput={handleFileInput}
                            zadatelAttachments={zadatelAttachments}
                            focused={focused}
                        />
                    </Row>}

                    {/* PLNÁ MOC */}
                    {request.form.applicantAttorney && <Row className='row-wrapper' ref={attorneyAttachmentRef}>
                        <PowerOfAttorneyInput
                            form={form}
                            setRequest={setRequest}
                            setZadatelAttachments={setZadatelAttachments}
                            handleFocused={handleFocused}
                            powerOfAttorneyError={powerOfAttorneyError}
                            handleFileInput={handleFileInput}
                            zadatelAttachments={zadatelAttachments}
                            focused={focused}
                            zakladnyDoklad={true}
                        />
                    </Row>}

                    {request.form.applicantMore && 
                    <ApplicantMoreControls
                        applicantArr={applicantArr}
                        iconCardText={iconCardText}
                        iconGrid={iconGrid}
                        setCurrentApplicant={setCurrentApplicant}
                        setZadatelAttachments={setZadatelAttachments}
                        setShow={setShow}
                        setApplicantArr={setApplicantArr}
                        powerOfAttorneyError={powerOfAttorneyError}
                    />}

                    {!request.form.applicantMe && <h3 className='p-0 id-title'>Zástupce žadatele</h3>}

                    {/* ZÁSTUPCE ŽADATELE */}
                    {!request.form.applicantMe &&
                    <PersonTypeSelector 
                        request={request} 
                        setRequest={setRequest} 
                        decodedToken={decodedToken}
                        keyToUpdate={'attorney'}
                        setZadatelAttachments={setZadatelAttachments}
                    />}

                    {/* ZÁKLADNÍ ÚDAJE ZÁSTUPCE */}
                    {!request.form.applicantMe && <Row className='row-wrapper'>
                        <form className='p-0'>
                            {
                                request.form.attorney.isFO &&
                                ATTORNEY_BASIC_INFO.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                            {
                                request.form.attorney.isFOBusiness &&
                                ATTORNEY_BASIC_INFO_BUSINESS.map(item => {
                                    if (item?.state === 'ico') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco info={item.info} updateProp={'attorney'} focusKey={'icoNumAtt'} handleFocused={handleFocused} focused={focused} request={request} setRequest={setRequest}  />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                            }
                            {
                                request.form.attorney.isPO &&
                                ATTORNEY_BASIC_INFO_PO.map(item => {
                                    if (item?.state === 'ico') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco info={item.info} updateProp={'attorney'} focusKey={'icoNumAtt'} handleFocused={handleFocused} focused={focused} request={request} setRequest={setRequest} />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                            }
                            {!request.form.attorney.isPO && <label className='d-flex flex-column label' style={{ marginTop: '1.5rem', color: (!form?.attorney?.nationality?.trim() && (error || focused.nationality2))  ? '#C52A3A' : '' }}>
                                Státní příslušnost
                                <Container fluid>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <select
                                                type='select' 
                                                value={form?.attorney?.nationality ?? 'CZE'}
                                                required
                                                {...(focused.nationality2 && {focused: 'true'})}
                                                style={{ border: error && !form?.attorney?.nationality?.trim() && 'solid 1px #C52A3A' }}
                                                onChange={(e) => {
                                                    setRequest(state => ({ ...state, form: { ...state.form, attorney: { ...state.form.attorney, nationality: e.target.value } } }));
                                                }}
                                                onBlur={() => {
                                                    handleFocused('nationality2');
                                                }}
                                                className='id-input2 dropdown-input' >
                                                <option value={''}>Vyberte</option>
                                                {COUNTRIES.map((country) => (
                                                    <option key={country.value} value={country.value}>{country.label}</option>
                                                ))}     
                                            </select>
                                        </Col>
                                    </Row>
                                </Container>
                            </label>}
                            {
                                request.form.attorney.isFOBusiness &&
                                ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* TRVALÝ POBYT ZÁSTUPCE / MÍSTO PODNIKÁNÍ ZÁSTUPCE */}
                    {!request.form.applicantMe && 
                    <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>{request.form.attorney.isFO ? 'Trvalý pobyt' : request.form.attorney.isFOBusiness ? 'Místo podnikání' : 'Sídlo'}</h3>
                        <form className='p-0'>
                            {
                                (request.form.attorney.isPO || request.form.attorney.isFO) &&
                                ATTORNEY_LOCATION.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                            {
                                request.form.attorney.isFOBusiness &&
                                ATTORNEY_LOCATION_BUSINESS.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* DORUČOVACÍ ADRESA ZÁSTUPCE STEJNÁ */}
                    {(!request.form.applicantMe && !request.form.attorney.isPO) && <Row className='row-wrapper'>
                        <form className='p-0'>
                            <label className='id-checkbox-label'>
                                <input type='checkbox' className='id-checkbox-input' checked={request.form.attorney.shippingSameAsPr} 
                                    onChange={(e) => { setRequest(state => ({ ...state, form: { ...state.form, attorney: { ...state.form.attorney, shippingSameAsPr: e.target.checked } } })); }} 
                                />
                                Doručovací adresa je stejná jako adresa {request.form.attorney.isFO ? 'trvalého pobytu' : 'místa podnikání'}
                            </label>
                        </form>
                    </Row>}

                    {/* DORUČOVACÍ ADRESA ZÁSTUPCE */}
                    {(!request.form.applicantMe && !request.form.attorney.isPO && !request.form.attorney.shippingSameAsPr) && <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>Doručovací adresa</h3>
                        <form className='p-0'>
                            {
                                ATTORNEY_DELIVERY_LOCATION.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* KONTAKTNÍ ÚDAJE ZÁSTUPCE */}
                    {!request.form.applicantMe && <Row className='row-wrapper'>
                        <form className='p-0'>
                            {
                                ATTORNEY_CONTACTS.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {(request.form.attorney.isFOBusiness) && (<Row className='row-wrapper'>
                        <form className='p-0'>
                            <label className='id-checkbox-label'>
                                <input type='checkbox' className='id-checkbox-input' checked={form.attorney.includeRepresentative}
                                    onChange={(e) => {  setRequest(state => ({ ...state, form: { ...state.form, attorney: { ...state.form.attorney, includeRepresentative: e.target.checked } } })); }}
                                />
                                Zastoupena jinou osobou
                            </label>
                        </form>
                    </Row>)}

                    {(request.form.attorney.isPO) && !request.form.applicantMe && <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>Osoba oprávněná jednat jménem právnické osoby</h3>
                        <form className='p-0'>
                            {
                                ATTORNEY_PERSON_AUTHORIZED.filter(item => !(item.marker && item.marker === 'FOP')).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {(request.form.attorney.includeRepresentative && request.form.attorney.isFOBusiness) && !request.form.applicantMe && <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>Osoba zastupující fyzickou osobu podnikající</h3>
                        <form className='p-0'>
                            {
                                REPRE_ATTORNEY_PERSON_AUTHORIZED.filter(item => !(item.marker && item.marker === 'PO')).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* PLNÁ MOC PRO FOP*/}
                    {((request.form.attorney.isFOBusiness && request.form.attorney.includeRepresentative)||(request.form.attorney.isPO)) && <Row className='row-wrapper' ref={attorneyAttachmentRef}>
                        <PowerOfAttorneyInputRepre
                            form={form}
                            setRequest={setRequest}
                            setZadatelAttachments={setZadatelAttachments}
                            handleFocused={handleFocused}
                            powerOfAttorneyError={powerOfAttorneyInputRepreError}
                            handleFileInput={handleFileInput}
                            zadatelAttachments={zadatelAttachments}
                            focused={focused}
                            pravnickaOsoba={request.form.attorney.isPO}
                        />
                    </Row>}

                    {/* MODAL WINDOW */}
                    <ApplicantMoreModal
                        zadatelAttachments={allZadatelAttachments}
                        show={show}
                        setShow={setShow}
                        currentApplicant={currentApplicant}
                        setCurrentApplicant={setCurrentApplicant}
                        applicantArr={applicantArr}
                        setApplicantArr={setApplicantArr}
                        plnaMocForZadatelAttachment={zadatelAttachments[PLNA_MOC_SECOND]}
                        plnaMocForZadatelRepreAttachment={zadatelAttachments[PLNA_MOC_FIFTH]}
                        setZadatelAttachments={setZadatelAttachments}
                        handleFileInput={handleFileInput}
                    />
                </Container>
            }
        </>
    );
};

ZadatelComponent15.propTypes = {

    urlPath: PropTypes.string.isRequired,
    foundApplicantIndex: PropTypes.number,
    setFoundApplicantIndex: PropTypes.func,
    stage: PropTypes.number,
    applicantArr: PropTypes.arrayOf(Object),
    setApplicantArr: PropTypes.func,
};

export default ZadatelComponent15;
