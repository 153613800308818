import axios from 'axios';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { iconChange, trashbin } from '../../../assets';
import { 
    DIVISION,
    UNIFICATION,
    CADASTRAL_AREA,
    CHANGE_OF_USE,
    CONSTRUCTION_SITE,
    DIVISION_UNIFICATION,
    PARCEL_NUMBER,
    PROTECTION,
    SURFACE_AREA,
    TYPE,
    ACTUAL_PARCEL_USAGE,
    NEW_PARCEL_USAGE,
    AFFECTED_POZEMKY_TABLE,
    POZEMKY_TABLE,
    TERRAIN_CHANGE,
    POZEMKY_MAPPING
} from '../../../constants/sharedConstants.js';
import { Container } from 'react-bootstrap';
import '../../../css/formTableView.css';
import { ButtonsAndPaginate } from '../../../components/index.js';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { enterCsvData, getFormType, removeCsvData, setCsvDataAndRequest, shouldNotSaveTable, handleAddManuallyIntoTables, getAnoNeValue } from '../../../helperFunctions/helpers.js';
import { formSave } from '../../../apiCalls/formApiCalls.js';
import FormContext from '../../../formContexts/FormContext.js';

const PozemkyTable = ({
    urlPath,
    title,
    subtitle,
    isAffected,
    dataToAdd,
    receivedToken,
    newBuilding,
    setNewBuilding,
    setDataToAdd
}) => {
    const [showPozemekModal, setShowPozemekModal] = useState(false);
    const resultPerPage = 25;
    const [listData, setListData] = useState([]);
    const { token } = useContext(AuthContext);
    const [startIndex, setStartIndex] = useState(0);
    const endIndex = startIndex + resultPerPage;
    const {id} = useContext(FormContext);
    const [tableRequest, setTableRequest] = useState({
        'applicationId': id,
    });
    const formType = getFormType(urlPath);
    const currentPageRef = useRef(null);
    const [currentlyShowing, setCurrentlyShowing] = useState(0);
    const dynamicKey = isAffected ? 'affectedBuildParcels' : 'buildParcels';
    const preloadRef = useRef(false);
    const [itemToUpdate, setItemToUpdate] = useState(null);
    const isForm8 = formType === 8;
    const isForm10 = formType === 10;
    const isForm15 = formType === 15;
    const isForm16 = formType === 16;
    const isForm19 = formType === 19;

    const is04Aff = formType === 4 && isAffected;
    const is09Aff = formType === 9 && isAffected;
    const is15Aff = isForm15 && isAffected;
    const is16Aff = isForm16 && isAffected;
    const is19Aff = (isForm19 && isAffected);
    const propertyToUpdate = isAffected ? 'affectedBuildParcels' : 'buildParcels';

    const [noFieldsToUpdate] = useState(isForm8 || isForm10 || isForm15 || is16Aff || is19Aff || is04Aff || is09Aff);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(tableRequest, token, urlPath, source, receivedToken ?? undefined);
    };

    useEffect(() => {
        if (!dataToAdd) {
            return;
        }

        if (dataToAdd.length > 0) {
            preloadRef.current = true;
        }

        setCsvDataAndRequest(
            listData,
            setListData,
            setTableRequest,
            dynamicKey,
            dataToAdd
        );

    }, [dataToAdd]);

    useEffect(() => {
        if (shouldNotSaveTable(tableRequest)) {
            return;
        }

        if (preloadRef.current) {
            preloadRef.current = false;
            return;
        }

        if (setDataToAdd) setDataToAdd(prev => ({...prev, [propertyToUpdate]: [...listData]}));

        saveChanges();
    }, [tableRequest]);

    const handleAddPozemek = (pozemek) => {
        const newData = [...listData, 
            {...pozemek, uid: uuidv4(), imported: false}
        ];
        setListData(newData);
        handleAddManuallyIntoTables(setTableRequest, listData, pozemek, dynamicKey, '');
        if(!setDataToAdd) return;
        setDataToAdd(prev => ({...prev, [propertyToUpdate]: newData}));
    };

    const handleUpdatePozemek = (pozemek) => {
        const newData = [...listData].map(data => {
            if (data.uid === pozemek.uid) {
                return {...data, ...pozemek};
            }
            return data;
        });
        setListData(newData);
        handleAddManuallyIntoTables(setTableRequest, listData, pozemek, dynamicKey, pozemek.uid);
        if(!setDataToAdd) return;
        setDataToAdd(prev => ({...prev, [propertyToUpdate]: newData}));
    };

    const handleRemovePozemek = (keyStr) => {
        const newData = listData.filter(item => String(item.uid) !== String(keyStr));
        setListData(newData);
        removeCsvData(setTableRequest, newData, dynamicKey);
        if(!setDataToAdd) return;
        setDataToAdd(prev => ({...prev, [propertyToUpdate]: newData}));
    };

    const handleUpdate = (data) => {
        setItemToUpdate(data);
        setShowPozemekModal(true);
    };

    const trimValue = (value) => value?.trim() ?? '';

    const handleCsv = async (data) => {
        const newLines = [];

        await data.forEach((line, index, self) => {
            try {
                let newLine = {};
    
                if (index === 0) {
                    return;
                }
    
                const headers = self[0];
                
                for (let i = 0; i < line.length; i++) {
                    const item = line[i];
                    const trimmedItem = trimValue(item);
                    if (/^(ano|ne)$/i.test(trimmedItem) && /\(ano\/ne\)/.test(headers[i]?.trim()?.toLowerCase())) {
                        newLine[POZEMKY_MAPPING[headers[i]?.trim()?.toLowerCase()]] = getAnoNeValue(trimmedItem);
                    } else {
                        newLine[POZEMKY_MAPPING[headers[i]?.trim()?.toLowerCase()]] = trimmedItem;
                    }
                }
    
                newLine.dirty = false;
                newLine.imported = true;
    
                newLines.push(newLine);
            } catch (error) {
                console.log(error);
            }
        });

        setListData([...newLines.map(el => ({
            ...el,
            uid: uuidv4(),
        }))]);

        enterCsvData(setTableRequest, newLines, dynamicKey);
    };

    useEffect(() => {
        if (listData.length === 0 || !currentPageRef.current) {
            return;
        }

        setCurrentlyShowing(currentPageRef.current.childNodes.length);
    }, [listData.length, startIndex]);


    return (
        <Container className='py-3' fluid>
            <h2 className='m-0 pb-2'>{isAffected ? 'Sousední pozemky' : (title || 'Pozemky')}</h2>
            {subtitle && <p className='p-0 mb-4 table-subtitle'>{subtitle}</p>}
            <Container className='overflow-x-scroll' fluid>
                {(listData.length > 0) &&
                    <table className='border my-3' >
                        <thead>
                            <tr>
                                <th></th>
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${CADASTRAL_AREA}`}</p></th>
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${PARCEL_NUMBER}`}</p></th>
                                {
                                    (!is16Aff && !is15Aff && !is19Aff) &&
                                    <>
                                        <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${TYPE}`}</p></th>
                                        <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${SURFACE_AREA} (m²)`}</p></th>
                                    </>
                                }
                                {(formType !== 7 && formType !== 8 && formType !== 10 && formType !== 6 && formType !== 15 && !is16Aff && formType !== 19 && !is04Aff && !is09Aff) && 
                                <>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${CHANGE_OF_USE}`}</p></th>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${DIVISION_UNIFICATION}`}</p></th>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${PROTECTION}`}</p></th>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${CONSTRUCTION_SITE}`}</p></th>
                                </>}
                                {(formType === 19 && !is19Aff) &&
                                <>  
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${TERRAIN_CHANGE}`}</p></th>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${CONSTRUCTION_SITE}`}</p></th>
                                </>}
                                {formType === 7 &&
                                <>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${DIVISION}`}</p></th>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${UNIFICATION}`}</p></th>
                                </>}
                                {formType === 6 &&
                                <>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${ACTUAL_PARCEL_USAGE}`}</p></th>
                                    <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${NEW_PARCEL_USAGE}`}</p></th>
                                </>}
                                {/* <th className='py-2 px-3'></th> */}
                                <th className='py-2 px-3'></th>
                            </tr>
                        </thead>
                        <tbody ref={currentPageRef}>
                            {listData.length > 0 && listData.map((data, index, array) => {
                                if ((array.length <= resultPerPage) || (index >= startIndex && index < endIndex)) {
                                    return (
                                        <tr key={data.uid}>
                                            <td className='py-2 px-3'><p className='row-number'>{index + 1}</p></td>
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.cadastralTerritory ?? ''}</p></td>
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.parcelNumber ?? ''}</p></td>
                                            {
                                                (!is16Aff && !is15Aff && !is19Aff) &&
                                                <>
                                                    <td className='py-2 px-3'><p className='row-info py-2'>{data?.type ?? ''}</p></td>
                                                    <td className='py-2 px-3'><p className='row-info py-2'>{data?.area ?? ''}</p></td>
                                                </>
                                            }
                                            {(formType !== 7 && formType !== 8 && formType !== 10 && formType !== 6 && formType !== 15 && !is16Aff && formType !== 19 && !is04Aff && !is09Aff) &&
                                            <>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.changeOfUse === true ? 'Ano' : 'Ne'}</p></td>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.divisionOfFusion === true ? 'Ano' : 'Ne'}</p></td>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.protectionZone === true ? 'Ano' : 'Ne'}</p></td>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionSite === true ? 'Ano' : 'Ne'}</p></td>
                                            </>}
                                            {(formType === 19 && !is19Aff) &&
                                            <>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.terrainAdjustments === true ? 'Ano' : 'Ne'}</p></td>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionSite === true ? 'Ano' : 'Ne'}</p></td>
                                            </>}
                                            {formType === 7 &&
                                            <>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.division === true ? 'Ano' : 'Ne'}</p></td>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.fusion === true ? 'Ano' : 'Ne'}</p></td>
                                            </>}
                                            {formType === 6 &&
                                            <>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.actualParcelUsage ?? ''}</p></td>
                                                <td className='py-2 px-3'><p className='row-info py-2'>{data?.newParcelUsage ?? ''}</p></td>
                                            </>}
                                            <td className='py-2 px-3'>                                            
                                                <button 
                                                    type='button'
                                                    onClick={() => handleUpdate(data)}
                                                    className='d-flex justify-content-center align-items-center table-button-upravit'
                                                >
                                                    <img src={iconChange} alt='upravit' className='pe-2' />
                                                    { noFieldsToUpdate || data?.imported ? 'Náhled' : 'Upravit'}
                                                </button>
                                            </td>
                                            <td className='py-2 px-3'>
                                                <button
                                                    type='button'
                                                    onClick={() => handleRemovePozemek(data.uid)}
                                                    className='d-flex justify-content-center align-items-center table-button-smazat'
                                                >
                                                    <img src={trashbin} alt='trashbin' className='pe-2' />
                                                        Smazat
                                                </button>
                                            </td>
                                        </tr>);
                                }
                            })}
                        </tbody>
                    </table>
                }
            </Container>
            <ButtonsAndPaginate
                showPozemekModal={showPozemekModal}
                setShowPozemekModal={setShowPozemekModal}
                itemToUpdate={itemToUpdate}
                setItemToUpdate={setItemToUpdate}
                newBuilding={newBuilding}
                setNewBuilding={setNewBuilding}
                category={isAffected ? AFFECTED_POZEMKY_TABLE : POZEMKY_TABLE}
                listData={listData}
                setListData={setListData}
                startIndex={startIndex}
                setStartIndex={setStartIndex}
                handleCsv={handleCsv}
                setTableRequest={setTableRequest}
                resultPerPage={resultPerPage}
                currentlyShowing={currentlyShowing}
                isAffected={isAffected}
                handleAddItem={handleAddPozemek}
                handleUpdateItem={handleUpdatePozemek}
                noFieldsToUpdate={noFieldsToUpdate}
            />
        </Container>
    );
};

PozemkyTable.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    handleCsv: PropTypes.func, 
    urlPath: PropTypes.string,
    isAffected: PropTypes.bool,
    dataToAdd: PropTypes.arrayOf(Object),
    setDataToAdd: PropTypes.func,
    receivedToken: PropTypes.string,
    newBuilding: PropTypes.object,
    setNewBuilding: PropTypes.func,
};

export default PozemkyTable;