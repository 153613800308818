import React, { useRef, useState } from 'react';
import '../css/reusable.css';
import '../css/filter.css';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { iconSearch, arrowDownDark } from '../assets/index.js';
import { RIZENI, STAVBY, ZADOSTI, ZAMERY, ALL_TYPES, DOKUMENTACE } from '../constants/sharedConstants.js';
import { getFilterBodyBySections, handleFilterChange } from '../helperFunctions/helpers.js';
import { ViewSwticher } from '../components/index.js';

const Filter = ({ isActive, setIsActive, postBody, setPostBody, page, isWithFolding }) => {
    const [filteringVisible, setFilteringVisible] = useState(false);
    const inputRef = useRef(null);

    const displayFilters = {
        display: filteringVisible ? 'flex' : 'none'
    };

    const border = {
        borderTop: page === ZAMERY || page === ZADOSTI || page === DOKUMENTACE ? 'solid 1px #DDDDDD' : 'none',
        borderBottom: 'solid 1px #DDDDDD'
    };

    return(
        <Container fluid className='pt-4 pb-4 d-flex flex-column align-items-center'>
            <form className='base-width pt-3 pb-3'>
                <Row className='py-3 position-relative' style={border}>
                    {/* TEMPORARY DISABLED ON CERTAIN PAGES */}
                    {(page !== STAVBY && page !== ZAMERY) && <>
                        <Col xs={12} sm={7} md={{span: 4, order: 1}} lg={{span: isActive === 'block' ? 3 : 4 }} className='my-2 me-4'>
                            <label className=' mb-1 form__label form--label-width' htmlFor='filter' style={{width: '88px'}}>Filtrovat</label>
                            <Container fluid className='input-icon-wrapper d-flex'>
                                <img src={iconSearch} className='input-icon' />
                                <input
                                    ref={inputRef} 
                                    id='filter'
                                    type='text'
                                    placeholder={page === RIZENI ? 'Dle názvu, ID záměru, čísla jednacího' : page === ZADOSTI ? 'Dle názvu záměru, ID záměru, typ žádosti' : page === DOKUMENTACE ? 'Dle názvu záměru, ID záměru' : ''}
                                    className='form_input-max-width form__input' 
                                    onChange={(e) => { 
                                        handleFilterChange(
                                            e.target,
                                            'input',
                                            '', 
                                            isWithFolding,
                                            setPostBody
                                        );
                                    }} 
                                    style={{ paddingLeft: '30px' }} 
                                />
                            </Container>
                        </Col>
                        {page !== STAVBY && <Col xs={12} sm={5} md={{span: 4, order: 2}} className='d-flex flex-column d-lg-none justify-content-end align-items-center pe-3 my-2'>
                            <Container fluid className='d-flex mb-1' role='button' onClick={() => setFilteringVisible(!filteringVisible)}>
                                <img className='me-2 input_icon' src={arrowDownDark} />
                                <p style={{ color: '#2362A2', fontSize: '0.9rem' }}>Filtrovaní, řazeni a zobrazení</p>
                            </Container>
                        </Col>}
                        {isActive === 'block' && <Col xs={12} sm={6} md={{span: 4, order: 5}} lg={{span: 2, order: 2}} className='me-lg-4 my-2 d-lg-flex' style={displayFilters}>
                            <Container fluid className='d-flex flex-column'>
                                <label className='mb-1 form__label' htmlFor='stav' style={{width: '64px'}}>Řadit</label>
                                <select id='stav' name='stav' className='form__select' 
                                    onChange={(e) => {
                                        handleFilterChange(
                                            e.target,
                                            page === ZADOSTI ? 'sort/zadosti/date' : page === RIZENI ? 'sort/rizeni' : page === DOKUMENTACE ? 'sort/dokumentace' : '',
                                            '',
                                            isWithFolding, 
                                            setPostBody
                                        );
                                    }}
                                    value={(page === RIZENI || page === DOKUMENTACE) ? (postBody.sorts?.[0]?.field ?? '') : page === ZADOSTI ? (postBody.sorts?.[0]?.asc ? 'asc' : 'desc') : ''}
                                >
                                    {page === ZADOSTI && <>
                                        <option value={'desc'}>Od nejnovějších</option>
                                        <option value={'asc'}>Od nejstarších</option>
                                    </>}
                                    {page === RIZENI && <>
                                        <option value={'date'}>Od poslední aktualizace</option>
                                        <option value={'projectName'}>Dle záměru A-Z</option>
                                        <option value={'procedureNumber'}>Dle čísla jednacího A-Z</option>
                                    </>}
                                    {page === DOKUMENTACE && <>
                                        <option value={'date'}>Od poslední aktualizace dokumentace záměru</option>
                                        <option value={'projectName'}>Dle záměru A-Z</option>
                                    </>}
                                </select>
                            </Container>
                        </Col>}
                        {(page !== STAVBY && page !== ZAMERY && page !== DOKUMENTACE) && <Col xs={12} sm={6} md={{span: 4, order: 5}} lg={{span: 2, order: 2}} className='form__col-sm-padding my-2 me-lg-4 d-lg-flex' style={displayFilters}>
                            <Container fluid className='d-flex flex-column'>
                                <label className='mb-1 form_label-no-width form__label' htmlFor='role' style={{width: '64px'}}>Stav</label>
                                <select id='role' name='role' className='form__select'
                                    onChange={(e) => {
                                        handleFilterChange(
                                            e.target,
                                            'filter',
                                            'status',
                                            isWithFolding, 
                                            setPostBody
                                        );
                                    }}
                                    value={[...(postBody.filters || [])].find(el => el.field === 'status')?.value ?? ''}
                                >
                                    <option value={''}>Nezáleží</option>
                                    {page === RIZENI && <>
                                        <option value={'COMPLETED'}>Dokončené</option>
                                        <option value={'ACTIVE'}>Běžící</option>
                                        <option value={'PAUSED'}>Přerušené</option>
                                    </>}
                                    {page === ZADOSTI && <>
                                        <option value={'COMPLETED'}>Dokončené</option>
                                        <option value={'ACTIVE'}>Zpracovává se</option>
                                    </>}
                                </select>
                            </Container>
                        </Col>}
                        {page !== STAVBY && page !== ZAMERY && page !== DOKUMENTACE && <Col xs={12} sm={6} md={{span: 4, order: 5}} lg={{span: 2, order: 3}} className='my-2 pe-sm-1 d-lg-flex' style={displayFilters}>
                            <Container fluid className='d-flex flex-column align-items-md-start'>
                                <label className='mb-1 form__label' htmlFor='role'>{page === RIZENI ? 'Moje role' : 'Typ žádosti'}</label>
                                <select id='role' name='role' className='form__select'  
                                    onChange={(e) => {
                                        handleFilterChange(
                                            e.target,
                                            'filter',
                                            page === RIZENI ? 'role' : 'requestType', 
                                            isWithFolding, 
                                            setPostBody
                                        );
                                    }}
                                    value={[...(postBody.filters || [])].find(el => el.field === (page === RIZENI ? 'role' : 'requestType'))?.value ?? ''}
                                >
                                    {page === RIZENI && <>
                                        <option value={''}>Vše</option>
                                        <option value={'BUILDER_OR_REPRESENTATIVE'}>Žadatel nebo zástupce</option>
                                        <option value={'LAND_OR_LAW_OWNER'}>Vlastník pozemku</option>
                                        <option value={'NEIGHBOUR'}>Soused</option>
                                        <option value={'OTHER'}>Ostatní</option>
                                    </>}
                                    {page === ZADOSTI && <>
                                        <option value={''}>Nezáleží</option>
                                        {ALL_TYPES.map((type) => (<option key={type} value={type}>{type}</option>))}
                                    </>}
                                </select>
                            </Container>
                        </Col>}
                        {JSON.stringify(postBody) !== JSON.stringify(getFilterBodyBySections(page)) && 
                        <Container className='d-flex align-items-end justify-content-end pb-1 position-absolute cancel-filter-btn-container'>
                            <button 
                                onClick={() => {
                                    setPostBody(getFilterBodyBySections(page)); 
                                    if (inputRef.current) {
                                        inputRef.current.value = '';
                                    }
                                }} 
                                className='cancel-filter-btn'
                            >
                                Zrušit filtry
                            </button>
                        </Container>}
                        {filteringVisible && <Col xs={12} sm={6} className='my-2 d-sm-none' style={displayFilters}>
                        </Col>}
                    </>}
                    <ViewSwticher
                        isActive={isActive}
                        setIsActive={setIsActive}
                    />
                </Row>
            </form>
        </Container>
    );
};

Filter.propTypes = {
    isActive: PropTypes.string.isRequired,
    setIsActive: PropTypes.func.isRequired,
    setPostBody: PropTypes.func,
    postBody: PropTypes.object,
    page: PropTypes.string,
    isWithFolding: PropTypes.bool
};


export default Filter;