import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useParams } from 'react-router-dom';
import { insertFilePidandFetchDokumentaceDetail } from '../../apiCalls/componentsApiCalls';
import { Container } from 'react-bootstrap';
import DokumentaceDetailHeaderSection from './DokumentaceDetailHeaderSection';
import DokumentaceDetailMainSection from './DokumentaceDetailMainSection';

const DokumentaceApiCall = () => {
    const [data, setData] = useState({
        versions: [],
        zamerDetail: null
    });
    // TODO temp state
    const [fetched, setFetched] = useState(false);
    const { token: documentationToken, filePid } = useParams();
    const { token } = useContext(AuthContext);

    const fetchDetail = async (signal) => {
        try {
            const response = await insertFilePidandFetchDokumentaceDetail(signal, token, documentationToken, filePid);
            if (response instanceof Object) {
                const {zamerDetail, versions} = response;
                setData(prev => ({...prev, zamerDetail, versions}));
            }
        } catch {
            console.log('fetch error dokumentace');
        } finally {
            setFetched(true);
        }
    };

    useEffect(() => {
        if (data?.versions?.length > 0) {
            return;
        }

        const controller = new AbortController();
        const signal = controller?.signal;
        // TODO temp state
        if (!fetched) {
            fetchDetail(signal);
        }
            
        return () => {
            controller?.abort();
        };
    }, []);

    return (
        <Container fluid className='px-2 py-4 px-md-5 px-xl-0 d-flex flex-column base-width'>
            {data?.zamerDetail && <DokumentaceDetailHeaderSection item={ data?.zamerDetail }/>}
            {data?.versions?.length > 0 && <DokumentaceDetailMainSection item={ { versions: [...(data?.versions || [])], zamerDetail: {...(data?.zamerDetail || {})} } }/>}
        </Container>
    );
};

export default DokumentaceApiCall;