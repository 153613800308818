import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import cs from 'moment/locale/cs';

import '../css/zadosti.css';

moment.locale('cs', cs);

export const CardContainer = ({ children }) => (
    <div className='d-flex flex-column border rounded px-3 py-4 zadosti-container'>
        {children}
    </div>
);

export const CardHeader = ({ children }) => (
    <div className='mb-3'>
        {children}
    </div>
);

export const CardRow = ({ label, value }) => {
    if(!value) return null;

    return (
        <Row className='d-flex align-items-center py-1 border-bottom'>
            <Col sm={4}>
                <p className='zadosti-row-name'>{label}</p>
            </Col>
            <Col sm={8}>
                <p className='zadosti-row-info'>{value}</p>
            </Col>
        </Row>
    );
};

export const CardBody = ({ children }) => (
    <div className='mb-4'>
        {children}
    </div>
);

export const CardFooter = ({ children }) => (
    <div className='mt-auto d-flex flex-column gap-2'>
        {children}
    </div>
);

CardContainer.propTypes = {
    children: PropTypes.node
};

CardHeader.propTypes = {
    children: PropTypes.node
};

CardRow.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ])
};

CardBody.propTypes = {
    children: PropTypes.node
};

CardFooter.propTypes = {
    children: PropTypes.node
};
