import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { arrowDownDark, arrowUpBlue } from '../../assets';
import { Link } from 'react-router-dom';
import useFirstRender from '../CustomHooks/useFirstRender';

import '../../css/tableView.css';
import { GovButton } from '@gov-design-system-ce/react';

const DocumentationTable = ({ items, postBody, setPostBody, isReset }) => {
    const [sortKeys, setSortKeys] = useState({
        filePid: [...(postBody.sorts || [])].find(el => el.field === 'filePid')?.asc ?? null, // ?bool
        projectId: [...(postBody.sorts || [])].find(el => el.field === 'projectId')?.asc ?? null, // ?bool
        projectName: [...(postBody.sorts || [])].find(el => el.field === 'projectName')?.asc ?? null, // ?bool
        date: [...(postBody.sorts || [])].find(el => el.field === 'date')?.asc ?? null, // ?bool
    });
    const firstRender = useFirstRender();

    const handleClick = (field, setSortKeys) => {
        setSortKeys(prev => {
            const ref = {};
            for (const key in prev) {
                if (key === field) {
                    ref[key] = !(prev[key]);
                } else {
                    ref[key] = null;
                }
            }

            return ref;
        });
    };

    useEffect(() => {
        if (firstRender) {
            return;
        }

        if (isReset) {
            setSortKeys({
                filePid: null,
                projectId:  null,
                projectName:  null,
                date:  null,
            });
        }
    }, [isReset]);
    
    useEffect(() => {
        if (Object.values(sortKeys).every(val => val === null)) {
            return;
        }

        const sortKey = Object.keys(sortKeys).find(key => sortKeys[key] !== null);
        setPostBody(prev => {
            const addedSort = {field: sortKey, asc: sortKeys[sortKey]};
            return {...prev, sorts: [...prev.sorts.filter(el => Object.keys(sortKeys).indexOf(el.field ) === -1), {...addedSort}]};
        });
    }, [sortKeys]);

    return (
        <table className="w-100 min-width-table border">
            <thead>
                <tr>
                    <th className='py-2 px-3' onClick={() => handleClick('projectName', setSortKeys)}><p className='table-head py-2 d-flex'>Název<img className='ms-2' src={sortKeys.projectName ? arrowUpBlue : arrowDownDark} alt='arrowDownDark' /></p></th>
                    <th className='py-2 px-3' onClick={() => handleClick('projectId', setSortKeys)}><p className='table-head py-2 d-flex'>Číslo záměru<img className='ms-2' src={sortKeys.projectId ? arrowUpBlue : arrowDownDark} alt='arrowDownDark' /></p></th>
                    <th className='py-2 px-3' onClick={() => handleClick('filePid', setSortKeys)}><p className='table-head py-2 d-flex'>PID nejnovější dokumentace<img className='ms-2' src={sortKeys.filePid ? arrowUpBlue : arrowDownDark} alt='arrowDownDark' /></p></th>
                    <th className='py-2 px-3' onClick={() => handleClick('date', setSortKeys)}><p className='table-head py-2 d-flex'>Datum vložení<img className='ms-2' src={sortKeys?.date ? arrowUpBlue : arrowDownDark} alt='arrowDownDark' /></p></th>
                </tr>
            </thead>
            <tbody>
                {items.length > 0 && items.map((item, idx) => (
                    <tr key={item.id ?? idx}>
                        <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.projectName}>{item.projectName}</p></td>
                        <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.projectId}>{item.projectId}</p></td>
                        <td className='py-2 px-3'><p className='row-number py-2 text-truncate' title={item.filePid}>{item.filePid}</p></td>
                        <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item?.finishDate}>{item?.finishDate}</p></td>
                        <td className='py-2 px-3'>
                            <Link to={`/dokumentace/${encodeURIComponent(item.filePid)}`}>
                                <GovButton
                                    nativeType='button'
                                    type='outlined'
                                    variant='primary'
                                >
                                    Zobrazit dokumentaci
                                </GovButton>
                            </Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

DocumentationTable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        filePid: PropTypes.string,
        projectId: PropTypes.string,
        projectEszId: PropTypes.string,
        projectName: PropTypes.string,
        versionId: PropTypes.string,
        versionNumber: PropTypes.number,
        finishDate: PropTypes.string,
    })),
    setPostBody: PropTypes.func,
    isReset: PropTypes.bool,
    postBody: PropTypes.object,
};

export default DocumentationTable;