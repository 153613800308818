import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { file } from '../../assets';

const DokumentaceVersionsTable = ({items, downloadDocumentVersion}) => {
    const [ isMobileView, setIsMobileView ] = useState(window.innerWidth <= 700);

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    return (
        <Container className='p-0 m-0'>
            <table className='border w-100'>
                <thead>
                    <tr>
                        <th className='py-2 px-3'><p className='table-head py-2 d-flex'>Datum</p></th>
                        <th className='py-2 px-3'><p className='table-head py-2 d-flex'>PID</p></th>
                    </tr>
                </thead>
                <tbody>
                    {items?.length > 0 && items?.map((item, idx) => (
                        <tr key={item?.id ?? idx}>
                            <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item?.finishDate}>{item?.finishDate}</p></td>
                            <td className='py-2 px-3'><p className='row-number py-2 text-truncate' title={item?.bppPid}>{item?.bppPid}</p></td>
                            <td className='py-2 px-3'>
                                <Row className='mb-4'>
                                    <button type='button' className='d-flex justify-content-center align-items-center kontrola-button' onClick={() => downloadDocumentVersion(item?.bppPid)}><img src={file} alt="file" className={!isMobileView ? 'kontrola-button-icon' : ''}/>
                                        {!isMobileView ? 'Stáhnout' : ''}
                                    </button>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Container>
    );
};

DokumentaceVersionsTable.propTypes = {
    items: PropTypes.any,
    downloadDocumentVersion: PropTypes.func 
};

export default DokumentaceVersionsTable;