import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Filter from '../Filter';
import PropTypes from 'prop-types';
import { DOKUMENTACE } from '../../constants/sharedConstants';
import { getFilterBodyBySections, intentionsSort, setFiltersForDocumentations } from '../../helperFunctions/helpers';
import RecordNotFound from '../RecordNotFound';
import { GovButton } from '@gov-design-system-ce/react';
import { exportToCSV } from '../../helperFunctions/exportCSVHelpers';
import DocumentationCard from './DocumentationCard';
import { Col, Row } from 'react-bootstrap';
import { fetchZamery } from '../../apiCalls/componentsApiCalls';
import { AuthContext } from 'react-oauth2-code-pkce';
import DocumentationTable from './DocumentationTable';
import useFirstRender from '../CustomHooks/useFirstRender';
import PaginateResults from '../Forms/ZamerComponentTables/PaginateResults';

const INSERTED_HEADERS = ['PID', 'Project ID', 'Project Name', 'Request Date'];
const RESULTS_PER_PAGE = 24;

const TabInserted = ({ isActive, setIsActive }) => {
    const { token } = useContext(AuthContext);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 700);
    const isShowBlock = isActive === 'block';
    const [fetching, setFetching] = useState(false);
    const [listInserted, setListInserted] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [startIndexInserted, setStartIndexInserted] = useState(0);
    const endIndexInserted = startIndexInserted + RESULTS_PER_PAGE;
    const [postBody, setPostBody] = useState(getFilterBodyBySections(DOKUMENTACE));
    const firstRender = useFirstRender();
    const isReset = useMemo(() => JSON.stringify(postBody) === JSON.stringify(getFilterBodyBySections(DOKUMENTACE)), [postBody]);

    const getData = useCallback(async (signal) => {
        try {
            const response = await fetchZamery(postBody, token, signal);
            if ((200 <= response?.status) && (response?.status < 300)) {
                const sortedList = [...response.data.data].sort(intentionsSort);
                setListInserted(sortedList);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setFetching(false);
        }
    }, [postBody]);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            }

            setIsMobileView(false);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        setFiltersForDocumentations(
            firstRender,
            isReset,
            setStartIndexInserted,
            listInserted,
            postBody,
            setFilteredList
        );
    }, [postBody, isReset]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        getData(signal);

        return () => {
            controller.abort();
        };
    }, [getData]);

    return (
        <div>
            <Filter
                isWithFolding={false}
                setPostBody={setPostBody}
                postBody={postBody} 
                isActive={isActive}
                setIsActive={setIsActive}                       
                page={DOKUMENTACE}
            />

            {fetching && (
                <div className='px-2 py-4 px-md-5 px-xl-0'>
                    <span>Načítám dokumentace...</span>
                </div>
            )}

            {!fetching && !listInserted.length && (
                <RecordNotFound />
            )}

            {!fetching && listInserted?.length > 0 && (
                <Row>
                    {isShowBlock && (
                        (isReset || firstRender) ? (
                            listInserted?.map((item, index, array) => {
                                if (array.length <= RESULTS_PER_PAGE) {
                                    return (
                                        <Col
                                            key={item?.filePid}
                                            xs={12}
                                            md={6}
                                            lg={4}
                                            className='d-flex justify-content-center py-2 px-md-2 px-lg-0 gap-2'
                                        >
                                            <DocumentationCard item={item} />
                                        </Col>
                                    );
                                } else if (index >= startIndexInserted && index < endIndexInserted) {
                                    return (
                                        <Col
                                            key={item?.filePid}
                                            xs={12}
                                            md={6}
                                            lg={4}
                                            className='d-flex justify-content-center py-2 px-md-2 px-lg-0 gap-2'
                                        >
                                            <DocumentationCard item={item} />
                                        </Col>
                                    );
                                }
                            })
                        ) : (
                            <>
                                {!filteredList && <RecordNotFound />}
                                {filteredList?.map((item, index, array) => {
                                    if (array.length <= RESULTS_PER_PAGE) {
                                        return (
                                            <Col
                                                key={item?.filePid}
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                className='d-flex justify-content-center py-2 px-md-2 px-lg-0 gap-2'
                                            >
                                                <DocumentationCard item={item} />
                                            </Col>
                                        );
                                    } else if (index >= startIndexInserted && index < endIndexInserted) {
                                        return (
                                            <Col
                                                key={item?.filePid}
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                className='d-flex justify-content-center py-2 px-md-2 px-lg-0 gap-2'
                                            >
                                                <DocumentationCard item={item} />
                                            </Col>
                                        );
                                    }
                                })}
                            </>
                        )
                    )}

                    {isShowBlock && (((isReset || firstRender) && listInserted.length > RESULTS_PER_PAGE) || (!isReset && !firstRender && filteredList.length > RESULTS_PER_PAGE)) &&
                        <Row className='d-flex w-100 align-items-center justify-content-center pt-3'>
                            <PaginateResults
                                resultPerPage={RESULTS_PER_PAGE}
                                listData={(isReset || firstRender) ? listInserted : filteredList}
                                isMobileView={isMobileView}
                                startIndex={startIndexInserted}
                                setStartIndex={setStartIndexInserted}
                            />
                        </Row>
                    }

                    {!isShowBlock && (
                        <DocumentationTable
                            items={(isReset || firstRender) ? listInserted : filteredList}
                            setPostBody={setPostBody}
                            postBody={postBody}
                            isReset={isReset}
                        />
                    )}

                    <div className='my-4'>
                        <GovButton
                            nativeType='button'
                            iconLeft='basic/file-earmark-arrow-down'
                            type='outlined'
                            variant='primary'
                            onGov-click={() => exportToCSV(INSERTED_HEADERS, listInserted)}
                        >
                            Exportovat .csv
                        </GovButton>
                    </div>
                </Row>
            )}
        </div>
    );
};

TabInserted.propTypes = {
    isActive: PropTypes.string.isRequired,
    setIsActive: PropTypes.func.isRequired,
};

export default TabInserted;