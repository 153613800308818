import React, { useContext, useState } from 'react';
import PozemkyTable from './PozemkyTable';
import StavbyTable from './StavbyTable';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { checkLimitAndInsertText } from '../../../helperFunctions/helpers';
import {CharCount} from '../../index';
import FormContext from '../../../formContexts/FormContext';
import { errorIcon } from '../../../assets';
import { affectedPropertyNotAdded } from '../../../helperFunctions/validationHelpers';

const AffectedConstructionsAndParcels = ({

    urlPath,
    request,
    setRequest,
    affectedBuildParcels,
    affectedBuildConstructions,
    isAffected,
    setDataToAdd,
    dataToAdd
}) => {
    const [moreInfo, setMoreInfo] = useState(false);
    const [ focused, setFocused ] = useState(false);
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 1000},
    });
    const buildIntention = request?.buildIntention;
    const form = request?.form;
    const {stagesArr, stepValue} = useContext(FormContext);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;

    const handleFocused = () => {
        setFocused(true);
    };

    return (
        <>
            <h2 className='p-0'>Užití sousedního pozemku nebo stavby</h2>
            <PozemkyTable
                
                urlPath={urlPath}
                isAffected={isAffected}
                dataToAdd={affectedBuildParcels}
                setDataToAdd={setDataToAdd}
            />
            {affectedPropertyNotAdded(form, dataToAdd) &&
            <Container className='mb-3' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                Bez vyhledání sousedního pozemku, či sousední stavby není možné pokračovat.
            </Container>}
            <StavbyTable
                title={'Sousední stavby'}
                
                urlPath={urlPath}
                isAffected={isAffected}
                dataToAdd={affectedBuildConstructions}
                setDataToAdd={setDataToAdd}
            />
            {affectedPropertyNotAdded(form, dataToAdd) &&
            <Container className='mt-3' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                Bez vyhledání sousedního pozemku, či sousední stavby není možné pokračovat.
            </Container>}

            {/* TODO add logic to hide Oduvodneni when there is no Stavba or Pozemek */}
            
            {/* {(affectedBuildConstructions?.length > 0 || affectedBuildParcels?.length > 0) &&  */}
            <Container className='py-2' fluid>
                <Row className='pt-2 pb-0 justification-container'>
                    <Col className='px-0 m-0 justification-text' style={{color: (error && !buildIntention?.useNeighborFacilityReason?.trim()) || (focused && !buildIntention?.useNeighborFacilityReason?.trim()) ? '#C52A3A' : ''}}>
                        Odůvodnění
                    </Col>
                    <Col className='d-flex align-items-center justify-content-end'>
                        <p className='p-0 pb-2 justification-link' onClick={() => setMoreInfo(!moreInfo)}>{moreInfo ? 'zabalit' : 'více informací'}</p>
                    </Col>
                </Row>
                {moreInfo && <Row className='py-2 justification-detail-text'>
                    <span className='px-0'>
                        Uveďte důvod pro použití sousedního pozemku nebo stavby, vymezení prováděné činnosti na sousedním pozemku nebo stavbě a podmínky omezení, zejména časové rozmezí provádění činnosti:
                    </span>
                </Row>}
                <Row className='my-2'>
                    <Row className='position-relative textarea-with-count-container'>
                        <textarea
                            className='textarea-with-count p-2 text-area--validation'
                            onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}name="justification"
                            id="justification"
                            required
                            {...(focused && { focused: 'true' })}
                            style={{border: error && !buildIntention?.useNeighborFacilityReason?.trim() ? '#C52A3A solid 1px' : ''}}
                            defaultValue={buildIntention?.useNeighborFacilityReason}
                            onBlur={(e) => {
                                setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, useNeighborFacilityReason: e.target.value } }));
                                handleFocused();
                            }}
                        />
                        <CharCount 
                            value={buildIntention?.useNeighborFacilityReason} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                        />
                    </Row>
                </Row>
            </Container>
            {/* } */}
        </>
    );
};

AffectedConstructionsAndParcels.propTypes = {
    

    affectedBuildConstructions: PropTypes.arrayOf(Object),
    affectedBuildParcels: PropTypes.arrayOf(Object),

    urlPath: PropTypes.string,
    setCheckbox: PropTypes.func,
    request: PropTypes.object,
    setRequest: PropTypes.func,
    isAffected: PropTypes.bool,
    error: PropTypes.bool,
    setDataToAdd: PropTypes.func,
    dataToAdd: PropTypes.arrayOf(Object),
};

export default AffectedConstructionsAndParcels;