import { ErrorBoundary } from 'react-error-boundary';
import React, { useEffect, useMemo, useState } from 'react';
import '../css/reusable.css';
import { Container } from 'react-bootstrap';
import { FormFooter, FormHeader, Form16MainSection, FormFinisher, ProcessError } from '../components';
import { useGlobalState } from '../constants/states';
import { isNotUploading, logError, getOngoingUploads } from '../helperFunctions/helpers';
import { items } from '../constants/form16';
import { FORM_16_INTENTION, DOCUMENT_FIRST, DOCUMENT_SECOND, DOCUMENT_THIRD_1, DOCUMENT_THIRD_2, DOCUMENT_THIRD_3, DOCUMENT_THIRD_4, DOCUMENT_THIRD_5, DOCUMENT_FOURTH, DOCUMENT_FIFTH, WARRANT, AFFIDAVIT, OWNER_CONSENT, PARTICIPANTS_LIST, FULFILLING_PRODUCT, PLANNING_CONTRACT, OTHER_DOCUMENTS, CONSENT_ALL } from '../constants/sharedConstants';
import FormContext from '../formContexts/FormContext';
import useScrollToTop from '../components/CustomHooks/useScrollToTop';

const Form16 = () => {
    const [state, setState] = useGlobalState();
    const [ isMobileView, setIsMobileView ] = useState(window.innerWidth < 992);
    const [isFinished, setIsFinished] = useState(false);
    const [id, setId] = useState(null);
    const [uploadError, setUploadError] = useState([]);
    const [isSuccessOrFailSubmission, setIsSuccessOrFailSubmission] = useState(null);
    const [uploadedBytes, setUploadedBytes] = useState(0);
    const [uploadedPrilohyBytes, setUploadedPrilohyBytes] = useState(0);
    const [stagesArr, setStagesArr] = useState(items);
    const [ fetch, setFetch ] = useState(false);
    const [waitingForPruvodniList, setWaitingForPruvodniList] = useState(false);
    const [documents, setDocuments] = useState({
        [DOCUMENT_FIRST]: [],
        [DOCUMENT_SECOND]: [],
        [DOCUMENT_THIRD_1]: [],
        [DOCUMENT_THIRD_2]: [],
        [DOCUMENT_THIRD_3]: [],
        [DOCUMENT_THIRD_4]: [],
        [DOCUMENT_THIRD_5]: [],
        [DOCUMENT_FOURTH]: [],
        [DOCUMENT_FIFTH]: [],
    });
    const [attachments, setAttachments] = useState({
        [WARRANT]: [],
        [AFFIDAVIT]: [],
        [OWNER_CONSENT]: [],
        [PARTICIPANTS_LIST]: [],
        [FULFILLING_PRODUCT]: [],
        [PLANNING_CONTRACT]: [],
        [CONSENT_ALL]: [],
        [OTHER_DOCUMENTS]: [],
    });
    const currentlyUploading = useMemo(() => getOngoingUploads(Object.values(documents || {})?.flat()), [documents]);
    const allUploads = React.useMemo(() => [...[...(Object.values(documents || {}) || [])].flat(), ...[...(Object.values(attachments || {}) || [])].flat()], [documents, attachments]);
    const { stage } = state;
    useScrollToTop(stage);
    const uploadProgressRef = React.useRef({});

    useEffect(() => {
        function handleBeforeUnload (e) {
            if (!isNotUploading(allUploads)) {
                e.preventDefault();
            }
        }

        function handleResize() {
            if(window.innerWidth < 992) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('resize', handleResize);
        };
    }, [allUploads]);

    const setStage = (newStage) => {
        setState({ ...state, stage: newStage });
    };

    useEffect(() => {
        return () => {
            setStage(1);
        };
    }, []);

    const validationFunctionRef = React.useRef();
    const saveFormRef = React.useRef();

    return (
        <ErrorBoundary fallback={<ProcessError idFromBoundary={id} isFromErrorBoundary />} onError={logError}>
            <Container className={`d-flex flex-column align-items-center pt-5 background ${isMobileView ? 'mobile' : ''}`} fluid>
                {isFinished ? <FormFinisher isSuccessOrFailSubmission={isSuccessOrFailSubmission} header={FORM_16_INTENTION.title} id={id} linkRef={'form16'}
                    setStage={setStage} setIsFinished={setIsFinished} /> :
                    <>
                        <FormContext.Provider
                            value={{
                                intention: FORM_16_INTENTION.title,
                                stepValue: stage,
                                uploadError,
                                setUploadError,
                                uploadedBytes,
                                setUploadedBytes,
                                uploadedPrilohyBytes,
                                setUploadedPrilohyBytes,
                                uploadProgressRef,
                                validationRef: validationFunctionRef,
                                setStagesArr,
                                stagesArr,
                                saveFormRef,
                                id,
                                setId,
                                fetch,
                                setFetch,
                                waitingForPruvodniList,
                                setWaitingForPruvodniList,
                            }}
                        >
                            <FormHeader currentlyUploading={currentlyUploading} isMobileView={isMobileView} header={FORM_16_INTENTION} />
                            <Form16MainSection attachments={attachments} setAttachments={setAttachments} documents={documents} setDocuments={setDocuments} isMobileView={isMobileView} stage={stage}   />
                            <FormFooter currentlyUploading={currentlyUploading} setIsSuccessOrFailSubmission={setIsSuccessOrFailSubmission} formNumber={'16'} setIsFinished={setIsFinished} />
                        </FormContext.Provider>
                    </>}
            </Container>
        </ErrorBoundary>
    );
};

export default Form16;