import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ZamerComponent07, FormProgress, ZadatelComponent07, RozsahComponent07, PrilohyComponent07, PrijemceComponent07, KontrolaComponent07 } from '../index.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave } from '../../apiCalls/formApiCalls.js';
import { FORM_07_INTENTION } from '../../constants/sharedConstants.js';
// import usePrevious from '../CustomHooks/usePrevious.jsx';
import FormContext from '../../formContexts/FormContext.js';
import { isPrilohy, sendAttachmentRequest } from '../../helperFunctions/helpers.js';
import axios from 'axios';

const Form07MainSection = ({
    stage,
    attachments,
    setAttachments,
    isMobileView,
}) => {
    const [loading, setLoading] = useState(true);
    const { token } = useContext(AuthContext);
    let { id: urlId } = useParams();
    const urlPath = 'quick-save-api/build-application-07/save';
    const stepsRef = useRef([]);
    const [foundApplicantIndex, setFoundApplicantIndex] = useState(null);
    const [applicantArr, setApplicantArr] = useState([]);
    // const prevStage = usePrevious(stage);
    const {/*uploadError, setUploadError,*/ setId, stepValue, stagesArr, id} = useContext(FormContext);
    const attachmentsToSaveManually = React.useMemo(() => 
        [...(Object.values(attachments || {})?.flat()?.filter(el => !el.section?.startsWith('power_of_attorney')) || [])]
            .filter(fileObj => (!fileObj.blobFile && !fileObj.abortController && !fileObj.message)), 
    [attachments]);

    useEffect(() => {
        if (!urlId) {
            (async () => {
                let data = JSON.stringify({
                    'buildApplication': {
                        'title': FORM_07_INTENTION.title
                    }
                });

                const response = await formSave(data, token, urlPath);
                if ((200 <= response?.status) && (response?.status < 300)) {
                    console.log('START NOVE ZADOSTI 07', response.data);
                    setId(response.data);
                    setLoading(false);
                }
            })();
        }

        else {
            setId(urlId);
            setLoading(false);
        }
    }, []);
    
    // useEffect(() => {
    //     if (prevStage && prevStage !== stage && uploadError.length) {
    //         setUploadError([]);
    //     }
    // }, [stage, uploadError]);

    useEffect(() => {
        if (!attachmentsToSaveManually.length || isPrilohy(stepValue, stagesArr)) {
            return; 
        }
        const source = axios.CancelToken.source();
        const requestObject = sendAttachmentRequest(id, [...(Object.values(attachments|| {}) || []).flat().filter(el => !el.message), ...attachmentsToSaveManually]);
        if (requestObject) {
            formSave(requestObject, token, urlPath, source);
        }
    }, [attachmentsToSaveManually.length]);

    return (
        <Container fluid className='d-flex justify-content-center px-3 pt-0 pb-3 p-lg-5'>
            <Row className='base-width'>
                <Col className='d-flex justify-content-center justify-content-md-end d-md-block d-lg-none mb-4 mb-lg-0'>
                    {isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }} className='pe-lg-3'>
                    {loading === false ? <>
                        {stage === 1 && <ZamerComponent07 
                            urlPath={urlPath}
                        />}
                        {stage === 2 && <ZadatelComponent07
                            urlPath={urlPath}
                            stage={stage}
                            foundApplicantIndex={foundApplicantIndex}
                            applicantArr={applicantArr}
                            setApplicantArr={setApplicantArr}
                            setFoundApplicantIndex={setFoundApplicantIndex}
                        />}
                        {stage === 3 && <RozsahComponent07 
                            urlPath={urlPath}
                        />}
                        {stage === 4 && <PrilohyComponent07
                            attachments={attachments}
                            setAttachments={setAttachments}
                            urlPath={urlPath}
                            stepsRef={stepsRef}
                            setFoundApplicantIndex={setFoundApplicantIndex}
                            applicantArr={applicantArr}
                        />}
                        {stage === 5 && <PrijemceComponent07 
                            urlPath={urlPath}
                        />}
                        {stage === 6 && <KontrolaComponent07 />}
                    </> :
                        <span>Načítám formulář...</span>}
                </Col>
                <Col lg={{ span: 4 }} xl={{ span: 4 }} className='d-flex justify-content-center justify-content-md-end d-none d-lg-block mb-md-5'>
                    {!isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default Form07MainSection;

Form07MainSection.propTypes = {
    stage: PropTypes.number.isRequired,
    setCheckbox: PropTypes.func,

    isMobileView: PropTypes.bool,
};