import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AuthContext } from 'react-oauth2-code-pkce';
import { GovButton } from '@gov-design-system-ce/react';
import { exportToCSV } from '../../helperFunctions/exportCSVHelpers';
import { fetchInProgressDocumentations, isRequestOK } from '../../apiCalls/componentsApiCalls';
import PaginateResults from '../Forms/ZamerComponentTables/PaginateResults';
import RecordNotFound from '../RecordNotFound';
import DocumentationCard, { IN_PROGRESS_DOCUMENTATION_CARD_TYPE } from './DocumentationCard';
import { appendNumberToGovLabel, updateNumberOnGovLabel } from '../../helperFunctions/helpers.js';

const IN_PROGRESS_HEADERS = ['id', 'title', 'created', 'updated', 'intentionNumber', 'intentionName', 'attachments', 'documentations'];
const RESULTS_PER_PAGE = 24;
const INITIAL_PAGE = 0;
const INITIAL_SIZE = 500;

const TabInProgress = () => {
    const { token } = useContext(AuthContext);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 700);
    const [fetching, setFetching] = useState(false);
    const [listInProgress, setListInProgress] = useState([]);
    const [startIndexInProgress, setStartIndexInProgress] = useState(0);
    const endIndexInProgress = startIndexInProgress + RESULTS_PER_PAGE;

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            }

            setIsMobileView(false);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const getData = async (signal) => {
        setFetching(true);
        try {
            const responseDevelopedDocumentations = await fetchInProgressDocumentations(INITIAL_PAGE, INITIAL_SIZE, token, signal);

            if (isRequestOK(responseDevelopedDocumentations.status)) {
                setListInProgress(responseDevelopedDocumentations.data);
                appendNumberToGovLabel(responseDevelopedDocumentations?.data?.length ?? null);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        getData(signal);

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        updateNumberOnGovLabel(listInProgress?.length);
    }, [listInProgress.length]);

    return (
        <div>
            {fetching && (
                <div className='px-2 py-4 px-md-5 px-xl-0'>
                    <span>Načítám dokumentace...</span>
                </div>
            )}

            {!fetching && !listInProgress?.length && (
                <RecordNotFound />
            )}

            {!fetching && listInProgress?.length > 0 && (
                <Container fluid>
                    <Row className='d-flex'>
                        {listInProgress.map((item, index, array) => {
                            if (array.length <= RESULTS_PER_PAGE) {
                                return (
                                    <Col
                                        key={item?.id}
                                        xs={12}
                                        md={6}
                                        xl={4}
                                        className='d-flex justify-content-center justify-content-md-start py-2 px-md-2 px-lg-0'
                                    >
                                        <DocumentationCard item={item} type={IN_PROGRESS_DOCUMENTATION_CARD_TYPE} />
                                    </Col>
                                );
                            } else if (index >= startIndexInProgress && index < endIndexInProgress) {
                                return (
                                    <Col
                                        key={item?.id}
                                        xs={12}
                                        md={6}
                                        xl={4}
                                        className='d-flex justify-content-center justify-content-md-start py-2 px-md-2 px-lg-0'
                                    >
                                        <DocumentationCard item={item} type={IN_PROGRESS_DOCUMENTATION_CARD_TYPE} />
                                    </Col>
                                );
                            }
                        })}

                        {listInProgress.length > RESULTS_PER_PAGE &&
                            <Row className='d-flex w-100 align-items-center justify-content-center pt-3'>
                                <PaginateResults
                                    resultPerPage={RESULTS_PER_PAGE}
                                    listData={listInProgress}
                                    isMobileView={isMobileView}
                                    startIndex={startIndexInProgress}
                                    setStartIndex={setStartIndexInProgress}
                                />
                            </Row>
                        }
                    </Row>

                    <div className='my-4'>
                        <GovButton
                            nativeType='button'
                            iconLeft='basic/file-earmark-arrow-down'
                            type='outlined'
                            variant='primary'
                            onGov-click={() => exportToCSV(IN_PROGRESS_HEADERS, listInProgress)}
                        >
                            Exportovat .csv
                        </GovButton>
                    </div>
                </Container>
            )}
        </div>
    );
};

export default TabInProgress;