import React, { useContext, useEffect, useMemo, useState } from 'react';
import { counter, saved } from '../../constants/zadostState';
import '../../css/reusable.css';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { arrowRight, iconAutoSave, iconDownload } from '../../assets';
import { useGlobalState } from '../../constants/states';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../../formContexts/FormContext.js';
import { createPdf, getDocumentationToken, isRequestOK } from '../../apiCalls/componentsApiCalls.js';
import { ProgressIndicator } from '../index';
import { canShowRemainingUploadCapacity, convertBytes, formatUrlWithToken, getTotalValue, getTotalValueFromUploads } from '../../helperFunctions/helpers.js';
import { TOTAL_UPLOAD_LIMIT } from '../../constants/sharedConstants.js';

const FormFooter = ({ setIsFinished, formNumber, setIsSuccessOrFailSubmission, receivedToken, currentlyUploading, buttonRef, setFinisherDetails }) => {
    const { token } = useContext(AuthContext);
    const { validationRef, saveFormRef, stagesArr, setStagesArr, id, uploadedBytes, waitingForPruvodniList, uploadProgressRef } = useContext(FormContext);
    const [state, setState] = useGlobalState();
    const [, forceUpdate] = useState(false);
    const [lastSaveTime, setLastSaveTime] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const cooldownPeriod = 3000;
    const saving = saved.value;
    const [averageProgress, setAverageProgress] = useState(0);
    const totalSizeValue = getTotalValueFromUploads(currentlyUploading, 'size') || 0;
    const uploadSize = convertBytes((totalSizeValue / currentlyUploading?.length) * averageProgress / 100, 2) || 0;
    const totalSize = convertBytes(totalSizeValue, 2) || 0;
    const remainingSizeInBytes = useMemo(() => (TOTAL_UPLOAD_LIMIT - Number(uploadedBytes) - getTotalValueFromUploads(currentlyUploading, 'size')), [currentlyUploading, uploadedBytes]);
    const isDocumentation = formNumber === 'documentation';
    // TODO to check if this is relevant
    const tokenForNewVersion = '';
    const isExternalDocumentation = isDocumentation && Boolean(tokenForNewVersion); 
    const isFinalStage = state?.stage === stagesArr?.length;
    const canRenderProgressIndicator = useMemo(() => Boolean(currentlyUploading?.length > 0), [currentlyUploading]) ?? false;
    const isInputError = React.useMemo(() => [...stagesArr].some(stg => (Number(stg?.stage) !== stagesArr?.length) && (stg?.error === true)), [stagesArr]);
    const isEveryThingChecked = React.useMemo(() => [...stagesArr]
        .filter(stg => Number(stg?.stage) !== stagesArr.length)
        .every(stg => (Number(stg?.stage) !== stagesArr?.length) && (stg?.seen === true)), [stagesArr]);
    const shouldDisableFormSubmitButton = useMemo(() => 
        ((isFinalStage || isExternalDocumentation) && (canRenderProgressIndicator || Boolean(waitingForPruvodniList) || isInputError || !isEveryThingChecked)),
    [isFinalStage, isExternalDocumentation, canRenderProgressIndicator, waitingForPruvodniList]);
    
    const calculateAverageProgress = () => {
        const progressValues = Object.values(uploadProgressRef?.current || {});
        const totalProgress = getTotalValue(progressValues);
        return progressValues.length > 0 ? Math.round(totalProgress / progressValues.length) : 0;
    };
    
    useEffect(() => {
        const updateProgress = () => {
            setAverageProgress(calculateAverageProgress());
        };
    
        updateProgress();
    
        const interval = setInterval(updateProgress, 100);
        return () => clearInterval(interval);
    }, [currentlyUploading]);

    useEffect(() => {
        const unsubscribe = saved.subscribe(() => {
            forceUpdate(update => !update);
        });

        return () => unsubscribe();
    }, []);

    const sendForm = async () => {
        try {
            const source = axios.CancelToken.source();
            // for now keep integrationsUrl for dokumentace until new endpoint
            // TODO check isExternalDocumentation
            let configKey = receivedToken ? 'validateAndPersistBaseUrl' : 'integrationsUrl';
            // TODO check isExternalDocumentation
            let param = isDocumentation ?  `send/documentation/${id}` : receivedToken ? `build-intentions/submit-accompanying-document/${id}` : `send/${id}`;

            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            };
        
            if (receivedToken) {
                config.headers.shared = receivedToken;
            }

            const response = await axios.post(
                urlJoin(apiConfig[configKey], param),
                null,
                config);
            if (isRequestOK(response?.status)) {
                if (isDocumentation && setFinisherDetails) {
                    const {icsId, filePid, bppId} = response?.data?.data || {};
                    if (icsId, filePid, bppId) {
                        const data = {icsId, filePid, bppId};
                        const documentationToken = await getDocumentationToken(data, token, source);
                        const formattedUrl = documentationToken && formatUrlWithToken(id, filePid, documentationToken);
                        if (formattedUrl) {
                            setFinisherDetails(prev => ({...prev, urlToCopy: formattedUrl, icsId, filePid, bppId}));
                            setIsSuccessOrFailSubmission(true);
                        }
                    }
                } else {
                    setIsSuccessOrFailSubmission(true);
                }
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
            setIsSuccessOrFailSubmission(false);
        }
    };


    const nextStage = () => {
        if (isExternalDocumentation) {
            sendForm();
            setIsSuccessOrFailSubmission(null);
            setIsFinished(true);
            return;
        }

        if (receivedToken && state.stage !== 4 && state.stage !== 3 && state.stage !== 2) {
            if (state.stage <= stagesArr.length - 1) {
                setState(prevState => {
                    return ({ ...prevState, stage: prevState.stage + 1 });
                });
            } else if (isFinalStage) {
                if (validationRef?.current?.validate()) {
                    sendForm();
                    setIsSuccessOrFailSubmission(null);
                    setIsFinished(true);
                } else {
                    window.scrollTo({
                        top: 500,
                        behavior: 'smooth',
                    });
                }
            }

            return;
        }

        if ((state.stage === 1 && !receivedToken) || (receivedToken && (state.stage === 4 || state.stage === 3 || state.stage === 2))) {
            if (validationRef?.current?.validate()) {
                setState(prevState => {
                    return ({ ...prevState, stage: prevState.stage + 1 });
                });
            } else {
                window.scrollTo({
                    top: 480,
                    behavior: 'smooth',
                });
            }
            return;
        }
           
        if (state.stage <= stagesArr.length - 1) {
            validationRef?.current?.validate();
            setState(prevState => {
                return ({ ...prevState, stage: prevState.stage + 1 });
            });
        } else if (isFinalStage) {
            const finalValidation = validationRef?.current?.validate();

            if (!isInputError && isEveryThingChecked && (isDocumentation || finalValidation)) {
                sendForm();
                setIsSuccessOrFailSubmission(null);
                setIsFinished(true);
                setStagesArr(prev => [...prev].map(stg => {
                    if (Number(stg.stage) === Number(state.stage)) {
                        return {...stg, warning: false};
                    }
                    return stg;
                }));
            } else {
                setStagesArr(prev => [...prev].map(stg => {
                    if (Number(stg.stage) === Number(state.stage)) {
                        return {...stg, warning: true};
                    }
                    return stg;
                }));
            }
        }
    };

    const prevStage = () => {
        if (state.stage > 1) {
            if (!receivedToken) {
                validationRef?.current?.validate();
            }
            setState(prevState => ({ ...prevState, stage: prevState.stage - 1 }));
        }
    };

    const handleSaveClick = () => {
        const currentTime = Date.now();
        if (currentTime - lastSaveTime > cooldownPeriod) {
            saveFormRef?.current?.saveForm();
            setLastSaveTime(currentTime);
            setIsButtonDisabled(true); 

            setTimeout(() => {
                setIsButtonDisabled(false);
            }, cooldownPeriod);
        } else {
            console.log('Please, wait before saving again.');
        }
    };

    return (
        <Container fluid className='p-3 p-md-5 d-flex justify-content-center' style={{ backgroundColor: 'white', padding: '24px 0' }}>
            <Row className='base-width d-flex align-items-center justify-content-between'>
                <Col xs={12} xl={4} className='my-3 my-xl-0 d-flex'>
                    {(!isExternalDocumentation && state.stage > 1)
                        &&
                        <button className='d-flex justify-content-between align-items-center button-outlined form-footer-btn' onClick={prevStage}>
                            <img src={arrowRight} style={{ rotate: '180deg', height: '1.5rem', marginLeft: '1rem' }} />
                            Zpět
                        </button>}
                    <button 
                        className={`w-100 d-flex align-items-center justify-content-center button-solid form-header-btn form-footer-btn ${shouldDisableFormSubmitButton ? 'disabled' : ''}`}
                        onClick={() => {
                            if (shouldDisableFormSubmitButton) {
                                return;
                            }
                            nextStage();
                        }} 
                        ref={el => { if (buttonRef) buttonRef.current['finisher'] = el; }}
                    >
                        {
                            (isExternalDocumentation ? 'Vložit dokumentaci' : stagesArr.length > 0 && stagesArr.map((item, index, array) =>
                                (state.stage === item.stage && index === array.length - 1) && receivedToken ? 'Vložit' :
                                    (state.stage === item.stage && index === array.length - 1) ? (isDocumentation ? 'Vložit dokumentaci' : 'Podat') :
                                        (state.stage + 1 === item.stage) ? item.name :
                                            ''
                            ))
                        }
                        <img src={arrowRight} style={{ height: '1.5rem', marginLeft: '1rem', filter: 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(42deg) brightness(104%) contrast(103%)' }} />
                    </button>
                </Col>
                {(remainingSizeInBytes > 0 && canRenderProgressIndicator) && (
                    <ProgressIndicator
                        type={'footer'}
                        averageProgress={averageProgress}
                        uploadSize={uploadSize}
                        totalSize={totalSize}
                        remainingSizeInBytes={remainingSizeInBytes}
                    />)}
                {!canRenderProgressIndicator && (!saving
                    ?
                    <Col xs={12} md={8} xl={5} className='d-flex align-items-center justify-content-center justify-content-md-start my-2 my-md-0 ps-xl-4'>
                        <span>Ukládám formulář...</span>
                    </Col>
                    :
                    <Col xs={12} md={8} xl={5} className='d-flex align-items-center justify-content-center justify-content-md-start my-2 my-md-0 ps-xl-4'>
                        <img src={iconAutoSave} style={{ height: '1.25rem', marginRight: '1rem' }} />
                        <Container className='d-flex flex-column justify-content-center'>
                            <p>
                                <span className='form-footer-btn-text_first'>Automaticky uloženo </span>
                                <span className='form-footer-btn-text_second'>před {counter} sekundami. &nbsp;&nbsp;</span>
                                <span 
                                    className={`form-footer-btn-text_main ${isButtonDisabled ? 'disabled' : 'enabled'}`}
                                    onClick={handleSaveClick}
                                >
                                    Uložit nyní
                                </span>
                            </p>
                            {canShowRemainingUploadCapacity(formNumber) && (
                                remainingSizeInBytes > 0 ? (<p className='upload-indicator-footer secondary'>{`Možno nahrát ještě ${convertBytes(remainingSizeInBytes) ?? '0 MB'}`}</p>)
                                    : (<p className='upload-indicator-footer secondary'>Datový limit dokumentace dosažen, další soubory již není možné nahrát.</p>)
                            )}
                        </Container>
                    </Col>)}
                <Col xs={12} md={4} xl={{ span: 3 }} className='d-flex align-items-center justify-content-center justify-content-md-end my-2 my-md-0' style={{ cursor: 'pointer' }}>
                    <img src={iconDownload} style={{ height: '1.25rem', marginRight: '1rem' }} />
                    <p style={{ fontSize: '0.938rem', fontWeight: '500', lineHeight: '1.464rem', letterSpacing: '0.018rem', color: '#2362A2', textDecoration: 'underline' }} onClick={() => createPdf(formNumber, id, receivedToken, token)}>
                        Stáhnout náhled PDF k tisku
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

FormFooter.propTypes = {
    setIsFinished: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    formNumber: PropTypes.string,
    receivedToken: PropTypes.string,
    setIsSuccessOrFailSubmission: PropTypes.func,
    averageProgress: PropTypes.number,
    currentlyUploading: PropTypes.arrayOf(Object),
    buttonRef: PropTypes.objectOf(Array),
    setFinisherDetails: PropTypes.func,
    documentsToTrack: PropTypes.any,
};

export default FormFooter;

