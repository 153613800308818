import React, { useContext, useEffect, useState, useImperativeHandle, useMemo } from 'react';
import { Container, Row } from 'react-bootstrap';
import { arrowDownBlue, arrowUpBlue } from '../../assets';
import '../../css/zamerComponent.css';
import '../../css/prilohyComponent.css';
import { FileUpload, UploadedAttachments } from '../index';
import { AFFIDAVIT, OTHER_DOCUMENTS, OWNER_CONSENT, PARTICIPANTS_LIST, WARRANT } from '../../constants/sharedConstants';
import {
    spreadUploadedAttachments,
    shouldNotSave,
    setApplicantsFile,
    setAttorneyFile,
    handleFiles,
    setApplicantRepreFile,
    getOngoingUploads,
} from '../../helperFunctions/helpers';
import usePrevious from '../CustomHooks/usePrevious';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import useFirstRender from '../CustomHooks/useFirstRender';
import { useLocation, useNavigate } from 'react-router-dom';

const PrilohyComponent08 = ({
    setFoundApplicantIndex,
    stepsRef,
    attachments,
    setAttachments,
    urlPath,    
    applicantArr,
}) => {
    const {intention, stepValue, saveFormRef, id, setUploadedPrilohyBytes} = useContext(FormContext); 
    const [isOpen, setIsOpen] = useState(WARRANT);
    const { token } = useContext(AuthContext);
    const [request, setRequest] = useState(
        {
            'applicationId': id,
            'buildApplication': {
                'attachments': []
            }
        }
    );

    
    const allAttachmentsToFilter = useMemo(() => Object.values(attachments || {})?.flat(), [attachments]);
    const allAttachments = useMemo(() => {
        return [...(allAttachmentsToFilter || [])].filter(el => !el.section?.startsWith('power_of_attorney'));
    }, [attachments]);
    // const [applicantNotMe, setApplicantNotMe] = useState(false);
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    
    const onGoingUploads = useMemo(() => getOngoingUploads(Object.values(attachments || {})?.flat()), [attachments]);
    const attachmentsToUpdate = useMemo(() => {
        return [...(allAttachments || [])].filter(fileObj => 
            (!fileObj.blobFile && !fileObj.abortController && !fileObj.message));
    }, [attachments]);


    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const buildApplicationForm = response.data?.buildApplicationForms[0];
                    // if(buildApplicationForm) setApplicantNotMe(Boolean(buildApplicationForm?.applicantAttorney || buildApplicationForm?.applicantMore));

                    setApplicantRepreFile(buildApplicationForm?.applicant, setAttachments);
                    if (buildApplicationForm?.applicantMore) {
                        setApplicantsFile(buildApplicationForm?.applicants, setAttachments);
                    }
                    setAttorneyFile(buildApplicationForm?.attorney, setAttachments);

                    const buildApplication = response?.data?.buildApplication;
                    const attachments = buildApplication?.attachments;

                    if (attachments instanceof Array && attachments.length > 0) {
                        spreadUploadedAttachments([...(attachments || []), ...onGoingUploads], setAttachments, setUploadedPrilohyBytes);
                    }
                } else {
                    console.log('ID NOT YET IN DB');
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        } else {
            console.log('NON EXISTENT ID');
        }
    }, []);

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }
        saveChanges();
    }, [request.buildApplication.attachments]);

    useEffect(() => {
        setRequest(prev => ({ ...prev, buildApplication: { ...prev.buildApplication, attachments: [...attachmentsToUpdate].map(file => {
            // eslint-disable-next-line no-unused-vars
            const {blobFile, message, abortController, existing, flagged, ...rest} = file;
            return {...rest};
        }) } }));
    }, [attachmentsToUpdate.length]);

    const handleFileInput = (files, category) => {
        handleFiles(files, category, token, setAttachments, null, allAttachmentsToFilter);
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    return (
        <Container className='pe-lg-3 component-container' fluid>
            <Row className='row-wrapper'>
                <h2 className='p-0 main-title'>{`${stepValue}. Přílohy`}</h2>
                <p className='p-0 prilohy-second_title'>Součásti žádosti o vydání rámcového povolení</p>
            </Row>

            <Row className='d-flex flex-column border-bottom pt-1 pb-4 mb-3' onClick={() => setIsOpen(WARRANT)}>
                <Row>
                    <p className='d-flex justify-content-between align-items-start p-0 pb-2 prilohy-title'>Plné moci<img src={(isOpen === WARRANT || attachments[WARRANT].length > 0) ? arrowUpBlue : arrowDownBlue} alt='arrowDown' /></p>
                </Row>
                <Row className='prilohy-row-text'>
                    <p className='p-0 prilohy-text'>K zastupování žadatele (stavebníka)</p>
                </Row>
                {(isOpen === WARRANT || attachments[WARRANT].length > 0) && <Row>
                    <Row>
                        <UploadedAttachments
                            category={WARRANT}
                            attachments={attachments[WARRANT]} //warrant here is a fake category that combines power of attorneys
                            setUploads={setAttachments} 

                            stepsRef={stepsRef}
                            setFoundApplicantIndex={setFoundApplicantIndex}
                            applicantArr={applicantArr}
                        />
                    </Row>
                </Row>}
            </Row>
            
            <Row className='d-flex flex-column border-bottom pt-1 pb-4 mb-3' onClick={() => setIsOpen(AFFIDAVIT)}>
                <Row>
                    <p className='d-flex justify-content-between align-items-start p-0 pb-2 prilohy-title'>Čestné prohlášení<img src={(isOpen === AFFIDAVIT || attachments[AFFIDAVIT].length > 0) ? arrowUpBlue : arrowDownBlue} alt='arrowDown' /></p>
                </Row>
                <Row className='prilohy-row-text'>
                    <p className='p-0 prilohy-text'>o vlastnickém právu ke stavbě, která není předmětem evidence v katastru nemovitostí</p>
                </Row>
                {(isOpen === AFFIDAVIT || attachments[AFFIDAVIT].length > 0) && <Row>
                    <Row>
                        <UploadedAttachments
                            category={AFFIDAVIT}
                            attachments={attachments[AFFIDAVIT]}
                            setUploads={setAttachments} 
      
                        />
                    </Row>
                    <FileUpload handleFileInput={handleFileInput} category={AFFIDAVIT} isForPrilohy allUploads={allAttachments}/>
                </Row>}
            </Row>
            <Row className='d-flex flex-column border-bottom pt-1 pb-4 mb-3' onClick={() => setIsOpen(OWNER_CONSENT)}>
                <Row>
                    <p className='d-flex justify-content-between align-items-start p-0 pb-2 prilohy-title'>Souhlas vlastníka<img src={(isOpen === OWNER_CONSENT || attachments[OWNER_CONSENT].length > 0) ? arrowUpBlue : arrowDownBlue} alt='arrowDown' /></p>
                </Row>
                <Row className='prilohy-row-text'>
                    <p className='p-0 prilohy-text'>podle § 187 stavebního zákona. Souhlas s navrhovaným stavebním záměrem musí být vyznačen na situačním výkresu dokumentace a musí obsahovat identifikační údaje a podpis</p>
                </Row>
                {(isOpen === OWNER_CONSENT || attachments[OWNER_CONSENT].length > 0) && <Row>
                    <Row>
                        <UploadedAttachments
                            category={OWNER_CONSENT}
                            attachments={attachments[OWNER_CONSENT]}
                            setUploads={setAttachments} 
      
                        />
                    </Row>
                    <FileUpload handleFileInput={handleFileInput} category={OWNER_CONSENT} isForPrilohy allUploads={allAttachments}/>
                </Row>}
            </Row>
            <Row className='d-flex flex-column border-bottom pt-1 pb-4 mb-3' onClick={() => setIsOpen(PARTICIPANTS_LIST)}>
                <Row>
                    <p className='d-flex justify-content-between align-items-start p-0 pb-2 prilohy-title'>Seznam známých účastníků řízení<img src={(isOpen === PARTICIPANTS_LIST || attachments[PARTICIPANTS_LIST].length > 0) ? arrowUpBlue : arrowDownBlue} alt='arrowDown' /></p>
                </Row>
                {(isOpen === PARTICIPANTS_LIST || attachments[PARTICIPANTS_LIST].length > 0) && <Row>
                    <Row>
                        <UploadedAttachments
                            category={PARTICIPANTS_LIST}
                            attachments={attachments[PARTICIPANTS_LIST]}
                            setUploads={setAttachments} 
      
                        />
                    </Row>
                    <FileUpload handleFileInput={handleFileInput} category={PARTICIPANTS_LIST} isForPrilohy allUploads={allAttachments}/>
                </Row>}
            </Row>
            <Row>
                <p className='p-0 mt-4 mb-3 prilohy-subtitle'>Další přílohy</p>
                <p className='p-0 prilohy-info-text'>V případě, že součástí dokladové části dokumentace pro rámcové povolení nejsou všechna nezbytná vyjádření a závazná stanoviska dotčených orgánů, přiložte další podklady vyžadované jinými právními předpisy – např. zákon č. 254/2001 Sb., vodní zákon, zákon č. 114/1992 Sb., o ochraně přírody a krajiny, zákon č. 201/2012 Sb., o ochraně ovzduší, zákon č. 334/1992 Sb., o ochraně zemědělského půdního fondu, zákon č. 20/1987 Sb., o státní památkové péči, zákon č. 289/1995 Sb., lesní zákon, zákon č. 133/1985 Sb., o požární ochraně, zákon č. 13/1997 Sb., o pozemních komunikacích, zákon č. 44/1988 Sb., horní zákon, zákon č. 164/2001 Sb., lázeňský zákon, zákon č. 62/1988 Sb., o geologických pracích, zákon č. 258/2000 Sb., o ochraně veřejného zdraví a o změně některých souvisejících zákonů, zákon č. 406/2000 Sb., o hospodaření energií, zákon č. 100/2001 Sb., o posuzování vlivů na životní prostředí, zákon č. 148/2023 Sb., o jednotném environmentálním stanovisku.</p>
            </Row>
            <Row>
                <Row>
                    <UploadedAttachments
                        category={OTHER_DOCUMENTS}
                        attachments={attachments[OTHER_DOCUMENTS]}
                        setUploads={setAttachments} 
                          
                    />
                </Row>
                <FileUpload handleFileInput={handleFileInput} category={OTHER_DOCUMENTS} isForPrilohy allUploads={allAttachments}/>
            </Row>
        </Container>
    );
};

PrilohyComponent08.propTypes = {

    urlPath: PropTypes.string.isRequired,
    stepsRef: PropTypes.objectOf(Array),
    applicantArr: PropTypes.arrayOf(Object),
    setFoundApplicantIndex: PropTypes.func,
};


export default PrilohyComponent08;
