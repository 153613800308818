import React, { useState } from 'react';
import '../css/rizeni.css';
import { HeaderSection, RizeniMainSection } from '../components';
import { Container } from 'react-bootstrap';

const Rizeni = () => {
    const [isActive, setIsActive] = useState('block');

    return (
        <Container className='d-flex flex-column align-items-center' fluid>
            <HeaderSection headerText={'Vypisujeme všechna řízení, kterých se vaše osoba účastní'} page={'rizeni'} mainHeader={'Řízení'}/>
            <RizeniMainSection isActive={isActive} setIsActive={setIsActive} />
        </Container>
    );
};

export default Rizeni;